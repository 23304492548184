import React, { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { apiCall } from "../../api/Api";

import classNames from "classnames";
import plusIcon from "../../assets/svg/plus-icon-white.svg";
import useInfiniteScroll from "react-infinite-scroll-hook";
import JobCard from "./JobCard/JobCard";
import LoadingComponent from "../../components/shared/LoadingComponent/LoadingComponent";
import reloadIcon2 from "../../assets/svg/Refresh_icon.svg";

import "./Jobs.scss";
interface FilteredJobs {
  [key: string]: [];
}

const Jobs = () => {
  let today = new Date();
  let tomorrow = today;
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [jobStatus, setJobStatus] = useState<string>("Upcoming");
  const [allAvailableJobs, setAllAvailableJobs] = useState<any | null>([]);
  const [nextPage, setNextPage] = useState<any>("");
  const [timeDates, setTimeDates] = useState<FilteredJobs>();
  const [isCalled, setIsCalled] = useState(false);
  const [jobAssigned, setJobAssigned] = useState<string>("Not assigned");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableJobsCount, setAvailableJobsCount] = useState(0);
  const [assignedJobsCount, setAssignedJobsCount] = useState(0);
  const [error, setError] = useState("");
  const [sortedKeys, setSortedKeys] = useState([]);
  const [jobsCountLoading, setJobsCountLoading] = useState(false);
  const [refreshCalled, setRefreshCalled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("common");

  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  useEffect(() => {
    setJobsCountLoading(true);
    try {
      apiCall("GET", `b2b/jobs/?status=assigned&limit=0`)
      .then((res) => {
        setAssignedJobsCount(res.data.count);
        setJobsCountLoading(false);
      })
      apiCall("GET", `b2b/jobs/?status=available&limit=0`).then((res) => {
        setAvailableJobsCount(res.data.count);
        setJobsCountLoading(false);
      })
    } catch(error){
      setJobsCountLoading(false);
    }
  }, []); 

  const filterDates = (datesArray: any) => {
    //groups jobs into same dates
    const filteredJobs: FilteredJobs = datesArray.reduce(
      (group: any, item: any) => {
        const date = new Date(item?.job_start_time);
        const isoDateKey = date.toISOString().split("T")[0];
        group[isoDateKey] = group[isoDateKey] ?? [];
        group[isoDateKey].push(item);

        return group;
      },
      {},
    );

    setTimeDates(filteredJobs);

    const sortedKeys2: any = Object.keys(filteredJobs).sort();
    setSortedKeys(sortedKeys2);
  };

  const callJobs = () => {
    setIsLoading(true);
    apiCall("GET", `b2b/jobs/?status=available&limit=10`)
      .then((res) => {
        setAllAvailableJobs(res.data.results);
        filterDates(res.data.results);
        setAvailableJobsCount(res.data.count);
        setIsCalled(true);
        setIsLoading(false);
        setNextPage(res.data?.next);
      })
      .catch((err) => setIsLoading(false));
  }

  useEffect(() => {
    if (!isCalled) {
       callJobs();
    }
  }, []);

  useEffect(() => {
    if (location?.state?.previousLocationPathname === "/jobs/new-job") {
     callJobs();
    }
  }, []);

  const loadMoreJobs = () => {
    if (nextPage !== null) {
    setIsLoading(true);
    let resultUrl = nextPage.split("?")[1];
      apiCall("GET", `b2b/jobs/?${resultUrl}`)
        .then((res) => {
          setAllAvailableJobs([...allAvailableJobs, ...res.data.results]);
          filterDates([...allAvailableJobs, ...res.data.results]);
          setAvailableJobsCount(res.data.count);
          setIsCalled(true);
          setIsLoading(false);
          setNextPage(res.data?.next);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: nextPage,
    onLoadMore: loadMoreJobs,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <div className="Jobs">
      <div className="Jobs__header-container">
        <div className="Jobs__title-container">
          <div className="Jobs__main-title">{t("jobs.title")}</div>
          {isLoading ? 
          (<div className="Jobs__spinner" />) 
          : 
          (<img src={reloadIcon2} alt="reload icon" className={classNames("Jobs__reload-icon", refreshCalled && "Jobs__reload-icon--disable")} onClick={() => {
            if(!refreshCalled) {
              callJobs();
              setRefreshCalled(true);
              setTimeout(() => {
                setRefreshCalled(false);
              }, 10000);
            }
          }} />)}
        </div>
        <div className="Jobs__button-wrapper">
          <div
            className="Jobs__button"
            onClick={() => {
              navigate("/jobs/new-job");
            }}
          >
            <div className="Jobs__button-round">
              <img
                alt="pic"
                src={plusIcon}
                className="Jobs__button-round--plus"
              />
            </div>
            <p className="Jobs__button-text">{t("jobs.addJobButton")}</p>
          </div>
        </div>
      </div>
      <section className="Jobs__container">
        <div
          onClick={() => setJobStatus("Upcoming")}
          className={classNames(
            "Jobs__container-item",
            jobStatus === "Upcoming" && "Jobs__container-item--active",
          )}
        >
          {t("jobs.upcomingTabLabel")}
        </div>
        <div
          onClick={() => {
            setJobStatus("Past");
            navigate("/jobs/past-unreviewed");
          }}
          className={classNames(
            "Jobs__container-item",
            jobStatus === "Past" && "Jobs__container-item--active",
          )}
        >
          {t("jobs.pastTabLabel")}
        </div>
      </section>
      <section className="Jobs__buttons-container">
        <div
          className={classNames(
            "Jobs__not-assigned-btn flex",
            jobAssigned === "Not assigned" && "Jobs__not-assigned-btn--active",
          )}
          onClick={() => {
            setJobAssigned("Not assigned");
            navigate("/jobs/upcoming-not-assigned");
            localStorage.setItem("availableJobsCount", `${availableJobsCount}`);
            localStorage.setItem("assignedJobsCount", `${assignedJobsCount}`);
          }}
        >

          {`${t("jobs.notAssigned.tabLabel")} `} {jobsCountLoading ? (<div className="Jobs__spinner" />) : `(${availableJobsCount})`} 
        </div>
        <div
          className={classNames(
            "Jobs__assigned-btn flex",
            jobAssigned === "Assigned" && "Jobs__assigned-btn--active",
          )}
          onClick={() => {
            setJobAssigned("Assigned");
            navigate("/jobs/upcoming-assigned");
            localStorage.setItem("assignedJobsCount", `${assignedJobsCount}`);
            localStorage.setItem("availableJobsCount", `${availableJobsCount}`);
          }}
        >
          {`${t("jobs.assigned.tabLabel")}`} {jobsCountLoading ? (<div className="Jobs__spinner" />) : `(${assignedJobsCount})`} 
        </div>
      </section>
      {allAvailableJobs?.length === 0 && isCalled && !loading && (
        <div className="Jobs__no-content">
          {t("jobs.notAssigned.noContent")}
        </div>
      )}
      {timeDates &&
        jobAssigned === "Not assigned" &&
        sortedKeys?.map((key, index) => (
          <div className="Jobs__timeline" key={index}>
            <div className="Jobs__timeline-container">
              <div className="Jobs__timeline-content">
                <div className="Jobs__timeline-dot">
                  <div className="Jobs__timeline-dot-weekday">
                    {daysOfTheWeek[new Date(key).getDay()]}
                  </div>
                  <div className="Jobs__timeline-dot-day">
                    {new Date(key).getDate()}
                  </div>
                </div>
                {timeDates &&
                  timeDates[key]?.map((job: any, index: number) => (
                    <div key={index} className="Jobs__job-wrapper">
                      <JobCard
                        job={job}
                        jobStatus={jobStatus}
                        subStatus={jobAssigned}
                        shiftTotal={true}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}

      {jobAssigned === "Not assigned" && (
        <div className="Jobs__infinite-scroll-wrapper">
          {nextPage && nextPage !== null && (
            <div ref={sentryRef}>
              <LoadingComponent />
            </div>
          )}
        </div>
      )}

      {isLoading && allAvailableJobs.length === 0 && (
        <div style={{ margin: "150px auto" }}>
          <LoadingComponent />
        </div>
      )}
    </div>
  );
};

export default Jobs;
