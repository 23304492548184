import React, { useEffect, useState } from "react";

import { apiCall } from "../../../api/Api";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { useNavigate } from "react-router-dom";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import AddPaymentCard from "../AddPaymentCard/AddPaymentCard";
import PlanCart from "./PlanCart/PlanCart";
import PlanInfo from "./PlanInfo/PlanInfo";
import SelectPlan from "./SelectPlan/SelectPlan";
import Button from "../../../components/shared/Button/Button";
import chevronDown from "../../../assets/svg/chevron-down-gray.svg";
import successEmblem from "../../../assets/svg/success-emblem.svg";

import "./SubscriptionPlans.scss";

const SubscriptionPlans = () => {
  const [activePlan, setActivePlan] = useState<any | null>();
  const [paymentMethods, setPaymentMethods] = useState<any | null>();
  const [plans, setPlans] = useState<any | null>();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isPlanInfoOpen, setIsPlanInfoOpen] = useState(false);
  const [isSelectPlanOpen, setIsSelectPlanOpen] = useState(false);
  const [isAddPaymentCardOpen, setIsAddPaymentCardOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any | null>();
  const globalCtx = useGlobalContext();
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  useEffect(() => {
    // GET ACTIVE SUBSCRIPTION PLANS
    apiCall("GET", "subscription/")
      .then((response) => {
        setActivePlan(response.data[0]);

        // GET PAYMENT METHODS
        apiCall("GET", "subscription/payment-methods/")
          .then((response) => {
            setPaymentMethods(response.data);
          })
          .catch((error) => {
            setPaymentMethods(null);
          });

        // GET PRODUCTS
        apiCall("GET", "subscription/products/")
          .then((response) => {
            setPlans(response.data);
          })
          .catch((error) => {
            setPlans(null);
          });
      })
      .catch((error) => {
        setActivePlan(null);
      });
  }, []);

  const planId = localStorage.getItem("selectedPlan");

  useEffect(() => {
    //when user adds credit card for the first time, this useEffect checks what subscription plan did user clicked, and opens selected plan
    if (plans && planId && paymentMethods !== null) {
      const filterPlan = plans.filter((item: any) => item?.id === planId);
      setSelectedPlan(filterPlan[0]);
      setIsSelectPlanOpen(true);
    }
  }, [planId, plans]);

  return (
    <>
      {isSuccessModalOpen && (
        <ModalContainer>
          <div className="SuccessModal">
            <div className="SuccessModal__body">
              <img
                alt="pic"
                className="SuccessModal__success"
                src={successEmblem}
              />
              <p className="SuccessModal__text">
                {t("subscription.subscriptionPlans.successModal.textOne")}
              </p>
            </div>
            <div className="SuccessModal__footer">
              <Button
                className="SuccessModal__button"
                label="OK"
                onClick={() => {
                  navigate("/subscription");
                }}
              />
            </div>
          </div>
        </ModalContainer>
      )}
      {isAddPaymentCardOpen && (
        <ModalContainer position="top">
          <AddPaymentCard
            onCloseClick={() => {
              setIsAddPaymentCardOpen(false);
            }}
          />
        </ModalContainer>
      )}
      {isSelectPlanOpen && (
        <ModalContainer>
          <SelectPlan
            activePlan={activePlan}
            selectedPlan={selectedPlan}
            openSuccessModal={() => {
              setIsSuccessModalOpen(true);
            }}
            onCloseClick={() => {
              setIsSelectPlanOpen(false);
            }}
          />
        </ModalContainer>
      )}{" "}
      {isPlanInfoOpen && (
        <ModalContainer>
          <PlanInfo
            activePlan={activePlan}
            onCloseClick={() => {
              setIsPlanInfoOpen(false);
            }}
          />
        </ModalContainer>
      )}
      <div className="SubscriptionPlansContainer">
        <div className="SubscriptionPlansContainer__head">
          <p className="SubscriptionPlansContainer__head-title">
            {t("subscription.title")}
          </p>
        </div>
        <div className="SubscriptionPlansContainer__body">
          <div className="SubscriptionPlans">
            <div className="SubscriptionPlans__header">
              <div className="SubscriptionPlans__text">
                <p className="SubscriptionPlans__title">
                  <img
                    alt="pic"
                    className="SubscriptionPlans__title-back"
                    src={chevronDown}
                    onClick={() => {
                      navigate("/subscription");
                    }}
                  />
                  {t("subscription.subscriptionPlans.title")}
                </p>
                <h5 className="SubscriptionPlans__subtitle">
                  {t("subscription.subscriptionPlans.subtitle")}
                </h5>
              </div>
            </div>

            <div className="SubscriptionPlans__body">
              <>
                {plans === undefined ? (
                  <div className="SubscriptionPlans__loader">
                    <LoadingComponent />
                  </div>
                ) : plans === null ? (
                  <div className="SubscriptionPlans__noData">{t("noData")}</div>
                ) : (
                  <div className="SubscriptionPlans__carts">
                    {plans?.map((item: any, index: number) => (
                      <PlanCart
                        key={index}
                        item={item}
                        activePlan={activePlan}
                        onPlanInfoClick={() => {
                          setIsPlanInfoOpen(true);
                        }}
                        onSelectPlanClick={() => {
                          if (paymentMethods === null) {
                            //IF NO PAYMENT METHOD
                            setIsAddPaymentCardOpen(true);
                            //remamber plan that selected
                            localStorage.setItem("selectedPlan", item.id);
                          } else {
                            setIsSelectPlanOpen(true);
                            setSelectedPlan(item);
                          }
                        }}
                      />
                    ))}
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlans;
