import React from "react";
import "./SimpleModal.scss";

interface SimpleModalProps {
  children: React.ReactNode;
  onClose: () => void;
}

const SimpleModal: React.FC<SimpleModalProps> = ({ children, onClose }) => {
  return (
    <div className="SimpleModal">
      <div className="SimpleModal__overlay" onClick={onClose} />
      <div className="SimpleModal__content">
        <button className="SimpleModal__close" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default SimpleModal;
