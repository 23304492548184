import React from "react";

import { useTranslation } from "react-i18next";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import { useNavigate } from "react-router-dom";

import signUpRounds from "../../assets/svg/sign-up-rounds.svg";
import briefCase from "../../assets/svg/briefcase-colored.svg";
import unitsColored from "../../assets/svg/units-colored.svg";
import peakJobsLogo from "../../assets/svg/peak-jobs-logo.svg";
import LanguageDropdown from "../../components/shared/LanguageDropdown/LanguageDropdown";
import SignUpForm from "./SignUpForm/SignUpForm";

import cs from "classnames";

import "./SignUp.scss";
import HelpDropdown from "../../components/shared/HelpDropdown/HelpDropdown";

const SignUp = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const isMobile = useResponsiveDimensions().isMobile;

  return (
    <div className="SignUp">
      <div className={cs("SignUp__left", isMobile && "SignUp__left-mobile")}>
        <div className="SignUp__left-top">
          <div className="SignUp__logo">
            <img alt="pic" src={peakJobsLogo} className="SignUp__logo-icon" />
            <LanguageDropdown />
          </div>
          <div className="SignUp__form">
            <SignUpForm />
          </div>
        </div>
      </div>

      {!isMobile && (
        <div className="SignUp__right">
          <div className="SignUp__right-container">
            <div className="SignUp__language"></div>
            <div className="SignUp__tips">
              <div className="SignUp__tipOne">
                <img
                  className="SignUp__tipOne-icon"
                  alt="pic"
                  src={briefCase}
                />
                <p className="SignUp__tipOne-text">{t("signUp.tipOne")}</p>
              </div>
              <div className="SignUp__tipTwo">
                <img
                  className="SignUp__tipTwo-icon"
                  alt="pic"
                  src={unitsColored}
                />
                <div className="SignUp__tipTwo-text">{t("signUp.tipTwo")}</div>
              </div>
            </div>
          </div>

          <img alt="pic" src={signUpRounds} className="SignUp__rounds" />
        </div>
      )}
    </div>
  );
};

export default SignUp;
