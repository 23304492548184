import React, { useEffect, useState } from "react";

import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { apiCall } from "../../../api/Api";
import { useTranslation } from "react-i18next";
import { useEditCompanyFormik } from "./useEditCompanyFormik";
import { useNavigate } from "react-router-dom";
import companyAvatar from "../../../assets/images/company-avatar.png";
import penLogo from "../../../assets/svg/pen-logo.svg";
import deleteIcon from "../../../assets/svg/delete-icon.svg";
import chevronDown from "../../../assets/svg/chevron-down-gray.svg";
import markerIcon from "../../../assets/svg/marker.svg";
import FileUploader from "../../../components/shared/FileUploader/FileUploader";
import InputField from "../../../components/shared/InputField/InputField";
import TextAreaInput from "../../../components/shared/TextAreaInput/TextAreaInput";
import Button from "../../../components/shared/Button/Button";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import Mapbox, { LocationType } from "../../../components/shared/Mapbox/Mapbox";

import uploadIcon from "../../../assets/svg/upload-icon.svg";
import rubbishBin from "../../../assets/svg/rubbish-bin.svg";

import "./EditCompanySettings.scss";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";

const EditCompanySettings = () => {
  const [companyData, setCompanyData] = useState<any | null>();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isImageUploaderOpen, setIsImageUploaderOpen] = useState(false);
  const [base64Image, setBase64Image] = useState("");
  const [base64ImageError, setBase64ImageError] = useState(false);
  const [isPdfUploaderOpen, setIsPdfUploaderOpen] = useState(false);
  const [base64Pdf, setBase64Pdf] = useState("");
  const [pdfFileName, setPdfFileName] = useState("");
  const [location, setLocation] = useState<LocationType | null>();
  const [locationError, setLocationError] = useState(false);
  const [locationRequiredError, setLocationRequiredError] = useState(false);
  const [locationStreetRequiredError, setLocationStreetRequiredError] =
    useState(false);
  const { t } = useTranslation("common");
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    apiCall("GET", "b2b/organization/")
      .then((response) => {
        setCompanyData(response.data);
      })
      .catch((error) => {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
        setCompanyData(null);
      });
  }, []);

  const formik = useEditCompanyFormik({
    onSubmit: async (values: any) => {
      if (base64Image.length === 0) {
        setBase64ImageError(true);
        return;
      }
      if (location === undefined) {
        setLocationRequiredError(true);
        return;
      }
      if (location === null) {
        setLocationError(true);
        return;
      }
      if (location.street === null) {
        setLocationStreetRequiredError(true);
        return;
      }

      try {
        setIsButtonLoading(true);
        const data = {
          name: values.companyName,
          unique_business_identifier: values.companyNumber,
          description: values.companyDescription,
          ...(base64Image !== companyData?.logo_url && {
            logo_data: base64Image,
          }),
          ...(base64Pdf.length > 0 && {
            business_certificate_data: base64Pdf,
          }),
          address: location.full_address,
          city: location.city,
          country: location.country_code,
          latitude: location.latitude,
          longitude: location.longitude,
        };

        // CREATE COMPANY
        const companyDataResponse = await apiCall(
          "PUT",
          "b2b/organization/",
          data,
        );

        // UPDATE LOCAL STORAGE
        localStorage.setItem(
          "organization",
          JSON.stringify(companyDataResponse.data),
        );
        globalCtx.openMessageModal(
          "success",
          t("staticMessage.companyUpdatedSuccess"),
        );

        // PROCEED
        setTimeout(() => {
          navigate("/company/company-settings");
        }, 3000);
      } catch (error: any) {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
      } finally {
        setIsButtonLoading(false);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("companyName", companyData?.name || "");
    formik.setFieldValue(
      "companyNumber",
      companyData?.unique_business_identifier || "",
    );
    formik.setFieldValue("companyDescription", companyData?.description || "");
    setBase64Image(companyData?.logo_url || "");
  }, [companyData]);

  useEffect(() => {
    if (location !== undefined) {
      setLocationRequiredError(false);

      if (location !== null) {
        setLocationError(false);

        if (location.street !== null) {
          setLocationStreetRequiredError(false);
        }
      }
    }
  }, [location]);

  return (
    <>
      {isPdfUploaderOpen && (
        <ModalContainer>
          <FileUploader
            maxFileSizeInBytes={10485760}
            accept=".pdf"
            onCancel={() => {
              setIsPdfUploaderOpen(false);
            }}
            onSubmit={(fileName, base64) => {
              if (base64 !== undefined) {
                setPdfFileName(fileName);
                setBase64Pdf(base64);
                setIsPdfUploaderOpen(false);
              }
            }}
          />
        </ModalContainer>
      )}
      {isImageUploaderOpen && (
        <ModalContainer>
          <FileUploader
            maxFileSizeInBytes={5242880}
            accept="image/*"
            onCancel={() => {
              setIsImageUploaderOpen(false);
            }}
            onSubmit={(fileName, base64) => {
              if (base64 !== undefined) {
                setBase64Image(base64);
                setIsImageUploaderOpen(false);
                setBase64ImageError(false);
              }
            }}
          />
        </ModalContainer>
      )}
      <div className="EditCompanySettingsContainer">
        <div className="EditCompanySettingsContainer__head">
          <p className="EditCompanySettingsContainer__head-title">
            {t("company.title")}
          </p>
        </div>
        <div className="EditCompanySettingsContainer__body">
          {companyData === undefined ? (
            <div className="EditCompanySettings__loader">
              <LoadingComponent />
            </div>
          ) : companyData === null ? (
            <div className="EditCompanySettings__noData">{t("noData")}</div>
          ) : (
            <div className="EditCompanySettings">
              <div className="EditCompanySettings__head">
                <p className="EditCompanySettings__title">
                  <img
                    alt="pic"
                    className="EditCompanySettings__title-back"
                    src={chevronDown}
                    onClick={() => {
                      navigate("/company/company-settings");
                    }}
                  />
                  {t("company.editCompanySettings.title")}
                </p>
              </div>

              <div className="EditCompanySettings__body">
                <div className="EditCompanySettings__form">
                  <div className="EditCompanySettings__uploader">
                    <div className="EditCompanySettings__circle">
                      {base64Image === "" ? (
                        <img
                          alt="pic"
                          src={companyAvatar}
                          className="EditCompanySettings__placeholder"
                        />
                      ) : (
                        <img
                          alt="pic"
                          src={base64Image}
                          className="EditCompanySettings__image"
                        />
                      )}

                      <img
                        alt="pic"
                        src={base64Image === "" ? penLogo : deleteIcon}
                        className="EditCompanySettings__pen"
                        onClick={() => {
                          base64Image === ""
                            ? setIsImageUploaderOpen(true)
                            : setBase64Image("");
                        }}
                      />
                    </div>
                    {base64ImageError && (
                      <h5 className="EditCompanySettings__uploader-error">
                        {t("company.editCompanySettings.companyLogoError")}
                      </h5>
                    )}
                  </div>
                  <div className="EditCompanySettings__inputs">
                    <div className="EditCompanySettings__input">
                      <InputField
                        type="text"
                        placeholder={t(
                          "company.editCompanySettings.companyName.placeholder",
                        )}
                        name="companyName"
                        value={formik.values.companyName}
                        onBlur={(e: any) => formik.handleBlur(e)}
                        onChange={(e: any) => formik.handleChange(e)}
                      />
                      <div className="EditCompanySettings__input-description">
                        {t(
                          "company.editCompanySettings.companyName.description",
                        )}
                      </div>
                      {formik.errors.companyName &&
                        formik.touched.companyName && (
                          <h5 className="EditCompanySettings__input-error">
                            {formik.errors.companyName as string}
                          </h5>
                        )}
                    </div>{" "}
                    <div className="EditCompanySettings__input">
                      <InputField
                        type="text"
                        placeholder={t(
                          "company.editCompanySettings.companyNumber.placeholder",
                        )}
                        name="companyNumber"
                        value={formik.values.companyNumber}
                        onBlur={(e: any) => formik.handleBlur(e)}
                        onChange={(e: any) => formik.handleChange(e)}
                      />
                      <div className="EditCompanySettings__input-description">
                        {t(
                          "company.editCompanySettings.companyNumber.description",
                        )}
                      </div>
                      {formik.errors.companyNumber &&
                        formik.touched.companyNumber && (
                          <h5 className="EditCompanySettings__input-error">
                            {formik.errors.companyNumber as string}
                          </h5>
                        )}
                    </div>{" "}
                    <div
                      className="EditCompanySettings__certificate"
                      onClick={(e) => {
                        if (base64Pdf === "") {
                          setIsPdfUploaderOpen(true);
                        } else {
                          e.preventDefault();
                        }
                      }}
                    >
                      {pdfFileName === "" ? (
                        <p className="EditCompanySettings__certificate-placeholder">
                          {t("createCompany.companyVerification.placeholder")}{" "}
                          <span>(.pdf)</span>
                        </p>
                      ) : (
                        <p className="EditCompanySettings__certificate-name">
                          {pdfFileName}
                        </p>
                      )}

                      <img
                        onClick={() => {
                          if (base64Pdf !== "") {
                            setBase64Pdf("");
                            setPdfFileName("");
                          }
                        }}
                        alt="pic"
                        src={base64Pdf === "" ? uploadIcon : rubbishBin}
                        className="EditCompanySettings__certificate-icon"
                      />
                    </div>
                  </div>
                  <div className="EditCompanySettings__textArea">
                    <TextAreaInput
                      placeholder={t(
                        "company.editCompanySettings.companyDescription.placeholder",
                      )}
                      name="companyDescription"
                      value={formik.values.companyDescription}
                      onBlur={(e: any) => formik.handleBlur(e)}
                      onChange={(e: any) => formik.handleChange(e)}
                    />
                    {formik.errors.companyDescription &&
                      formik.touched.companyDescription && (
                        <h5 className="EditCompanySettings__textArea-error">
                          {formik.errors.companyDescription as string}
                        </h5>
                      )}
                  </div>
                  <div className="EditCompanySettings__map">
                    <Mapbox
                      locationData={(location) => {
                        setLocation(location);
                      }}
                      longitudeData={companyData?.longitude}
                      latitudeData={companyData?.latitude}
                    />
                    <div className="EditCompanySettings__map-description">
                      {t("mapComponent.descriptionOne")}{" "}
                      <img alt="pic" width="10px" src={markerIcon} />{" "}
                      {t("mapComponent.descriptionTwo")}
                    </div>
                    {locationError && (
                      <h5 className="EditCompanySettings__map-error">
                        {t("mapComponent.error")}
                      </h5>
                    )}{" "}
                    {locationRequiredError && (
                      <h5 className="EditCompanySettings__map-error">
                        {t("mapComponent.errorRequired")}
                      </h5>
                    )}{" "}
                    {locationStreetRequiredError && (
                      <h5 className="EditCompanySettings__map-error">
                        {t("mapComponent.errorStreetRequired")}
                      </h5>
                    )}
                  </div>
                  <div className="EditCompanySettings__footer">
                    <Button
                      className="EditCompanySettings__button"
                      type="submit"
                      isLoading={isButtonLoading}
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                      label={t("company.editCompanySettings.button")}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditCompanySettings;
