import React, { useEffect, useState } from "react";

import invoiceIcon from "../../../assets/svg/billing-colored.svg";
import downloadIcon from "../../../assets/svg/download-icon-gradient.svg";

import cs from "classnames";

import "./Invoice.scss";

interface InvoiceProps {
  invoice: any;
}

const Invoice = (props: InvoiceProps) => {
  const { invoice } = props;
  const [formattedDate, setFormattedDate] = useState("");
  const [amount, setAmount] = useState("");

  useEffect(() => {
    const date = new Date(invoice.created * 1000); // JavaScript uses milliseconds
    const formattedDate = date.toLocaleDateString();
    setFormattedDate(formattedDate);
    const amount = invoice.total;
    const formattedAmount = (amount / 100).toFixed(2);
    setAmount(formattedAmount);
  }, []);

  return (
    <>
      {invoice.total === 0 ? (
        <></>
      ) : (
        <div className="Invoice">
          <div className="Invoice__left">
            <img alt="pic" className="Invoice__icon" src={invoiceIcon} />
            <div className="Invoice__details">
              <p className="Invoice__details-id"> #{invoice.number}</p>
              <p className="Invoice__details-date"> {formattedDate}</p>
            </div>
            <div
              className={cs(
                "Invoice__status",
                invoice.status === "paid" && "Invoice__status-paid",
              )}
            >
              {invoice.status}
            </div>
          </div>
          <div className="Invoice__right">
            <p className="Invoice__amount"> {amount}€</p>
            <div
              className="Invoice__download"
              onClick={() => {
                window.open(invoice.invoice_pdf, "_blank");
              }}
            >
              <img
                alt="pic"
                className="Invoice__download-icon"
                src={downloadIcon}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Invoice;
