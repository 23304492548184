import React, { useState } from "react";
import Button from "../../../../components/shared/Button/Button";
import closeIcon from "../../../../assets/svg/close-dark.svg";
import cs from "classnames";
import TextAreaInput from "../../../../components/shared/TextAreaInput/TextAreaInput";
import { useFormik } from "formik";
import { postJobReview } from "../../../../api/Jobs";
import { useGlobalContext } from "../../../../context/GlobalContext/GlobalContext";
import { useTranslation } from "react-i18next";
import ModalContainer from "../../../../components/shared/ModalContainer/ModalContainer";
import { convertRestApiErrorCodes } from "../../../../lib/helpers/handleErrors";

import "./ReviewModal.scss";

const ReviewModal = (props: any) => {
  const { jobId, personId, setGetReview, person, setPerson } = props;
  const [attendance, setAttendance] = useState(0);
  const [communication, setCommunication] = useState(0);
  const [skill, setSkill] = useState(0);
  const [teamwork, setTeamwork] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const globalCtx = useGlobalContext();
  const { t } = useTranslation("common");

  const validate = (values: any) => {
    const errors: any = {};
    if (values.review.length > 100) {
      errors.review = t("staticMessage.reviewValidate");
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      review: "",
    },
    validate,
    onSubmit: (values) => {
      setIsLoading(true);
      const data = {
        user_id: personId,
        job_id: jobId,
        teamwork_rating: teamwork,
        communication_rating: communication,
        attendance_rating: attendance,
        skill_rating: skill,
        review: values?.review,
      };

      postJobReview(data)
        .then((res) => {
          setIsLoading(false);
          setPerson((prev: {}) => ({ ...prev, review: res.data }));

          globalCtx.openMessageModal(
            "success",
            t("staticMessage.reviewAddedSuccess"),
          );
          props.onCancel();
          setGetReview(true);
        })
        .catch((err) => {
          setIsLoading(true);
          const errorMessage = convertRestApiErrorCodes(
            err?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
          props.onCancel();
        });
    },
  });

  return (
    <ModalContainer position="top">
      <div className="ReviewModal__container">
        <div className="ReviewModal__header">
          <h2 className="ReviewModal__label">{t("jobs.reviewModal.title")}</h2>
          <img
            className="ReviewModal__close"
            alt="close icon"
            src={closeIcon}
            onClick={() => {
              props.onCancel();
            }}
          />
        </div>
        <div className="ReviewModal__body">
          <div className="ReviewModal__body-col">
            <div className="ReviewModal__stat">
              <div className="ReviewModal__stat-label">
                {t("jobs.reviewModal.attendance")}
              </div>
              <div className="ReviewModal__stat-body">
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    attendance === 1 && "ReviewModal__stat-number--red",
                  )}
                  onClick={() => setAttendance(1)}
                >
                  1
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    attendance === 2 && "ReviewModal__stat-number--yellow",
                  )}
                  onClick={() => setAttendance(2)}
                >
                  2
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    attendance === 3 && "ReviewModal__stat-number--yellow",
                  )}
                  onClick={() => setAttendance(3)}
                >
                  3
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    attendance === 4 && "ReviewModal__stat-number--yellow",
                  )}
                  onClick={() => setAttendance(4)}
                >
                  4
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    attendance === 5 && "ReviewModal__stat-number--green",
                  )}
                  onClick={() => setAttendance(5)}
                >
                  5
                </div>
              </div>
            </div>
            <div className="ReviewModal__stat">
              <div className="ReviewModal__stat-label">
                {t("jobs.reviewModal.communication")}
              </div>
              <div className="ReviewModal__stat-body">
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    communication === 1 && "ReviewModal__stat-number--red",
                  )}
                  onClick={() => setCommunication(1)}
                >
                  1
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    communication === 2 && "ReviewModal__stat-number--yellow",
                  )}
                  onClick={() => setCommunication(2)}
                >
                  2
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    communication === 3 && "ReviewModal__stat-number--yellow",
                  )}
                  onClick={() => setCommunication(3)}
                >
                  3
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    communication === 4 && "ReviewModal__stat-number--yellow",
                  )}
                  onClick={() => setCommunication(4)}
                >
                  4
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    communication === 5 && "ReviewModal__stat-number--green",
                  )}
                  onClick={() => setCommunication(5)}
                >
                  5
                </div>
              </div>
            </div>
          </div>
          <div className="ReviewModal__body-col">
            <div className="ReviewModal__stat">
              <div className="ReviewModal__stat-label">
                {t("jobs.reviewModal.skills")}
              </div>
              <div className="ReviewModal__stat-body">
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    skill === 1 && "ReviewModal__stat-number--red",
                  )}
                  onClick={() => setSkill(1)}
                >
                  1
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    skill === 2 && "ReviewModal__stat-number--yellow",
                  )}
                  onClick={() => setSkill(2)}
                >
                  2
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    skill === 3 && "ReviewModal__stat-number--yellow",
                  )}
                  onClick={() => setSkill(3)}
                >
                  3
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    skill === 4 && "ReviewModal__stat-number--yellow",
                  )}
                  onClick={() => setSkill(4)}
                >
                  4
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    skill === 5 && "ReviewModal__stat-number--green",
                  )}
                  onClick={() => setSkill(5)}
                >
                  5
                </div>
              </div>
            </div>
            <div className="ReviewModal__stat">
              <div className="ReviewModal__stat-label">
                {t("jobs.reviewModal.teamWork")}
              </div>
              <div className="ReviewModal__stat-body">
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    teamwork === 1 && "ReviewModal__stat-number--red",
                  )}
                  onClick={() => setTeamwork(1)}
                >
                  1
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    teamwork === 2 && "ReviewModal__stat-number--yellow",
                  )}
                  onClick={() => setTeamwork(2)}
                >
                  2
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    teamwork === 3 && "ReviewModal__stat-number--yellow",
                  )}
                  onClick={() => setTeamwork(3)}
                >
                  3
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    teamwork === 4 && "ReviewModal__stat-number--yellow",
                  )}
                  onClick={() => setTeamwork(4)}
                >
                  4
                </div>
                <div
                  className={cs(
                    "ReviewModal__stat-number",
                    teamwork === 5 && "ReviewModal__stat-number--green",
                  )}
                  onClick={() => setTeamwork(5)}
                >
                  5
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ReviewModal__text-area-container">
          <div className="ReviewModal__text-area-label">
            {t("jobs.reviewModal.addCommentLabel")} (Optional)
          </div>
          <TextAreaInput
            name="review"
            value={formik.values.review}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            className="ReviewModal__textarea"
          />
          {formik.errors.review ? (
            <div className="ReviewModal__error">{formik.errors.review}</div>
          ) : null}
        </div>

        <div className="ReviewModal__buttons">
          <Button
            type="submit"
            style={{ height: "71px", width: "207px" }}
            label={t("jobs.reviewModal.complete")}
            onClick={() => {
              formik.handleSubmit();
            }}
            className="ReviewModal__submit-btn"
            disabled={
              attendance === 0 ||
              teamwork === 0 ||
              skill === 0 ||
              communication === 0 ||
              isLoading
            }
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export default ReviewModal;
