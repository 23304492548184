import React from "react";

import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";
import peakJobsLogo from "../../assets/svg/peak-jobs-logo.svg";
import cs from "classnames";

import "./ResetPassword.scss";

const ResetPassword = () => {
  const isMobile = useResponsiveDimensions().isMobile;

  return (
    <div className="ResetPassword">
      <div
        className={cs(
          "ResetPassword__left",
          isMobile && "ResetPassword__left-mobile",
        )}
      >
        <div className="ResetPassword__left-container">
          <div className="ResetPassword__logo">
            <img
              alt="pic"
              src={peakJobsLogo}
              className="ResetPassword__logo-icon"
            />
          </div>
          <div className="ResetPassword__form">
            <ResetPasswordForm />
          </div>
        </div>
      </div>

      {!isMobile && (
        <div className="ResetPassword__right">
          <div className="ResetPassword__right-container">
            <div className="ResetPassword__circle">
              <p className="ResetPassword__circle-text">
                PEAK <br /> YOUR SHIFT
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
