import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import peakJobsLogo from "../../assets/svg/peak-jobs-logo.svg";
import circle from "../../assets/svg/small-circle-opacity-two.svg";
import peakJobsIcon from "../../assets/svg/peak-jobs-icon.svg";
import { useGlobalContext } from "../../context/GlobalContext/GlobalContext";
import cs from "classnames";
import { apiCall } from "../../api/Api";
import InputField from "../../components/shared/InputField/InputField";
import { convertRestApiErrorCodes } from "../../lib/helpers/handleErrors";
import Button from "../../components/shared/Button/Button";
import FourDigits from "../../components/shared/FourDigits/FourDigits";
import messageIcon from "../../assets/svg/message-icon.svg";
import getMessageEffectIcon from "../../assets/svg/get-message-effect.svg";
import ksFlag from "../../assets/flags/kosova.svg";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext/AuthContext";

import "./VerifyPhoneNumber.scss";

const VerifyPhoneNumber = () => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [activeStep, setActiveStep] = useState("sendMessage");
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [fourDigitsValue, setFourDigitsValue] = useState("");
  const [fourDigitsError, setFourDigitsError] = useState("");
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();
  const authCtx = useAuthContext();
  const { t } = useTranslation("common");
  const isMobile = useResponsiveDimensions().isMobile;

  const handleNextClick = () => {
    if (phoneNumberValue.length === 0) {
      setPhoneNumberError(t("verifyPhoneNumber.modal.sendMessage.errorOne"));
      return;
    }
    if (/^\d{8}$/.test(phoneNumberValue)) {
      setIsButtonLoading(true);
      apiCall("POST", "b2b/user/verify-phone/", {
        phone_number: `+383${phoneNumberValue}`,
      })
        .then(() => {
          setActiveStep("getMessage");
        })
        .catch((error) => {
          const errorMessage = convertRestApiErrorCodes(
            error?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
        })
        .finally(() => {
          setIsButtonLoading(false);
        });
    } else {
      setPhoneNumberError(t("verifyPhoneNumber.modal.sendMessage.errorTwo"));
    }
  };

  const handleVerifyClick = async () => {
    if (fourDigitsValue.length < 4) {
      setFourDigitsError(t("verifyPhoneNumber.modal.getMessage.error"));
    } else {
      try {
        setIsButtonLoading(true);
        await apiCall("POST", "b2b/user/verify-phone-otp/", {
          phone_number: `+383${phoneNumberValue}`,
          otp: fourDigitsValue,
        });

        //GET USER PROFILE
        const profileData = await apiCall("GET", "b2b/user/profile/");

        //IF COMPANY IS NOT CREATED
        if (profileData.data.user.organization === null) {
          navigate("/create-company");
          return;
        }

        //GET COMPANY
        const companyData = await apiCall("GET", "b2b/organization/");

        // SAVE PROFILE DATA TO LOCAL STORAGE AND RESET STATE
        authCtx.setDataToLocalStorage(
          profileData.data.profile,
          companyData.data,
        );
        //PROCEED
        navigate("/");
      } catch (error: any) {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
      } finally {
        setIsButtonLoading(false);
      }
    }
  };

  return (
    <div className="VerifyPhoneNumber">
      {!isMobile && (
        <div className="VerifyPhoneNumber__left">
          <div className="VerifyPhoneNumber__left-container">
            <div className="VerifyPhoneNumber__logo">
              <img
                alt="pic"
                src={peakJobsLogo}
                className="VerifyPhoneNumber__logo-icon"
              />
            </div>
            <div className="VerifyPhoneNumber__title">
              <h1 className="VerifyPhoneNumber__title-text">
                {t("verifyPhoneNumber.headerButton.textOne")} <br />
                {t("verifyPhoneNumber.headerButton.textTwo")}
              </h1>
            </div>
          </div>
          <div className="VerifyPhoneNumber__circle">
            <img
              alt="pic"
              src={circle}
              className="VerifyPhoneNumber__circle-background"
            />
            <img
              alt="pic"
              src={peakJobsIcon}
              className="VerifyPhoneNumber__circle-icon"
            />
          </div>
        </div>
      )}
      <div
        className={cs(
          "VerifyPhoneNumber__right",
          isMobile && "VerifyPhoneNumber__right-mobile",
        )}
      >
        <div className="VerifyPhoneNumber__right-container">
          <div className="VerifyPhoneNumber__right-head">
            <p className="VerifyPhoneNumber__right-title">
              {`${t("verifyPhoneNumber.headerButton.textOne")} ${t("verifyPhoneNumber.headerButton.textTwo")} `}
            </p>
          </div>
          <div className="VerifyPhoneNumber__right-body">
            {activeStep === "sendMessage" && (
              <div className="SendMessage">
                <p className="SendMessage__title">
                  {t("verifyPhoneNumber.modal.sendMessage.title")}
                </p>
                <div className="SendMessage__icon-container">
                  <img
                    alt="pic"
                    className="SendMessage__icon"
                    src={messageIcon}
                  />
                </div>
                <div className="SendMessage__inputs">
                  <InputField
                    onBlur={() => {}}
                    onChange={() => {}}
                    placeholder="+383"
                    value="+383"
                    leftIcon={ksFlag}
                  />
                  <InputField
                    onBlur={() => {}}
                    onChange={(e: any) => {
                      setPhoneNumberValue(e.target.value);
                      setPhoneNumberError("");
                    }}
                    placeholder="44123456"
                  />
                </div>
                <h5 className="SendMessage__inputs-error">
                  {phoneNumberError}
                </h5>

                <Button
                  isLoading={isButtonLoading}
                  label={t("verifyPhoneNumber.modal.sendMessage.button")}
                  onClick={() => {
                    handleNextClick();
                  }}
                />
              </div>
            )}
            {activeStep === "getMessage" && (
              <div className="GetMessage">
                <p className="GetMessage__title">
                  {t("verifyPhoneNumber.modal.getMessage.title")}
                </p>
                <div className="GetMessage__icon-container">
                  <img
                    alt="pic"
                    className="GetMessage__icon GetMessage__icon--effect"
                    src={getMessageEffectIcon}
                  />
                  <img
                    alt="pic"
                    className="GetMessage__icon GetMessage__icon--message"
                    src={messageIcon}
                  />
                </div>
                <div className="GetMessage__inputs">
                  <FourDigits
                    getValues={(values) => {
                      setFourDigitsValue(values);
                      setFourDigitsError("");
                    }}
                  />
                </div>
                <h5 className="SendMessage__inputs-error">{fourDigitsError}</h5>

                <Button
                  isLoading={isButtonLoading}
                  label={t("verifyPhoneNumber.modal.getMessage.button")}
                  onClick={() => {
                    handleVerifyClick();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPhoneNumber;
