import React from "react";

import "./Environment.scss";

const Environment = () => {
  return (
    <div className="Environment">
      <div className="Environment__container">
        <div className="Environment__item">
          <div className="Environment__item-head">Environment: </div>
          <div className="Environment__item-body">
            {process.env.REACT_APP_ENVIRONMENT}
          </div>
        </div>{" "}
        <div className="Environment__item">
          <div className="Environment__item-head">Version: </div>
          <div className="Environment__item-body">{process.env.REACT_APP_VERSION}</div>
        </div>
      </div>
    </div>
  );
};

export default Environment;
