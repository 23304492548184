import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useResetPasswordFormik } from "./useResetPasswordFormik";
import { useNavigate } from "react-router-dom";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import lock from "../../../assets/svg/lock.svg";

import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { apiCallWithoutAuth } from "../../../api/Api";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";

import "./ResetPasswordForm.scss";

const ResetPasswordForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("common");
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();

  const formik = useResetPasswordFormik({
    onSubmit: async (values: any) => {
      try {
        setIsLoading(true);
        //RESET PASSWORD
        await apiCallWithoutAuth("post", "b2b/user/reset-password/", {
          email: values.email,
        });
        globalCtx.openMessageModal(
          "success",
          t("staticMessage.resetPasswordSuccess"),
        );
      } catch (error: any) {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <form className="ResetPasswordForm" onSubmit={formik.handleSubmit}>
      <div className="ResetPasswordForm__container">
        <div className="ResetPasswordForm__title">
          <img alt="pic" src={lock} className="ResetPasswordForm__title-icon" />
          <h2 className="ResetPasswordForm__title-text">
            {t("resetPassword.form.title")}
          </h2>
        </div>
        <p className="ResetPasswordForm__description">
          {t("resetPassword.form.description")}
        </p>{" "}
        <div className="ResetPasswordForm__input">
          <InputField
            type="text"
            placeholder="Email"
            name="email"
            value={formik.values.email}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
          />
          {formik.errors.email && formik.touched.email && (
            <h5 className="ResetPasswordForm__input-error">
              {formik.errors.email as string}
            </h5>
          )}
        </div>
        <div className="ResetPasswordForm__button">
          <Button
            isLoading={isLoading}
            onClick={() => {}}
            label={t("resetPassword.form.button")}
            type="submit"
          />
        </div>
        <p
          className="ResetPasswordForm__back"
          onClick={() => {
            navigate("/sign-in");
          }}
        >
          {t("backToSignIn")}
        </p>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
