import React from "react";

import { useAuthContext } from "../../context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import plusIcon from "../../assets/svg/plus-icon.svg";
import dashboardCircle from "../../assets/svg/dashboard-circle.svg";

import "./Dashboard.scss";

const Dashboard = () => {
  const authCtx = useAuthContext();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const isMobile = useResponsiveDimensions().isMobile;

  return (
    <div className="Dashboard">
      <div className="WelcomeCart">
        <div className="WelcomeCart__body">
          <p className="WelcomeCart__title">
            <span>{t("dashboard.welcomeCart.title")}</span> {""}
            {isMobile && <br />}
            <span>{authCtx.localUserProfile.display_name}</span>{" "}
          </p>
          <p className="WelcomeCart__subtitle">
            {t("dashboard.welcomeCart.description")}
          </p>
          <div className="WelcomeCart__steps">
            <div className="WelcomeCart__step">
              <div className="WelcomeCart__step-number">1</div>
              <h5 className="WelcomeCart__step-text">
                {t("dashboard.welcomeCart.stepOne")}
              </h5>
            </div>
            <div className="WelcomeCart__step">
              <div className="WelcomeCart__step-number">2</div>
              <h5 className="WelcomeCart__step-text">
                {t("dashboard.welcomeCart.stepTwo")}
              </h5>
            </div>
            <div className="WelcomeCart__step">
              <div className="WelcomeCart__step-number">3</div>
              <h5 className="WelcomeCart__step-text">
                {t("dashboard.welcomeCart.stepThree")}
              </h5>
            </div>
          </div>
        </div>
        <div className="WelcomeCart__footer">
          <div
            className="WelcomeCart__button"
            onClick={() => {
              navigate("/jobs/upcoming-not-assigned");
            }}
          >
            <div className="WelcomeCart__button-round">
              <img
                alt="pic"
                src={plusIcon}
                className="WelcomeCart__button-round--plus"
              />
            </div>
            <p className="WelcomeCart__button-text">
              {t("dashboard.welcomeCart.button")}
            </p>
          </div>
        </div>

        <img alt="pic" src={dashboardCircle} className="WelcomeCart__circle" />
      </div>
    </div>
  );
};

export default Dashboard;
