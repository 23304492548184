import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useCreateCompanyFormik } from "./useCreateCompanyFormik";
import { apiCall } from "../../../api/Api";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import Mapbox, { LocationType } from "../../../components/shared/Mapbox/Mapbox";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";
import FileUploader from "../../../components/shared/FileUploader/FileUploader";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import TextAreaInput from "../../../components/shared/TextAreaInput/TextAreaInput";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import companyAvatar from "../../../assets/images/company-avatar.png";
import penLogo from "../../../assets/svg/pen-logo.svg";
import deleteIcon from "../../../assets/svg/delete-icon.svg";
import markerIcon from "../../../assets/svg/marker.svg";

import "./CreateCompanyForm.scss";

const CreateCompanyForm = () => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isImageUploaderOpen, setIsImageUploaderOpen] = useState(false);
  const [base64Image, setBase64Image] = useState("");
  const [base64ImageError, setBase64ImageError] = useState(false);
  const [location, setLocation] = useState<LocationType | null>();
  const [locationError, setLocationError] = useState(false);
  const [locationRequiredError, setLocationRequiredError] = useState(false);
  const [locationStreetRequiredError, setLocationStreetRequiredError] =
    useState(false);
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const globalCtx = useGlobalContext();
  const authCtx = useAuthContext();

  const formik = useCreateCompanyFormik({
    onSubmit: async (values: any) => {
      if (base64Image.length === 0) {
        setBase64ImageError(true);
        return;
      }
      if (location === undefined) {
        setLocationRequiredError(true);
        return;
      }
      if (location === null) {
        setLocationError(true);
        return;
      }
      if (location.street === null) {
        setLocationStreetRequiredError(true);
        return;
      }
      try {
        setIsButtonLoading(true);
        const profileDataObject = {
          display_name: values.nameSurname,
          language: globalCtx.selectedLanguage.keyword,
        };

        const data = {
          name: values.companyName,
          unique_business_identifier: values.companyNumber,
          description: values.companyDescription,
          logo_data: base64Image,
          address: location.full_address,
          city: location.city,
          country: location.country_code,
          latitude: location.latitude,
          longitude: location.longitude,
        };

        // CREATE USER PROFILE
        await apiCall("POST", "b2b/user/profile/", profileDataObject);

        // CREATE COMPANY
        const companyData = await apiCall("POST", "b2b/organization/", data);

        // GET USER PROFILE
        const profileData = await apiCall("GET", "b2b/user/profile/");

        // SAVE PROFILE DATA TO LOCAL STORAGE AND RESET STATE
        authCtx.setDataToLocalStorage(
          profileData.data.profile,
          companyData.data,
        );
        // PROCEED
        navigate("/");
      } catch (error: any) {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
      } finally {
        setIsButtonLoading(false);
      }
    },
  });

  useEffect(() => {
    if (location !== undefined) {
      setLocationRequiredError(false);

      if (location !== null) {
        setLocationError(false);

        if (location.street !== null) {
          setLocationStreetRequiredError(false);
        }
      }
    }
  }, [location]);

  return (
    <>
      {isImageUploaderOpen && (
        <ModalContainer>
          <FileUploader
            maxFileSizeInBytes={5242880}
            accept="image/*"
            onCancel={() => {
              setIsImageUploaderOpen(false);
            }}
            onSubmit={(fileName, base64) => {
              if (base64 !== undefined) {
                setBase64Image(base64);
                setIsImageUploaderOpen(false);
                setBase64ImageError(false);
              }
            }}
          />
        </ModalContainer>
      )}
      <div className="CreateCompanyForm">
        <p className="CreateCompanyForm__description">
          {t("createCompany.companyDetails.form.description")}
        </p>
        <div className="CreateCompanyForm__body">
          <div className="CompanyDetails">
            <div className="CompanyDetails__body">
              <div className="CompanyDetails__uploader">
                <div className="CompanyDetails__circle">
                  {base64Image === "" ? (
                    <img
                      alt="pic"
                      src={companyAvatar}
                      className="CompanyDetails__placeholder"
                    />
                  ) : (
                    <img
                      alt="pic"
                      src={base64Image}
                      className="CompanyDetails__image"
                    />
                  )}

                  <img
                    alt="pic"
                    src={base64Image === "" ? penLogo : deleteIcon}
                    className="CompanyDetails__pen"
                    onClick={() => {
                      base64Image === ""
                        ? setIsImageUploaderOpen(true)
                        : setBase64Image("");
                    }}
                  />
                </div>
              </div>
              <div className="CompanyDetails__uploader-description">
                {t("createCompany.companyDetails.form.companyLogoDescription")}
              </div>
              <div className="CompanyDetails__input">
                <InputField
                  type="text"
                  placeholder={t(
                    "createCompany.companyDetails.form.nameSurname.placeholder",
                  )}
                  name="nameSurname"
                  value={formik.values.nameSurname}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                />
                <div className="CompanyDetails__input-description">
                  {t(
                    "createCompany.companyDetails.form.nameSurname.description",
                  )}
                </div>
                {formik.errors.nameSurname && formik.touched.nameSurname && (
                  <h5 className="CompanyDetails__input-error">
                    {formik.errors.nameSurname as string}
                  </h5>
                )}
              </div>{" "}
              <div className="CompanyDetails__input">
                <InputField
                  type="text"
                  placeholder={t(
                    "createCompany.companyDetails.form.companyName.placeholder",
                  )}
                  name="companyName"
                  value={formik.values.companyName}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                />
                <div className="CompanyDetails__input-description">
                  {t(
                    "createCompany.companyDetails.form.companyName.description",
                  )}
                </div>
                {formik.errors.companyName && formik.touched.companyName && (
                  <h5 className="CompanyDetails__input-error">
                    {formik.errors.companyName as string}
                  </h5>
                )}
              </div>{" "}
              <div className="CompanyDetails__input">
                <InputField
                  type="text"
                  placeholder={t(
                    "createCompany.companyDetails.form.companyNumber.placeholder",
                  )}
                  name="companyNumber"
                  value={formik.values.companyNumber}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                />
                <div className="CompanyDetails__input-description">
                  {t(
                    "createCompany.companyDetails.form.companyNumber.description",
                  )}
                </div>
                {formik.errors.companyNumber &&
                  formik.touched.companyNumber && (
                    <h5 className="CompanyDetails__input-error">
                      {formik.errors.companyNumber as string}
                    </h5>
                  )}
              </div>{" "}
              <div className="CompanyDetails__textArea">
                <TextAreaInput
                  placeholder={t(
                    "createCompany.companyDetails.form.companyDescription.placeholder",
                  )}
                  name="companyDescription"
                  value={formik.values.companyDescription}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                />
              </div>
              {formik.errors.companyDescription &&
                formik.touched.companyDescription && (
                  <h5 className="CompanyDetails__textArea-error">
                    {formik.errors.companyDescription as string}
                  </h5>
                )}
              <div className="CompanyDetails__map">
                <Mapbox
                  locationData={(location) => {
                    setLocation(location);
                  }}
                />
              </div>
              <div className="CompanyDetails__map-description">
                {t("mapComponent.descriptionOne")}
                <img alt="pic" width="10px" src={markerIcon} />{" "}
                {t("mapComponent.descriptionTwo")}
              </div>
              {locationError && (
                <h5 className="CompanyDetails__map-error">
                  {t("mapComponent.error")}
                </h5>
              )}{" "}
              {locationRequiredError && (
                <h5 className="CompanyDetails__map-error">
                  {t("mapComponent.errorRequired")}
                </h5>
              )}{" "}
              {locationStreetRequiredError && (
                <h5 className="CompanyDetails__map-error">
                  {t("mapComponent.errorStreetRequired")}
                </h5>
              )}
            </div>
            {base64ImageError && (
              <h5 className="CompanyDetails__uploader-error">
                {t("createCompany.companyDetails.form.companyLogoError")}
              </h5>
            )}
            <div className="CompanyDetails__footer">
              <div className="CompanyDetails__button">
                <Button
                  isLoading={isButtonLoading}
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  label={t("createCompany.companyDetails.form.button")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCompanyForm;
