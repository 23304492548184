import React from "react";

import { Route, Routes } from "react-router-dom";
import {
  PrivateRoutesComponents,
  PublicRoutesComponents,
} from "./RoutesComponents";
import PrivateRoutesContainer from "./PrivateRoutesContainer";
import PublicRoutesContainer from "./PublicRoutesContainer";
import { useAuthContext } from "../context/AuthContext/AuthContext";

const RoutesContainer = () => {
  const authCtx = useAuthContext();

  return (
    <Routes>
      <Route
        element={
          <PrivateRoutesContainer
            isAuth={
              authCtx.currentUser !== null &&
              authCtx.localUserProfile !== null &&
              authCtx.localOrganization !== null
            }
          />
        }
      >
        {PrivateRoutesComponents.map((r) => (
          <Route key={r.path} path={r.path} element={r.element} />
        ))}
      </Route>

      <Route element={<PublicRoutesContainer />}>
        {PublicRoutesComponents.map((r) => (
          <Route element={r.element} key={r.path} path={r.path} />
        ))}
      </Route>
    </Routes>
  );
};

export default RoutesContainer;
