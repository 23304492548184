import React from "react";

import "./LoadingComponent.scss";

const LoadingComponent = () => {
  return (
    <div className="LoadingComponent">
      <div className="LoadingComponent__spinner"></div>
    </div>
  );
};

export default LoadingComponent;
