import React from "react";
import { useInView } from "react-intersection-observer";

const InfiniteScroll = (props:any) => {
  const {loadMore} = props;
  const [ref, inView] = useInView({
   // triggerOnce: true,
    //initialInView: true,
    threshold: 0
  });

  React.useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView]);

  return (<div ref={ref} style={{ height: "10px" }} />);
};

export default InfiniteScroll;