import React from "react";

import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import ModalContainer from "../ModalContainer/ModalContainer";
import closeIcon from "../../../assets/svg/close-white.svg";
import diamondIcon from "../../../assets/svg/diamond.svg";
import companyAvatar from "../../../assets/images/company-avatar.png";

import "./SubscribeForUnitsModal.scss";
import { useTranslation } from "react-i18next";

interface SubscribeForUnitsModalProps {
  onUpgradeClick: () => void;
  onCloseClick: () => void;
  image?: string;
  name?: string;
  number?: number;
}

const SubscribeForUnitsModal = (props: SubscribeForUnitsModalProps) => {
  const { onUpgradeClick, onCloseClick, image, name, number } = props;
  const isMobile = useResponsiveDimensions().isMobile;
  const { t } = useTranslation("common");

  return (
    <ModalContainer>
      <div className="SubscribeForUnitsModal">
        <img
          alt="pic"
          className="SubscribeForUnitsModal__close"
          src={closeIcon}
          onClick={() => {
            onCloseClick();
          }}
        />
        <div className="SubscribeForUnitsModal__body">
          <div className="SubscribeForUnitsModal__left">
            <p className="SubscribeForUnitsModal__title">
              {t("subscribeForUnitsModal.title.partOne")} <br />{" "}
              {t("subscribeForUnitsModal.title.partTwo")}
              <br /> {t("subscribeForUnitsModal.title.partThree")}
            </p>

            <p className="SubscribeForUnitsModal__description">
              {t("subscribeForUnitsModal.description")}
            </p>
            {!isMobile && (
              <div className="SubscribeForUnitsModal__button-container">
                <div
                  className="SubscribeForUnitsModal__button"
                  onClick={() => {
                    onUpgradeClick();
                  }}
                >
                  <div className="SubscribeForUnitsModal__button-round">
                    <img
                      alt="pic"
                      src={diamondIcon}
                      className="SubscribeForUnitsModal__button-round--plus"
                    />
                  </div>
                  <p className="SubscribeForUnitsModal__button-text">
                    {t("subscribeForUnitsModal.upgrade")}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="SubscribeForUnitsModal__right">
            <div className="SubscribeForUnitsModalCart">
              <div className="SubscribeForUnitsModalCart__container">
                <img
                  alt="pic"
                  className="SubscribeForUnitsModalCart__icon"
                  src={image || companyAvatar}
                />
                <p className="SubscribeForUnitsModalCart__title">
                  {name || "Unit Name"}
                </p>
                <div className="SubscribeForUnitsModalCart__number">
                  {`+${number}` || "+10"}
                </div>
              </div>
              <div className="SubscribeForUnitsModalCart__shadow"></div>
              <div className="SubscribeForUnitsModalCart__shadowTwo"></div>
            </div>{" "}
            {isMobile && (
              <div className="SubscribeForUnitsModal__button-container">
                <div
                  className="SubscribeForUnitsModal__button"
                  onClick={() => {
                    onUpgradeClick();
                  }}
                >
                  <div className="SubscribeForUnitsModal__button-round">
                    <img
                      alt="pic"
                      src={diamondIcon}
                      className="SubscribeForUnitsModal__button-round--plus"
                    />
                  </div>
                  <p className="SubscribeForUnitsModal__button-text">
                    {t("subscribeForUnitsModal.upgrade")}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default SubscribeForUnitsModal;
