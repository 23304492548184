import React from "react";

import cs from "classnames";

import "./InputField.scss";

interface InputFieldProps {
  name?: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  value?: string;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
  onIconClick?: () => void;
  style?: React.CSSProperties;
  leftIcon?: string;
  icon?: string;
  className?: string;
  textIcon?: string;
  min?: string;
  max?: string;
}

const InputField = (props: InputFieldProps) => {
  const {
    onIconClick,
    name,
    placeholder,
    type,
    disabled,
    value,
    onBlur,
    onChange,
    style,
    leftIcon,
    icon,
    className,
    textIcon,
  } = props;
  return (
    <div className="InputField">
      <div
        className={cs(
          "InputField__container",
          disabled && `InputField__container--disabled`,
          className,
        )}
      >
        {leftIcon && (
          <img
            onClick={onIconClick}
            alt="pic"
            src={leftIcon}
            className="InputField__leftIcon"
          />
        )}
        <input
          name={name}
          style={style}
          placeholder={placeholder}
          className={cs(
            "InputField__input",
            disabled && `InputField__input--disabled`,
          )}
          type={type}
          disabled={disabled}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          min={props.min}
          max={props.max}
        />
        {textIcon && <span className="InputField__icon-text">{textIcon}</span>}
        {icon && (
          <img
            onClick={onIconClick}
            alt="pic"
            src={icon}
            className="InputField__icon"
          />
        )}
      </div>
    </div>
  );
};

export default InputField;
