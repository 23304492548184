import React from "react";
import verified from "../../../../assets/svg/verified-icon-green.svg";
import cs from "classnames";
import acceptedIcon from "../../../../assets/svg/accepted-icon.svg";
import cancelledIcon from "../../../../assets/svg/cancelled-icon.svg";
import { useTranslation } from "react-i18next";

import "./ApplicationCard.scss";

const ApplicationCard = (props: any) => {
  const { person, jobName, setSelectedApplicant, setOpenSidebar } = props;
  const { t } = useTranslation("common");

  return (
    <section className="ApplicationCard">
      <div
        className={cs(
          "ApplicationCard__header",
          person.status === "accepted" && "ApplicationCard__header--accepted",
          person.status === "cancelled" && "ApplicationCard__header--cancelled",
        )}
      >
        <div className="ApplicationCard__person-image-wrapper">
          <img
            src={person.applicant?.profile?.picture_url}
            alt=""
            className="ApplicationCard__person-image"
          />
        </div>
        <div>
          <div className="ApplicationCard__name-container">
            <h2
              className={cs(
                "ApplicationCard__name",
                person.status === "accepted" &&
                  "ApplicationCard__name--accepted",
              )}
            >{`${person.applicant?.profile?.first_name} ${person.applicant?.profile?.last_name}`}</h2>
            {person.applicant?.verified && (
              <img
                src={verified}
                alt="verified icon"
                className="ApplicationCard__verified-icon"
              />
            )}
          </div>
          <div className="ApplicationCard__role">{jobName}</div>
          {person.status === "accepted" && (
            <div className="ApplicationCard__review-wrapper--accepted">
              <img
                src={acceptedIcon}
                alt="accepted icon"
                className="ApplicationCard__accepted-icon"
              />
              <span>{t("jobs.applicationCard.accepted")}</span>
            </div>
          )}{" "}
          {person.status !== "accepted" && person.status !== "cancelled" && (
            <div className="ApplicationCard__review-wrapper">
              &#9733;
              {`${person.applicant?.profile?.average_rating}(${person.applicant?.profile?.total_reviews}) `}
            </div>
          )}
          {person.status === "cancelled" && (
            <div className="ApplicationCard__review-wrapper--accepted">
              <img
                src={cancelledIcon}
                alt="cancelled icon"
                className="ApplicationCard__accepted-icon"
              />
              <span>{t("jobs.applicationCard.canceled")}</span>
            </div>
          )}
        </div>
      </div>
      <div className="ApplicationCard__body-main-container">
        <div className="ApplicationCard__body-wrap">
          <p className="ApplicationCard__body-job-name">
            {t("jobs.applicationCard.jobs")} {jobName}
          </p>
          <div className="ApplicationCard__body-job-name-number">
            {person.applicant?.profile?.this_job_title_jobs}
          </div>
        </div>
        <div className="ApplicationCard__body-wrap">
          <p className="ApplicationCard__body-job-name">
            {t("jobs.applicationCard.totalJobs")}
          </p>
          <div className="ApplicationCard__body-job-name-number">
            {person.applicant?.profile?.total_jobs}
          </div>
        </div>
      </div>
      <div className="ApplicationCard__body">
        <div className="ApplicationCard__body-left">
          <div className="ApplicationCard__body-job-stat">
            <div className="ApplicationCard__body-job-stat-wrap">
              <div className="ApplicationCard__stat-label">
                {t("jobs.applicationCard.attendance")}
              </div>
              <div className="ApplicationCard__stat-number">
                {person.applicant?.profile?.attendance_average_rating}
              </div>
            </div>
            <div className="ApplicationCard__stat-line">
              {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                <div
                  key={index}
                  className={cs(`ApplicationCard__stat-line-one
                        ${person.applicant?.profile?.attendance_average_rating === 1 && item === 1 && `ApplicationCard__stat-line-one--red`}
                        ${
                          person.applicant?.profile
                            ?.attendance_average_rating <= 4 &&
                          person.applicant?.profile?.attendance_average_rating >
                            1 &&
                          item <=
                            person.applicant?.profile
                              ?.attendance_average_rating &&
                          person.applicant?.profile
                            ?.attendance_average_rating !== null &&
                          `ApplicationCard__stat-line-one--yellow`
                        }
                        ${person.applicant?.profile?.attendance_average_rating === 5 && `ApplicationCard__stat-line-one--green`}
                        `)}
                />
              ))}
            </div>
          </div>
          <div className="ApplicationCard__body-job-stat">
            <div className="ApplicationCard__body-job-stat-wrap">
              <div className="ApplicationCard__stat-label">
                {t("jobs.applicationCard.communication")}
              </div>
              <div className="ApplicationCard__stat-number">
                {person.applicant?.profile?.communication_average_rating}
              </div>
            </div>
            <div className="ApplicationCard__stat-line">
              {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                <div
                  key={index}
                  className={cs(`ApplicationCard__stat-line-one
                        ${person.applicant?.profile?.communication_average_rating === 1 && item === 1 && `ApplicationCard__stat-line-one--red`}
                        ${
                          person.applicant?.profile
                            ?.communication_average_rating <= 4 &&
                          person.applicant?.profile
                            ?.communication_average_rating > 1 &&
                          item <=
                            person.applicant?.profile
                              ?.communication_average_rating &&
                          person.applicant?.profile
                            ?.communication_average_rating !== null &&
                          `ApplicationCard__stat-line-one--yellow`
                        }
                        ${person.applicant?.profile?.communication_average_rating === 5 && `ApplicationCard__stat-line-one--green`}
                        `)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="ApplicationCard__body-right">
          <div className="ApplicationCard__body-job-stat">
            <div className="ApplicationCard__body-job-stat-wrap">
              <div className="ApplicationCard__stat-label">
                {t("jobs.applicationCard.skills")}
              </div>
              <div className="ApplicationCard__stat-number">
                {person.applicant?.profile?.skill_average_rating}
              </div>
            </div>
            <div className="ApplicationCard__stat-line">
              {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                <div
                  key={index}
                  className={cs(`ApplicationCard__stat-line-one
                        ${person.applicant?.profile?.skill_average_rating === 1 && item === 1 && `ApplicationCard__stat-line-one--red`}
                        ${
                          person.applicant?.profile?.skill_average_rating <=
                            4 &&
                          person.applicant?.profile?.skill_average_rating > 1 &&
                          item <=
                            person.applicant?.profile?.skill_average_rating &&
                          person.applicant?.profile?.skill_average_rating !==
                            null &&
                          `ApplicationCard__stat-line-one--yellow`
                        }
                        ${person.applicant?.profile?.skill_average_rating === 5 && `ApplicationCard__stat-line-one--green`}
                        `)}
                />
              ))}
            </div>
          </div>
          <div className="ApplicationCard__body-job-stat">
            <div className="ApplicationCard__body-job-stat-wrap">
              <div className="ApplicationCard__stat-label">
                {t("jobs.applicationCard.teamWork")}
              </div>
              <div className="ApplicationCard__stat-number">
                {person.applicant?.profile?.teamwork_average_rating}
              </div>
            </div>
            <div className="ApplicationCard__stat-line">
              {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                <div
                  key={index}
                  className={cs(`ApplicationCard__stat-line-one
                        ${person.applicant?.profile?.teamwork_average_rating === 1 && item === 1 && `ApplicationCard__stat-line-one--red`}
                        ${
                          person.applicant?.profile?.teamwork_average_rating <=
                            4 &&
                          person.applicant?.profile?.teamwork_average_rating >
                            1 &&
                          item <=
                            person.applicant?.profile
                              ?.teamwork_average_rating &&
                          person.applicant?.profile?.teamwork_average_rating !==
                            null &&
                          `ApplicationCard__stat-line-one--yellow`
                        }
                        ${person.applicant?.profile?.teamwork_average_rating === 5 && `ApplicationCard__stat-line-one--green`}
                        `)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="ApplicationCard__back-icon-wrapper"
        onClick={() => {
          setSelectedApplicant(person);
          setOpenSidebar(true);
        }}
      >
        <span>{t("jobs.applicationCard.view")}</span>
        <svg
          className="ApplicationCard__back-icon"
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="1"
            d="M9 1L2 8.00029L9 15"
            stroke="#132342"
            strokeWidth="2"
          />
        </svg>
      </div>
    </section>
  );
};

export default ApplicationCard;
