import common_EN from "./EN/common.json";
import common_SQ from "./SQ/common.json";

export const resources = {
  EN: {
    common: common_EN,
  },
  SQ: {
    common: common_SQ,
  },
};
