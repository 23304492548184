import React, { useRef, useState } from "react";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import cs from "classnames";
import chevronDown from "../../../assets/svg/chevron-down.svg";

import "./SelectBox.scss";

export interface ItemsType {
  name: string | undefined | any;
  title?: any;
  value?: string | undefined;
}

interface SelectBoxProps {
  items?: ItemsType[];
  className?: string;
  className2?: string;
  style?: any;
  selectedItem: any;
  onItemSelect: (name: any) => void;
  onClick: () => void;
  labelStyle?: React.CSSProperties;
  placeholder?: string;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
}

const SelectBox = (props: SelectBoxProps) => {
  const { items, selectedItem, className, className2, style, labelStyle, placeholder, onBlur,
    onChange } =
    props;
  const [showItems, setShowItems] = useState(false);

  const dropDownRef = useRef(null);

  const onItemSelect = (name: any) => {
    
    props.onItemSelect(name);
    setShowItems(!showItems);
  };

  const handleOutSideClick = () => {
    setShowItems(false);
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  return (
    <div ref={dropDownRef} className={cs("SelectBox", className)} style={style}>
      <div  className="SelectBox__container" onClick={() => setShowItems(!showItems)}>
      <input
        className="SelectBox__input"
        placeholder={placeholder}
        value={selectedItem?.name}
        onBlur={onBlur}
        onChange={onChange}

      />
      <img
          className="SelectBox__icon"
          src={chevronDown}
          alt="chevron down icon"
     />
     </div>
      <div
        className={cs("SelectBox__items", showItems && "active", className2)}
      >
        {items?.map((item, index) => {
          return (
            <div
              onClick={() => {
                if(!item.name) {
                  let nameEng = item?.title?.en;
                  onItemSelect(nameEng);
                  
                }else {
                  onItemSelect(item);
                }
             
              }}
              className={`SelectBox__item-container ${
                props.selectedItem.name === item.name ? "activeLabel" : ""
              }`}
              key={index}
            >
             {item.name && (<p className="SelectBox__item">{item.name}</p>)} 
             {item?.title?.en && (<p className="SelectBox__item">{item?.title?.en}</p>)} 
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectBox;