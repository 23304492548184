import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export const useNewJobFormik = (
  seatsAllowed: any,
  opts: {
    onSubmit?: any;
    initialValues?: any;
  },
) => {
  const { t } = useTranslation("common");

  const memoizedT = useCallback(t, [t]);

  return useFormik({
    initialValues: {
      hourly_rate: "",
      seats: "",
      description: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      hourly_rate: Yup.number()
        .min(2, memoizedT("jobs.addNewJobs.jobDetails.hourlyRate.min"))
        .required(memoizedT("jobs.addNewJobs.jobDetails.hourlyRate.required")),
      seats: Yup.number()
        .required(memoizedT("jobs.addNewJobs.jobDetails.seats.required"))
        .min(1)
        .max(
          seatsAllowed,
          `${memoizedT("jobs.addNewJobs.jobDetails.seats.seatInfo.textOne")} ${seatsAllowed} ${seatsAllowed === 1 ? memoizedT("jobs.addNewJobs.jobDetails.seats.seatInfo.textTwo") : memoizedT("jobs.addNewJobs.jobDetails.seats.seatInfo.textThree")}`,
        ),
      description: Yup.string()
        .required(memoizedT("jobs.addNewJobs.jobDetails.description.required"))
        .min(5, memoizedT("jobs.addNewJobs.jobDetails.description.min"))
        .max(2000, memoizedT("jobs.addNewJobs.jobDetails.description.max")),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
