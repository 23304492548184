import { createContext, useContext } from "react";

export interface GlobalContextType {
  selectedLanguage: { keyword: string; label: string };
  onLanguageSelect: (keyword: string, label: string) => void;
  messageModal: {
    text: string;
    type: string;
  };
  openMessageModal: (type: string, text: string) => void;
  closeMessageModal: () => void;
}

const GlobalContextValues: GlobalContextType = {
  selectedLanguage: { keyword: "", label: "" },
  onLanguageSelect: () => {},
  messageModal: { text: "", type: "" },
  openMessageModal: () => {},
  closeMessageModal: () => {},
};

export const GlobalContext =
  createContext<GlobalContextType>(GlobalContextValues);
export const useGlobalContext = () => useContext(GlobalContext);
