import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export const useEditJobDetailsFormik = (
  job: any,
  opts: {
    onSubmit?: any;
    initialValues?: any;
  }
) => {
  const { t } = useTranslation("common");

  const memoizedT = useCallback(t, [t]);

  return useFormik({
    initialValues: {
      hourly_rate: job?.pay_rate ? job?.pay_rate : "",
      description: job?.description ? job?.description : "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      hourly_rate: Yup.string()
        .matches(
          /^-?(\d+(\.\d*)?|\.\d+)$/,
          memoizedT("jobs.jobDetails.hourlyRate.matches")
        )
        .required(memoizedT("jobs.jobDetails.hourlyRate.required")),
      description: Yup.string()
        .required(memoizedT("jobs.jobDetails.description.required"))
        .min(5, memoizedT("jobs.jobDetails.description.min"))
        .max(1000, memoizedT("jobs.jobDetails.description.max")),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
