import React from "react";
import Button from "../Button/Button";
import ErrorIcon from "../../../assets/svg/error-emblem.svg";
import closeIcon from "../../../assets/svg/close-dark.svg";

import "./DeleteModal.scss";
import { useTranslation } from "react-i18next";

interface DeleteModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  name?: string;
}

const DeleteModal = (props: DeleteModalProps) => {
  const { t } = useTranslation("common");

  return (
    <div className="DeleteModal">
      <div className="DeleteModal__container">
        <img
          className="DeleteModal__close"
          alt="close icon"
          src={closeIcon}
          onClick={() => {
            props.onCancel();
          }}
        />
        <img src={ErrorIcon} alt="alert icon" className="DeleteModal__icon" />
        <h2 className="DeleteModal__label">
          {t("jobs.addNewJobs.shifts.deleteModal.title")} {props.name}?
        </h2>

        <div className="DeleteModal__buttons">
          <Button
            type="reset"
            style={{ height: "60px", marginRight: "5px" }}
            className="Button__secondary DeleteModal__cancel-btn"
            label={t("jobs.addNewJobs.shifts.deleteModal.cancel")}
            onClick={() => {
              props.onCancel();
            }}
          />
          <Button
            type="submit"
            style={{ height: "60px", marginLeft: "5px" }}
            label={t("jobs.addNewJobs.shifts.deleteModal.delete")}
            onClick={() => {
              props.onSubmit();
            }}
            className="DeleteModal__submit-btn"
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
