import React, { useEffect, useState, useRef } from "react";
import { listAllShiftAttendanceForJob } from "../../../api/Jobs";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import AttendanceCard from "./AttendanceCard/AttendanceCard";
import RightSidebar from "../../../components/shared/RightSidebar/RightSidebar";
import AttendanceProfile from "./AttendanceProfile/AttendanceProfile";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import cs from "classnames";
import chevronLeft from "../../../assets/svg/chevron-down-gray.svg";
import { apiCall } from "../../../api/Api";
import reloadIcon from "../../../assets/svg/Refresh_icon.svg";

import "./Attendance.scss";

const Attendance = () => {
  let jobStorage = JSON.parse(localStorage.getItem("selectedJob")!!);
  let jobStatusStorage = localStorage.getItem("jobStatus");
  let selectedLanguage = localStorage.getItem("lang");
  const availableJobsCount2 = localStorage.getItem("availableJobsCount");
  const assignedJobsCount2 = localStorage.getItem("assignedJobsCount");
  const completedJobsCount2 = localStorage.getItem("completedJobsCount");
  const unreviewedJobCount2 = localStorage.getItem("unreviewedJobCount");
  const [job, setJob] = useState(jobStorage);
  const [allAttendance, setAllAttendance] = useState<any>([]);
  const [error] = useState("");
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openRightSidebar, setOpenSidebar] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState<any>({});
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [availableJobsCount, setAvailableJobsCount] = useState(availableJobsCount2 ? availableJobsCount2 : 0);
  const [assignedJobsCount, setAssignedJobsCount] = useState(assignedJobsCount2 ? assignedJobsCount2 : 0);
  const [unreviewedJobCount, setUnreviewedJobCount] = useState(unreviewedJobCount2 ? unreviewedJobCount2 : 0);
  const [completedJobsCount, setCompletedJobsCount] = useState(completedJobsCount2 ? completedJobsCount2 : 0);
  const [refreshCalled, setRefreshCalled] = useState(false);
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();

  const rightSidebarRef = useRef(null);

  const hideRightSidebar = () => {
    setOpenSidebar(false);
    setSelectedApplicant({});
  };


  useEffect(() => {
      apiCall("GET", `b2b/jobs/?status=assigned&limit=0`)
      .then((res) => {
        setAssignedJobsCount(res.data.count);
      });
      apiCall("GET", `b2b/jobs/?status=available&limit=0`)
      .then((res) => {
        setAvailableJobsCount(res.data.count);
      });
      apiCall("GET", `b2b/jobs/?status=unreviewed&limit=0`).then((res) => setUnreviewedJobCount(res.data.count));
      apiCall("GET", `b2b/jobs/?status=completed&limit=0`).then((res) => setCompletedJobsCount(res.data.count));
  
  }, []);

  const refresh = () => {
    setIsLoading(true);
    listAllShiftAttendanceForJob(job.id)
    .then((res) => {
      setAllAttendance(res.data);
      setIsCalled(true);
      setIsLoading(false);
    })
    .catch((err) => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    refresh();
  }, []);

  return (
    <section className="Attendance">
      <div className="Applications__header">
        <div className="Applications__title-container">
        <h1 className="Applications__default-title">{t("jobs.title")}</h1>
        {isLoading ? 
          (<div className="Jobs__spinner" />) 
          : 
        (<img src={reloadIcon} alt="reload icon" className={cs("Applications__reload-icon", refreshCalled && "Applications__reload-icon--disable")} onClick={() => {
          if(!refreshCalled) {
            refresh();
            setRefreshCalled(true);
            setTimeout(() => {
              setRefreshCalled(false);
            }, 10000);
          } 
        }} />)}
        </div>
        <section className="Applications__container">
          <div
            onClick={() => navigate("/jobs/upcoming-not-assigned")}
            className={cs(
              "Applications__container-item",
              jobStatusStorage === "Upcoming" &&
                "Applications__container-item--active",
            )}
          >
            {t("jobs.upcomingTabLabel")}
          </div>
          <div
            onClick={() => {
              navigate("/jobs/past-unreviewed");
            }}
            className={cs(
              "Applications__container-item",
              jobStatusStorage === "Past" &&
                "Applications__container-item--active",
            )}
          >
            {t("jobs.pastTabLabel")}
          </div>
        </section>
        {jobStatusStorage === "Upcoming" && (
          <section className="Applications__buttons-container">
            <div
              className={cs(
                "Applications__not-assigned-btn",
                job.status === "available" &&
                  "Applications__not-assigned-btn--active",
              )}
              onClick={() => {
                navigate("/jobs/upcoming-not-assigned");
              }}
            >
              {` ${t("jobs.notAssigned.tabLabel")}  (${availableJobsCount})`}
            </div>
            <div
              className={cs(
                "Applications__assigned-btn",
                (job.status === "assigned" ||
                  job.status === "in_progress" ||
                  job.status === "unattended") &&
                  "Applications__assigned-btn--active",
              )}
              onClick={() => {
                navigate("/jobs/upcoming-assigned");
              }}
            >
              {` ${t("jobs.assigned.tabLabel")}  (${assignedJobsCount})`}
            </div>
          </section>
        )}
        {jobStatusStorage === "Past" && (
          <section className="Applications__buttons-container">
            <div
              className={cs(
                "Applications__status-btn",
                location?.state?.previousLocation === "Review" &&
                  "Applications__status-btn--active",
              )}
              onClick={() => {
                navigate("/jobs/past-unreviewed");
              }}
            >
              {`${t("jobs.review.tabLabel")} (${unreviewedJobCount})`}
            </div>
            <div
              className={cs(
                "Applications__status-btn",
                job.status === "completed" &&
                  location?.state?.previousLocation !== "Review" &&
                  "Applications__status-btn--active",
              )}
              onClick={() => {
                navigate("/jobs/past-reviewed");
              }}
            >
              {`${t("jobs.completed.tabLabel")} (${completedJobsCount})`}
            </div>
          </section>
        )}
        <div className="Applications__header-wrapper">
          <img
            alt="chevron left icon"
            src={chevronLeft}
            className="Applications__back-icon"
            onClick={() => {
              if (jobStatusStorage === "Upcoming") {
                navigate("/jobs/upcoming-assigned");
              }
              if (jobStatusStorage === "Past" && job.status === "completed") {
                navigate("/jobs/past-reviewed");
              }
              if (
                jobStatusStorage === "Past" &&
                location?.state?.previousLocation === "Review"
              ) {
                navigate("/jobs/past-unreviewed");
              }
            }}
          />
          <h2 className="Applications__title">{selectedLanguage === "SQ" ? `${job.job_title.title?.sq}` : `${job.job_title.title?.en}`}</h2>
        </div>
      </div>
      {isCalled && !isLoading && allAttendance?.length === 0 && (
        <div
          className="Attendance__no-content"
          style={{ margin: "150px auto" }}
        >
          {t("jobs.attendance.noContent")}
        </div>
      )}
      {isLoading && allAttendance?.length === 0 && (
        <div style={{ margin: "150px auto" }}>
          <LoadingComponent />
        </div>
      )}
      <div className="Attendance__container">
        {allAttendance &&
          allAttendance.map((person: any, index: number) => (
            <div key={index} className="Attendance__card">
              <AttendanceCard
                person={person}
                jobName={selectedLanguage === "SQ" ? job.job_title.title?.sq : job.job_title.title?.en}
                jobId={job?.id}
                setSelectedApplicant={setSelectedApplicant}
                setOpenSidebar={setOpenSidebar}
              />
            </div>
          ))}
      </div>
      {isLoading && allAttendance.length === 0 && (
        <div style={{ margin: "150px auto" }}>
          <LoadingComponent />
        </div>
      )}
      {openRightSidebar && (
        <div>
          <RightSidebar
            onClose={() => hideRightSidebar()}
            sidebarRef={rightSidebarRef}
          >
            <AttendanceProfile onClose={() => hideRightSidebar()} />
          </RightSidebar>
        </div>
      )}
    </section>
  );
};

export default Attendance;
