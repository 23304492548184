import React, { useEffect, useState } from "react";
import { apiCall } from "../../../api/Api";
import { getUnreviewedJobs, getCompletedJobs } from "../../../api/Jobs";
import JobCard from "../JobCard/JobCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import cs from "classnames";
import "./PastJobs.scss";
import plusIcon from "../../../assets/svg/plus-icon-white.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import reloadIcon2 from "../../../assets/svg/Refresh_icon.svg";

const PastJobs = () => {
  const [unreviewedJob, setUnreviewedJob] = useState<any>([]);
  const [nextPage, setNextPage] = useState<any>("");
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [jobStatus, setJobStatus] = useState("Review");
  const [unreviewedJobCount, setUnreviewedJobCount] = useState(0);
  const [completedJobsCount, setCompletedJobsCount] = useState(0);
  const [jobsCountLoading, setJobsCountLoading] = useState(false);
  const [mainJobStatus, setMainJobStatus] = useState<string>("Past");
  const [refreshCalled, setRefreshCalled] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  useEffect(() => {
    try {
      setJobsCountLoading(true);
      apiCall("GET", `b2b/jobs/?status=completed&limit=0`).then((res) => {
        setCompletedJobsCount(res.data.count);
        setJobsCountLoading(false);
      });
    } catch (error) {
      setJobsCountLoading(false);
    }
  }, []);

  const callJobs = () => {
    setIsLoading(true);
    getUnreviewedJobs()
      .then((res) => {
        setUnreviewedJob(res.data.results);
        setIsCalled(true);
        setUnreviewedJobCount(res.data.count);
        setNextPage(res.data?.next);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (!isCalled) {
      callJobs();
    }
  }, []);

  const loadMoreJobs = () => {
    if (nextPage !== null) {
      setIsLoading(true);
      let resultUrl = nextPage.split("?")[1];
      apiCall("GET", `b2b/jobs/?${resultUrl}`)
        .then((res) => {
          setUnreviewedJob([...unreviewedJob, ...res.data.results]);
          setIsCalled(true);
          setNextPage(res.data?.next);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: nextPage,
    onLoadMore: loadMoreJobs,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <section className="PastJobs">
      <div className="PastJobs__header-container">
      <div className="PastJobs__title-container">
        <div className="PastJobs__main-title">{t("jobs.title")}</div>
        {isLoading ? 
          (<div className="Jobs__spinner" />) 
          : 
       (<img src={reloadIcon2} alt="reload icon" className={cs("PastJobs__reload-icon", refreshCalled && "PastJobs__reload-icon--disable")} onClick={() => {
        if(!refreshCalled) {
          callJobs();
          setRefreshCalled(true);
          setTimeout(() => {
            setRefreshCalled(false);
          }, 10000);
        }
       }} />)}
      </div>
        <div className="PastJobs__button-wrapper">
          <div
            className="PastJobs__button"
            onClick={() => {
              navigate("/jobs/new-job");
            }}
          >
            <div className="PastJobs__button-round">
              <img
                alt="pic"
                src={plusIcon}
                className="PastJobs__button-round--plus"
              />
            </div>
            <p className="PastJobs__button-text">{t("jobs.addJobButton")}</p>
          </div>
        </div>
      </div>
      <section className="PastJobs__container">
        <div
          onClick={() => {
            setMainJobStatus("Upcoming");
            navigate("/jobs/upcoming-not-assigned");
          }}
          className={cs(
            "PastJobs__container-item",
            mainJobStatus === "Upcoming" && "PastJobs__container-item--active",
          )}
        >
          {t("jobs.upcomingTabLabel")}
        </div>
        <div
          onClick={() => {
            navigate("/jobs/past-unreviewed");
            setMainJobStatus("Past");
          }}
          className={cs(
            "PastJobs__container-item",
            mainJobStatus === "Past" && "PastJobs__container-item--active",
          )}
        >
          {t("jobs.pastTabLabel")}
        </div>
      </section>
      <section className="PastJobs__buttons-container">
        <div
          className={cs(
            "PastJobs__status-btn flex",
            jobStatus === "Review" && "PastJobs__status-btn--active",
          )}
          onClick={() => {
            setJobStatus("Review");
            navigate("/jobs/past-unreviewed");
            localStorage.setItem("completedJobsCount", `${completedJobsCount}`);
            localStorage.setItem("unreviewedJobCount", `${unreviewedJobCount}`);
          }}
        >
          {` ${t("jobs.review.tabLabel")}`}
          {jobsCountLoading ? (<div className="Jobs__spinner" />) : `(${unreviewedJobCount})`} 
        </div>
        <div
          className={cs(
            "PastJobs__status-btn flex",
            jobStatus === "Completed" && "PastJobs__status-btn--active",
          )}
          onClick={() => {
            navigate("/jobs/past-reviewed");
            localStorage.setItem("completedJobsCount", `${completedJobsCount}`);
            localStorage.setItem("unreviewedJobCount", `${unreviewedJobCount}`);
            setJobStatus("Completed");
          }}
        >
          {`${t("jobs.completed.tabLabel")}`}
          {jobsCountLoading ? (<div className="Jobs__spinner" />) : `(${completedJobsCount})`} 
        </div>
      </section>
      {jobStatus === "Review" && (
        <>
          {unreviewedJob?.length === 0 && isCalled && !loading && (
            <div className="PastJobs__no-content">
              {t("jobs.review.noContent")}
            </div>
          )}
          <div className="PastJobs__main-wrapper">
            {unreviewedJob &&
              unreviewedJob.map((job: any, index: number) => {
                return (
                  <div key={index} className="PastJobs__job-wrapper">
                    <JobCard
                      job={job}
                      shiftTotal={true}
                      jobStatus={"Past"}
                      subStatus="Review"
                    />
                  </div>
                );
              })}
          </div>
          <div>
            {nextPage && nextPage !== null && (
              <div ref={sentryRef}>
                <LoadingComponent />
              </div>
            )}
          </div>
        </>
      )}

      {isLoading && unreviewedJob.length === 0 && (
        <div style={{ margin: "150px auto" }}>
          <LoadingComponent />
        </div>
      )}
    </section>
  );
};

export default PastJobs;
