import React from "react";
import cs from "classnames";

import "./TextAreaInput.scss";

interface TextAreaInputPropsType {
  name?: string;
  value: string;
  placeholder?: string;
  label?: string;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
  className?: string;
  disabled?: boolean;
}


const TextAreaInput = (props: TextAreaInputPropsType) => {
  const { name, value, placeholder, label, onBlur, onChange, className, disabled } = props;
  return (
    <textarea
      className={cs("TextAreaInput", className, disabled && "TextAreaInput--disabled")}
      name={name}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
    ></textarea>
  );
};

export default TextAreaInput;
