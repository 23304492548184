import { useWindowWidth } from "./useWindowWidth";

export const useResponsiveDimensions = () => {
  const width = useWindowWidth();

  return {
    isLaptop: width > 1200,
    isTablet: width <= 1200 && width > 700,
    isMobile: width <= 700,
  };
};
