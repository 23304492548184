import React, { useState } from "react";

import { apiCall } from "../../../api/Api";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { useTranslation } from "react-i18next";
import ModalContainer from "../ModalContainer/ModalContainer";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import FourDigits from "../FourDigits/FourDigits";
import closeIcon from "../../../assets/svg/close-dark.svg";
import messageIcon from "../../../assets/svg/message-icon.svg";
import getMessageEffectIcon from "../../../assets/svg/get-message-effect.svg";
import ksFlag from "../../../assets/flags/kosova.svg";

import "./VerifyPhoneNumberModal.scss";

interface VerifyPhoneNumberModal {
  onCloseClick: () => void;
}
const VerifyPhoneNumberModal = (props: VerifyPhoneNumberModal) => {
  const { onCloseClick } = props;
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [activeStep, setActiveStep] = useState("sendMessage");
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [fourDigitsValue, setFourDigitsValue] = useState("");
  const [fourDigitsError, setFourDigitsError] = useState("");
  const globalCtx = useGlobalContext();
  const { t } = useTranslation("common");

  const handleNextClick = () => {
    if (phoneNumberValue.length === 0) {
      setPhoneNumberError(t("verifyPhoneNumber.modal.sendMessage.errorOne"));
      return;
    }
    if (/^\d{8}$/.test(phoneNumberValue)) {
      setIsButtonLoading(true);
      apiCall("POST", "b2b/user/verify-phone/", {
        phone_number: `+383${phoneNumberValue}`,
      })
        .then(() => {
          setActiveStep("getMessage");
        })
        .catch((error) => {
          onCloseClick();
          globalCtx.openMessageModal(
            "error",
            error?.response?.data?.error || t("response.somethingWentWrong"),
          );
        })
        .finally(() => {
          setIsButtonLoading(false);
        });
    } else {
      setPhoneNumberError(t("verifyPhoneNumber.modal.sendMessage.errorTwo"));
    }
  };

  const handleVerifyClick = () => {
    if (fourDigitsValue.length < 4) {
      setFourDigitsError(t("verifyPhoneNumber.modal.getMessage.error"));
    } else {
      setIsButtonLoading(true);
      apiCall("POST", "b2b/user/verify-phone-otp/", {
        phone_number: `+383${phoneNumberValue}`,
        otp: fourDigitsValue,
      })
        .then(() => {
          apiCall("GET", "b2b/user/profile/")
            .then((response) => {
              onCloseClick();
              setIsButtonLoading(false);
              localStorage.setItem(
                "profile",
                JSON.stringify(response?.data?.profile),
              );
              globalCtx.openMessageModal(
                "success",
                t("verifyPhoneNumber.modal.getMessage.success"),
              );
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            })
            .catch((error) => {
              onCloseClick();
              setIsButtonLoading(false);
              globalCtx.openMessageModal(
                "warning",
                t("verifyPhoneNumber.modal.getMessage.warning"),
              );
            });
        })
        .catch((error) => {
          onCloseClick();
          setIsButtonLoading(false);
          globalCtx.openMessageModal(
            "error",
            error?.response?.data?.error || t("response.somethingWentWrong"),
          );
        });
    }
  };

  return (
    <ModalContainer>
      <div className="VerifyPhoneNumberModal">
        <div className="VerifyPhoneNumberModal__header">
          <div className="VerifyPhoneNumberModal__header-container">
            <p className="VerifyPhoneNumberModal__title">
              {t("verifyPhoneNumber.modal.title")}
            </p>
          </div>
          <img
            alt="pic"
            className="VerifyPhoneNumberModal__close"
            src={closeIcon}
            onClick={() => {
              onCloseClick();
            }}
          />
        </div>
        <div className="VerifyPhoneNumberModal__body">
          {activeStep === "sendMessage" && (
            <div className="SendMessage">
              <p className="SendMessage__title">
                {t("verifyPhoneNumber.modal.sendMessage.title")}
              </p>
              <div className="SendMessage__icon-container">
                <img
                  alt="pic"
                  className="SendMessage__icon"
                  src={messageIcon}
                />
              </div>
              <div className="SendMessage__inputs">
                <InputField
                  onBlur={() => {}}
                  onChange={() => {}}
                  placeholder="+383"
                  value="+383"
                  leftIcon={ksFlag}
                />
                <InputField
                  onBlur={() => {}}
                  onChange={(e: any) => {
                    setPhoneNumberValue(e.target.value);
                    setPhoneNumberError("");
                  }}
                  placeholder="44123456"
                />
              </div>
              <h5 className="SendMessage__inputs-error">{phoneNumberError}</h5>

              <Button
                isLoading={isButtonLoading}
                label={t("verifyPhoneNumber.modal.sendMessage.button")}
                onClick={() => {
                  handleNextClick();
                }}
              />
            </div>
          )}
          {activeStep === "getMessage" && (
            <div className="GetMessage">
              <p className="GetMessage__title">
                {t("verifyPhoneNumber.modal.getMessage.title")}
              </p>
              <div className="GetMessage__icon-container">
                <img
                  alt="pic"
                  className="GetMessage__icon GetMessage__icon--effect"
                  src={getMessageEffectIcon}
                />
                <img
                  alt="pic"
                  className="GetMessage__icon GetMessage__icon--message"
                  src={messageIcon}
                />
              </div>
              <div className="GetMessage__inputs">
                <FourDigits
                  getValues={(values) => {
                    setFourDigitsValue(values);
                    setFourDigitsError("");
                  }}
                />
              </div>
              <h5 className="SendMessage__inputs-error">{fourDigitsError}</h5>

              <Button
                isLoading={isButtonLoading}
                label={t("verifyPhoneNumber.modal.getMessage.button")}
                onClick={() => {
                  handleVerifyClick();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default VerifyPhoneNumberModal;
