import React from "react";

import { Outlet } from "react-router-dom";

import PublicRoutesLayout from "../layout/PublicRoutesLayout/PublicRoutesLayout";

const PublicRoutesContainer = () => {
  return (
    <PublicRoutesLayout>
      <Outlet />
    </PublicRoutesLayout>
  );
};
export default PublicRoutesContainer;
