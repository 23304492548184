import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useCreateUnitFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  const { t } = useTranslation("common");

  const memoizedT = useCallback(t, [t]);

  return useFormik({
    initialValues: {
      unitName: "",
      phoneNumber: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      unitName: Yup.string().required(
        memoizedT("units.createUnit.unitName.required"),
      ),
      phoneNumber: Yup.string()
        .required(memoizedT("units.createUnit.phoneNumber.required"))
        .matches(
          /^\+\d{11}$/,
          memoizedT("units.createUnit.phoneNumber.matches"), // Translated error message
        ),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
