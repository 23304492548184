import React from "react";

import "./LoadingPage.scss";

const LoadingPage = () => {
  return (
    <div className="LoadingPage">
      <div className="LoadingPage__spinner"></div>
    </div>
  );
};

export default LoadingPage;
