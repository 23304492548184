import React, { useEffect, useState } from "react";

import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import {
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
import { useAuthContext } from "../../context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import { convertFirebaseAuthErrorCodes } from "../../lib/helpers/handleErrors";
import { useTranslation } from "react-i18next";
import peakJobsLogo from "../../assets/svg/peak-jobs-logo.svg";
import successEmblem from "../../assets/svg/success-emblem.svg";
import errorEmblem from "../../assets/svg/error-emblem.svg";
import useGetParameterByName from "../../lib/hooks/useGetParameterByName";
import AuthPageResponse from "./AuthPageResponse/AuthPageResponse";
import CreateNewPasswordForm from "./CreateNewPasswordForm/CreateNewPasswordForm";
import cs from "classnames";

import "./AuthPage.scss";

const AuthPage = () => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isComponentLoading, setIsComponentLoading] = useState(false);
  const [emailVerificationStatus, setEmailVerificationStatus] = useState({
    status: "",
    message: "",
  });
  const [resetPasswordStatus, setResetPasswordStatus] = useState({
    status: "",
    message: "",
  });
  const mode = useGetParameterByName("mode");
  const oobCode = useGetParameterByName("oobCode");
  const isMobile = useResponsiveDimensions().isMobile;
  const navigate = useNavigate();
  const authCtx = useAuthContext();
  const { t } = useTranslation("common");

  useEffect(() => {
    setIsComponentLoading(true);
    if (mode === "verifyEmail") {
      applyActionCode(auth, oobCode)
        .then(() => {
          setEmailVerificationStatus({
            status: "success",
            message: t("authPage.verifyEmail.success"),
          });
        })
        .catch((error) => {
          const errorMessage = convertFirebaseAuthErrorCodes(error.code, t);
          setEmailVerificationStatus({
            status: "failed",
            message: errorMessage,
          });
        })
        .finally(() => {
          setIsComponentLoading(false);
        });
    } else if (mode === "resetPassword") {
      verifyPasswordResetCode(auth, oobCode)
        .then(() => {
          setResetPasswordStatus({ status: "new-password", message: "" });
        })
        .catch((error) => {
          const errorMessage = convertFirebaseAuthErrorCodes(error.code, t);
          setResetPasswordStatus({
            status: "failed",
            message: errorMessage,
          });
        })
        .finally(() => {
          setIsComponentLoading(false);
        });
    } else {
      navigate("/*");
    }
  }, []);

  const handleConfirmPasswordReset = (confirmPassword: string) => {
    setIsButtonLoading(true);
    confirmPasswordReset(auth, oobCode, confirmPassword)
      .then((res) => {
        setResetPasswordStatus({
          status: "success",
          message: t("authPage.resetPassword.success"),
        });
      })
      .catch((error) => {
        const errorMessage = convertFirebaseAuthErrorCodes(error.code, t);
        setResetPasswordStatus({
          status: "failed",
          message: errorMessage,
        });
      })
      .finally(() => {
        setIsButtonLoading(false);
      });
  };

  return (
    <div className="AuthPage">
      <div
        className={cs("AuthPage__left", isMobile && "AuthPage__left-mobile")}
      >
        <div className="AuthPage__left-container">
          <div className="AuthPage__logo">
            <img alt="pic" src={peakJobsLogo} className="AuthPage__logo-icon" />
          </div>
          <div className="AuthPage__form">
            {isComponentLoading ? (
              <div className="AuthPage__spinner-container">
                <div className="AuthPage__spinner" />
              </div>
            ) : (
              <>
                {mode === "verifyEmail" && (
                  <>
                    {emailVerificationStatus.status === "success" && (
                      <AuthPageResponse
                        buttonLabel={t("authPage.responseButton")}
                        icon={successEmblem}
                        title={emailVerificationStatus.message}
                        onButtonClick={() => {
                          authCtx.signOutFirebaseUser();
                          navigate("/sign-in");
                        }}
                      />
                    )}
                    {emailVerificationStatus.status === "failed" && (
                      <AuthPageResponse
                        buttonLabel={t("authPage.responseButton")}
                        icon={errorEmblem}
                        title={emailVerificationStatus.message}
                        onButtonClick={() => {
                          authCtx.signOutFirebaseUser();
                          navigate("/sign-in");
                        }}
                      />
                    )}
                  </>
                )}
                {mode === "resetPassword" && (
                  <>
                    {resetPasswordStatus.status === "new-password" && (
                      <CreateNewPasswordForm
                        isButtonLoading={isButtonLoading}
                        onFormSubmit={(confirmPassword) => {
                          handleConfirmPasswordReset(confirmPassword);
                        }}
                      />
                    )}
                    {resetPasswordStatus.status === "success" && (
                      <AuthPageResponse
                        buttonLabel={t("authPage.responseButton")}
                        icon={successEmblem}
                        title={resetPasswordStatus.message}
                        onButtonClick={() => {
                          authCtx.signOutFirebaseUser();
                          navigate("/sign-in");
                        }}
                      />
                    )}
                    {resetPasswordStatus.status === "failed" && (
                      <AuthPageResponse
                        buttonLabel={t("authPage.responseButton")}
                        icon={errorEmblem}
                        title={resetPasswordStatus.message}
                        onButtonClick={() => {
                          authCtx.signOutFirebaseUser();
                          navigate("/sign-in");
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {!isMobile && (
        <div className="AuthPage__right">
          <div className="AuthPage__right-container">
            <div className="AuthPage__circle">
              <p className="AuthPage__circle-text">
                PEAK <br /> YOUR SHIFT
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthPage;
