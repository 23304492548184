import React from "react";

import { useTranslation } from "react-i18next";
import verified from "../../../../assets/svg/verified-icon-green.svg";
import calendarIcon from "../../../../assets/svg/calendar-icon.svg";
import startIcon from "../../../../assets/svg/start-icon.svg";
import endIcon from "../../../../assets/svg/end-shift-icon.svg";
import completedIcon from "../../../../assets/svg/completed.svg";
import inProgressIcon from "../../../../assets/svg/in-progresss-shift.svg";

import "./AttendanceCard.scss";
import cs from "classnames";

const AttendanceCard = (props: any) => {
  const { person, jobName, jobId, setSelectedApplicant, setOpenSidebar } =
    props;
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const { t } = useTranslation("common");

  return (
    <section
      className="AttendanceCard"
      onClick={() => {
        localStorage.setItem("person", JSON.stringify(person));
        localStorage.setItem("jobName", jobName);
        localStorage.setItem("jobId", jobId);
        setSelectedApplicant(person);
        setOpenSidebar(true);
      }}
    >
      <div
        className={cs(
          "AttendanceCard__header",
          person?.status && `AttendanceCard__header--${person?.status}`,
        )}
      >
        <div className="ApplicationCard__person-image-wrapper">
          <img
            src={person?.picture_url}
            alt="user"
            className="ApplicationCard__person-image"
          />
        </div>
        <div>
          <div className="ApplicationCard__name-container">
            <h2 className="ApplicationCard__name">{`${person?.first_name} ${person?.last_name}`}</h2>
            {person.verified && (
              <img
                src={verified}
                alt="verified icon"
                className="ApplicationCard__verified-icon"
              />
            )}
          </div>
          <div className="ApplicationCard__role">{jobName}</div>
          <div className="ApplicationCard__review-wrapper">
            &#9733;{`${person?.average_rating}(${person?.total_reviews})`}
          </div>
        </div>
      </div>
      <div className="AttendanceCard__body">
        {person.shifts && (
          <div className="AttendanceCard__shift1">
            <img
              src={calendarIcon}
              alt="calendar icon"
              className="AttendanceCard__calendar-icon"
            />
            <div>
              {`${daysOfTheWeek[new Date(person.shifts[0].start_time).getDay()]} ${new Date(person.shifts[0].start_time).getDate()}. ${new Date(person.shifts[0].start_time).getMonth() + 1}`}
              <span>
                {`${new Date(person.shifts[0].start_time).toLocaleString("en-GB", { hour: "numeric", minute: "numeric" })} TO ${new Date(person.shifts[0].end_time).toLocaleString("en-GB", { hour: "numeric", minute: "numeric" })} `}
              </span>
            </div>
          </div>
        )}
        <div className="AttendanceCard__card-total flex">
          {person.status === "completed" && (
            <img
              src={completedIcon}
              alt="completed icon"
              className="AttendanceCard__completed-icon"
            />
          )}
          {person.status === "in_progress" && (
            <img
              src={startIcon}
              alt="in progress icon"
              className="AttendanceCard__completed-icon"
            />
          )}
          {person.status === "accepted" && (
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="AttendanceCard__completed-icon"
            >
              <ellipse
                cx="7.42748"
                cy="7.46715"
                rx="5.51537"
                ry="6.1024"
                fill="#84b1f0"
              />
              <ellipse
                opacity="0.2"
                cx="7.42652"
                cy="7.47047"
                rx="6.71168"
                ry="7.42604"
                fill="#84b1f0"
              />
              <rect
                x="4.71484"
                y="5.04443"
                width="5.5"
                height="5.5"
                rx="1"
                fill="#131A28"
              />
            </svg>
          )}
          {person.status === "did_not_attend" && (
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="AttendanceCard__completed-icon"
            >
              <ellipse
                cx="7.42748"
                cy="7.46715"
                rx="5.51537"
                ry="6.1024"
                fill="#ffeec3"
              />
              <ellipse
                opacity="0.2"
                cx="7.42652"
                cy="7.47047"
                rx="6.71168"
                ry="7.42604"
                fill="#ffeec3"
              />
              <rect
                x="4.71484"
                y="5.04443"
                width="5.5"
                height="5.5"
                rx="1"
                fill="#131A28"
              />
            </svg>
          )}
          {person.status === "cancelled" && (
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="AttendanceCard__completed-icon"
            >
              <ellipse
                cx="7.42748"
                cy="7.46715"
                rx="5.51537"
                ry="6.1024"
                fill="#ffc3c3"
              />
              <ellipse
                opacity="0.2"
                cx="7.42652"
                cy="7.47047"
                rx="6.71168"
                ry="7.42604"
                fill="#ffc3c3"
              />
              <rect
                x="4.71484"
                y="5.04443"
                width="5.5"
                height="5.5"
                rx="1"
                fill="#131A28"
              />
            </svg>
          )}

          <span
            className={cs(
              "AttendanceCard__card-total-text",
              person.status &&
                `AttendanceCard__card-total-text--${person.status}`,
            )}
          >
            {person.status === "in_progress" &&
              t("jobs.attendance.attendanceCard.inProgress")}
            {person.status === "accepted" &&
              t("jobs.attendance.attendanceCard.accepted")}{" "}
            {person.status === "cancelled" &&
              t("jobs.attendance.attendanceCard.cancelled")}{" "}
            {person.status === "did_not_attend" &&
              t("jobs.attendance.attendanceCard.didNotAttend")}{" "}
            {person.status === "completed" &&
              t("jobs.attendance.attendanceCard.completed")}{" "}
          </span>
        </div>

        <div className="AttendanceCard__shift-cards">
          {person.shifts &&
            person.shifts.map((shift: any, index: number) => (
              <div key={index} className="AttendanceCard__shift-card">
                <div className="flex">
                  <img
                    src={calendarIcon}
                    alt="calendar icon"
                    className="AttendanceCard__calendar-icon2"
                  />
                  <div className="AttendanceCard__shift-card-date">
                    {`${daysOfTheWeek[new Date(shift.start_time).getDay()]} ${new Date(shift.start_time).getDate()}. ${new Date(shift.start_time).getMonth() + 1}   `}
                    <span>
                      {`${new Date(shift.start_time).toLocaleString("en-GB", { hour: "numeric", minute: "numeric" })} TO ${new Date(shift.end_time).toLocaleString("en-GB", { hour: "numeric", minute: "numeric" })} `}
                    </span>
                  </div>
                </div>
                <div className="AttendanceCard__shift-card-body">
                  <div className="flex" style={{ width: "50px" }}>
                    <img
                      src={startIcon}
                      alt="start icon"
                      className="AttendanceCard__start-icon"
                    />
                    {shift?.attendance?.clock_in_time !== null ? (
                      <span>
                        {new Date(
                          shift?.attendance.clock_in_time,
                        ).toLocaleString("en-GB", {
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex" style={{ width: "50px" }}>
                    <img
                      src={endIcon}
                      alt="end icon"
                      className="AttendanceCard__end-icon"
                    />
                    {shift?.attendance?.clock_out_time !== null ? (
                      <span>
                        {new Date(
                          shift?.attendance.clock_out_time,
                        ).toLocaleString("en-GB", {
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </span>
                    ) : (
                      <span style={{ width: "35px" }} />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default AttendanceCard;
