import React from "react";

import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";

import peakJobsLogo from "../../assets/svg/peak-jobs-logo.svg";
import dashboardIcon from "../../assets/svg/dashboard.svg";
import dashboardIconColored from "../../assets/svg/dashboard-colored.svg";
import briefcaseIcon from "../../assets/svg/briefcase.svg";
import briefcaseIconColored from "../../assets/svg/briefcase-colored.svg";
import unitsIcon from "../../assets/svg/units.svg";
import unitsIconColored from "../../assets/svg/units-colored.svg";
import billingIcon from "../../assets/svg/billing.svg";
import billingIconColored from "../../assets/svg/billing-colored.svg";
import closeIcon from "../../assets/svg/close-dark.svg";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import cs from "classnames";

import "./Sidebar.scss";

interface SidebarProps {
  closeSidebar: () => void;
}

const Sidebar = (props: SidebarProps) => {
  const { closeSidebar } = props;
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const isTablet = useResponsiveDimensions().isTablet;
  const { t } = useTranslation("common");

  const sidebarItems = [
    {
      icon: dashboardIcon,
      iconColored: dashboardIconColored,
      label: t("sidebar.items.dashboard"),
      path: "/",
    },
    {
      icon: unitsIcon,
      iconColored: unitsIconColored,
      label: t("sidebar.items.units"),
      hasChildren: true,
      path: "/units",
    },
    {
      icon: briefcaseIcon,
      iconColored: briefcaseIconColored,
      label: t("sidebar.items.jobs"),
      hasChildren: true,
      path: "/jobs/upcoming-not-assigned",
    },
    {
      icon: billingIcon,
      iconColored: billingIconColored,
      label: t("sidebar.items.subscription"),
      hasChildren: true,
      path: "/subscription",
    },
  ];

  return (
    <div className="Sidebar">
      <div className="Sidebar__head">
        <img alt="pic" className="Sidebar__logo" src={peakJobsLogo} />
        <img
          alt="pic"
          className="Sidebar__close"
          src={closeIcon}
          onClick={() => {
            closeSidebar();
          }}
        />
      </div>
      <div className="Sidebar__body">
        <div className="Sidebar__nav">
          {sidebarItems.map((item: any, key: number) => (
            <div
              className="Sidebar__item"
              key={key}
              onClick={() => {
                navigate(item.path);
                closeSidebar();
              }}
            >
              <div className="Sidebar__item-container">
                <img
                  alt="pic"
                  className="Sidebar__item-icon"
                  src={
                    (item.hasChildren &&
                      pathname.startsWith(`/${item.path.split("/")[1]}`)) ||
                    pathname === item.path
                      ? item.iconColored
                      : item.icon
                  }
                />
                {!isTablet && (
                  <h5
                    className={cs(
                      "Sidebar__item-label",
                      ((item.hasChildren &&
                        pathname.startsWith(`/${item.path.split("/")[1]}`)) ||
                        pathname === item.path) &&
                        "Sidebar__item-label--active",
                    )}
                  >
                    {item.label}
                  </h5>
                )}
              </div>
              <div
                className={cs(
                  "Sidebar__item-border",
                  ((item.hasChildren &&
                    pathname.startsWith(`/${item.path.split("/")[1]}`)) ||
                    pathname === item.path) &&
                    "Sidebar__item-border--active",
                )}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
