import React, { useState, useEffect } from "react";
import { stripeApiKey } from "../../../stripeConfig";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { apiCall } from "../../../api/Api";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import closeIcon from "../../../assets/svg/close-dark.svg";

import "./AddPaymentCard.scss";

interface AddPaymentCardTypes {
  onCloseClick: () => void;
}

const AddPaymentCard = (props: AddPaymentCardTypes) => {
  const { onCloseClick } = props;
  const [clientSecret, setClientSecret] = useState<null | string>();
  const { t } = useTranslation("common");
  const planId = localStorage.getItem("selectedPlan");

  useEffect(() => {
    apiCall("POST", "subscription/payment-method-setup-embedded/")
      .then((data) => {
        setClientSecret(data.data.client_secret);
      })
      .catch(() => {
        setClientSecret(null);
      });
  }, []);

  const options = { clientSecret };

  return (
    <div className="AddPaymentCard">
      <div className="AddPaymentCard__header">
        <p className="AddPaymentCard__title">{t("addPaymentCard")}</p>
        <img
          alt="close"
          className="AddPaymentCard__close"
          src={closeIcon}
          onClick={() => {
            onCloseClick();
            if(planId){
              localStorage.removeItem("selectedPlan");
            }
          }}
        />
      </div>
      <div className="AddPaymentCard__body">
        {clientSecret === undefined ? (
          <div className="AddPaymentCard__loading">
            <LoadingComponent />
          </div>
        ) : clientSecret === null ? (
          <div className="AddPaymentCard__noData">{t("noData")}</div>
        ) : (
          <EmbeddedCheckoutProvider stripe={stripeApiKey} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </div>
  );
};

export default AddPaymentCard;
