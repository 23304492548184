import React from "react";

import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { useTranslation } from "react-i18next";
import ModalContainer from "../ModalContainer/ModalContainer";
import closeIcon from "../../../assets/svg/close-white.svg";
import diamondIcon from "../../../assets/svg/diamond.svg";
import briefCaseIcon from "../../../assets/svg/briefcase-colored.svg";
import plusIcon from "../../../assets/svg/plus-icon.svg";
import calendarIcon from "../../../assets/svg/calendar-icon-gradient.svg";

import "./SubscribeForJobsModal.scss";

interface SubscribeForJobsModalProps {
  onUpgradeClick: () => void;
  onCloseClick: () => void;
}

const SubscribeForJobsModal = (props: SubscribeForJobsModalProps) => {
  const { onUpgradeClick, onCloseClick } = props;
  const isMobile = useResponsiveDimensions().isMobile;
  const { t } = useTranslation("common");

  return (
    <ModalContainer>
      <div className="SubscribeForJobsModal">
        <img
          alt="pic"
          className="SubscribeForJobsModal__close"
          src={closeIcon}
          onClick={() => {
            onCloseClick();
          }}
        />
        <div className="SubscribeForJobsModal__body">
          <div className="SubscribeForJobsModal__left">
            <p className="SubscribeForJobsModal__title">
              {t("subscribeForJobsModal.title.partOne")} <br />{" "}
              {t("subscribeForJobsModal.title.partTwo")} <br />{" "}
              {t("subscribeForJobsModal.title.partThree")}
            </p>

            <p className="SubscribeForJobsModal__description">
              {t("subscribeForJobsModal.description")}
            </p>
            {!isMobile && (
              <div className="SubscribeForJobsModal__button-container">
                <div
                  className="SubscribeForJobsModal__button"
                  onClick={() => {
                    onUpgradeClick();
                  }}
                >
                  <div className="SubscribeForJobsModal__button-round">
                    <img
                      alt="pic"
                      src={diamondIcon}
                      className="SubscribeForJobsModal__button-round--plus"
                    />
                  </div>
                  <p className="SubscribeForJobsModal__button-text">
                    {t("subscribeForJobsModal.upgrade")}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="SubscribeForJobsModal__right">
            <div className="SubscribeForJobsModalCart">
              <div className="SubscribeForJobsModalCart__container">
                <img
                  alt="pic"
                  className="SubscribeForJobsModalCart__icon"
                  src={briefCaseIcon}
                />
                <p className="SubscribeForJobsModalCart__title">
                  {t("subscribeForJobsModal.cartOne")}
                </p>
                <div className="SubscribeForJobsModalCart__number">+15</div>
              </div>
              <div className="SubscribeForJobsModalCart__shadow"></div>
              <div className="SubscribeForJobsModalCart__shadowTwo"></div>
            </div>{" "}
            <div className="SubscribeForJobsModalCart">
              <div className="SubscribeForJobsModalCart__container">
                <img
                  alt="pic"
                  className="SubscribeForJobsModalCart__icon"
                  src={plusIcon}
                />
                <p className="SubscribeForJobsModalCart__title">
                  {t("subscribeForJobsModal.cartTwo")}
                </p>
                <div className="SubscribeForJobsModalCart__number">+4</div>
              </div>
              <div className="SubscribeForJobsModalCart__shadow"></div>
              <div className="SubscribeForJobsModalCart__shadowTwo"></div>
            </div>{" "}
            <div className="SubscribeForJobsModalCart">
              <div className="SubscribeForJobsModalCart__container">
                <img
                  alt="pic"
                  className="SubscribeForJobsModalCart__icon"
                  src={calendarIcon}
                />
                <p className="SubscribeForJobsModalCart__title">
                  {t("subscribeForJobsModal.cartThree")}
                </p>
                <div className="SubscribeForJobsModalCart__number">+10</div>
              </div>
              <div className="SubscribeForJobsModalCart__shadow"></div>
              <div className="SubscribeForJobsModalCart__shadowTwo"></div>
            </div>{" "}
            {isMobile && (
              <div className="SubscribeForJobsModal__button-container">
                <div
                  className="SubscribeForJobsModal__button"
                  onClick={() => {
                    onUpgradeClick();
                  }}
                >
                  <div className="SubscribeForJobsModal__button-round">
                    <img
                      alt="pic"
                      src={diamondIcon}
                      className="SubscribeForJobsModal__button-round--plus"
                    />
                  </div>
                  <p className="SubscribeForJobsModal__button-text">
                    {t("subscribeForJobsModal.upgrade")}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default SubscribeForJobsModal;
