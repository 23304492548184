import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../../../api/Api";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import Invoice from "../../../components/shared/Invoice/Invoice";
import chevronDown from "../../../assets/svg/chevron-down-gray.svg";

import "./Invoices.scss";

const Invoices = () => {
  const [invoices, setInvoices] = useState<any | null>();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const globalCtx = useGlobalContext();

  useEffect(() => {
    // GET INVOICES
    apiCall("GET", "subscription/invoices/")
      .then((response) => {
        setInvoices(response.data.data);
      })
      .catch((error) => {
        setInvoices(null);
      });
  }, []);

  return (
    <div className="InvoicesContainer">
      <div className="InvoicesContainer__head">
        <p className="InvoicesContainer__head-title">
          {t("subscription.title")}
        </p>
      </div>
      <div className="InvoicesContainer__body">
        <div className="Invoices">
          <div className="Invoices__header">
            <p className="Invoices__title">
              <img
                alt="pic"
                className="Invoices__title-back"
                src={chevronDown}
                onClick={() => {
                  navigate("/subscription");
                }}
              />
              {t("subscription.invoices.title")}
            </p>
          </div>

          {invoices === undefined ? (
            <div className="Invoices__loader">
              <LoadingComponent />
            </div>
          ) : invoices === null ? (
            <div className="Invoices">{t("noData")}</div>
          ) : (
            <div className="Invoices__body">
              {invoices?.map((item: any, index: number) => (
                <Invoice key={index} invoice={item} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoices;
