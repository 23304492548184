import React, { useRef, useState } from "react";

import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { useTranslation } from "react-i18next";
import userAvatar from "../../../assets/images/user-avatar.png";
import chevronDown from "../../../assets/svg/chevron-down.svg";
import chevronDownWhite from "../../../assets/svg/chevron-down-white.svg";

import cs from "classnames";

import "./UserProfileDropdown.scss";

const UserProfileDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const authCtx = useAuthContext();
  const { t } = useTranslation("common");
  const location = useLocation();

  useOutsideClickDetection(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  return (
    <div className="UserProfileDropdown" ref={dropdownRef}>
      <div
        className={cs(
          "UserProfileDropdown__head",
          location.pathname.startsWith("/profile") &&
            "UserProfileDropdown__head-active",
        )}
        onClick={() => {
          setIsDropdownOpen(true);
        }}
      >
        <img
          alt="pic"
          className="UserProfileDropdown__image"
          src={authCtx.localUserProfile.picture_url || userAvatar}
        />
        <h5
          className={cs(
            "UserProfileDropdown__name",
            location.pathname.startsWith("/profile") &&
              "UserProfileDropdown__name-active",
          )}
        >
          {authCtx.localUserProfile.display_name || "Name Surname"}
        </h5>
        <img
          alt="pic"
          className="UserProfileDropdown__icon"
          src={
            location.pathname.startsWith("/profile")
              ? chevronDownWhite
              : chevronDown
          }
        />
      </div>
      {isDropdownOpen && (
        <div className="UserProfileDropdown__body">
          <h5
            className="UserProfileDropdown__body-item"
            onClick={() => {
              navigate("/profile/profile-settings");
              setIsDropdownOpen(false);
            }}
          >
            {t("header.userProfileDropdown.profile")}
          </h5>
          <h5
            className="UserProfileDropdown__body-item"
            onClick={() => {
              authCtx.signOutFirebaseUser();
            }}
          >
            {t("header.userProfileDropdown.signOut")}
          </h5>
        </div>
      )}
    </div>
  );
};

export default UserProfileDropdown;
