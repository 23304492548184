import React, { useEffect, useState } from "react";
import { apiCall } from "../../../api/Api";
import { getAssignedJobs } from "../../../api/Jobs";
import JobCard from "../JobCard/JobCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../../assets/svg/plus-icon-white.svg";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import reloadIcon2 from "../../../assets/svg/Refresh_icon.svg";

import "./AssignedJobs.scss";

interface FilteredJobs {
  [key: string]: [];
}

const AssignedJobs = () => {
  const availableJobsCount = localStorage.getItem("availableJobsCount");
  const assignedJobsCount2 = localStorage.getItem("assignedJobsCount");
  const [assignedJobs, setAssignedJobs] = useState<any>([]);
  const [nextPage, setNextPage] = useState<any>("");
  const [timeDates, setTimeDates] = useState<any>();
  const [isCalled, setIsCalled] = useState(false);
  const [loading] = useState(false);
  const [error] = useState("");
  const [sortedKeys, setSortedKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const [mainJobStatus, setMainJobStatus] = useState<string>("Upcoming");
  const [jobStatus, setJobStatus] = useState("Assigned");
  const [assignedJobsCount, setAssignedJobsCount] = useState(assignedJobsCount2);
  const [refreshCalled, setRefreshCalled] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const filterDates = (datesArray: any) => {
    //groups jobs into same dates
    const filteredJobs: FilteredJobs = datesArray.reduce(
      (group: any, item: any) => {
        const date = new Date(item?.job_start_time);
        const isoDateKey = date.toISOString().split("T")[0];
        group[isoDateKey] = group[isoDateKey] ?? [];
        group[isoDateKey].push(item);

        return group;
      },
      {},
    );

    setTimeDates(filteredJobs);

    const sortedKeys2: any = Object.keys(filteredJobs).sort();
    setSortedKeys(sortedKeys2);
  };

  const callJobs = () => {
    setIsLoading(true);
    getAssignedJobs()
      .then((res) => {
        setAssignedJobs(res.data.results);
        filterDates(res.data.results);
        setIsCalled(true);
        setNextPage(res.data?.next);
        setAssignedJobsCount(res.data.count);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (!isCalled) {
       callJobs();
    }
  }, []);

  const loadMoreJobs = () => {
    if (nextPage !== null && isCalled) {
    setIsLoading(true);
    let resultUrl = nextPage.split("?")[1];
      apiCall("GET", `b2b/jobs/?${resultUrl}`)
        .then((res) => {
          setAssignedJobs([...assignedJobs, ...res.data.results]);
          filterDates([...assignedJobs, ...res.data.results]);
          setIsCalled(true);
          setIsLoading(false);
          setNextPage(res.data?.next);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: nextPage,
    onLoadMore: loadMoreJobs,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <section className="AssignedJobs">
      <div className="AssignedJobs__header-container">
        <div className="AssignedJobs__title-container">
        <div className="AssignedJobs__main-title">{t("jobs.title")}</div>
        {isLoading ? 
          (<div className="Jobs__spinner" />) 
          : 
       (<img src={reloadIcon2} alt="reload icon" className={cs("AssignedJobs__reload-icon", refreshCalled && "AssignedJobs__reload-icon--disable")} onClick={() => {
        if(!refreshCalled) {
          callJobs();
          setRefreshCalled(true);
          setTimeout(() => {
            setRefreshCalled(false);
          }, 10000);
        }
       }} />)}
        </div>
        <div className="AssignedJobs__button-wrapper">
          <div
            className="AssignedJobs__button"
            onClick={() => {
              navigate("/jobs/new-job");
            }}
          >
            <div className="AssignedJobs__button-round">
              <img
                alt="pic"
                src={plusIcon}
                className="AssignedJobs__button-round--plus"
              />
            </div>
            <p className="AssignedJobs__button-text">
              {t("jobs.addJobButton")}
            </p>
          </div>
        </div>
      </div>
      <section className="AssignedJobs__container">
        <div
          onClick={() => {
            setMainJobStatus("Upcoming");
            navigate("/jobs/upcoming-not-assigned");
          }}
          className={cs(
            "AssignedJobs__container-item",
            mainJobStatus === "Upcoming" &&
              "AssignedJobs__container-item--active",
          )}
        >
          {t("jobs.upcomingTabLabel")}
        </div>
        <div
          onClick={() => {
            navigate("/jobs/past-unreviewed");
            setMainJobStatus("Past");
          }}
          className={cs(
            "AssignedJobs__container-item",
            mainJobStatus === "Past" && "AssignedJobs__container-item--active",
          )}
        >
          {t("jobs.pastTabLabel")}
        </div>
      </section>
      <section className="AssignedJobs__buttons-container">
        <div
          className={cs(
            "AssignedJobs__not-assigned-btn",
            jobStatus === "Not assigned" &&
              "AssignedJobs__not-assigned-btn--active",
          )}
          onClick={() => {
            setJobStatus("Not assigned");
            navigate("/jobs/upcoming-not-assigned");
          }}
        >
          {`${t("jobs.notAssigned.tabLabel")} (${availableJobsCount})`}
        </div>
        <div
          className={cs(
            "AssignedJobs__assigned-btn",
            jobStatus === "Assigned" && "AssignedJobs__assigned-btn--active",
          )}
          onClick={() => {
            setJobStatus("Assigned");
            navigate("/jobs/upcoming-assigned");
          }}
        >
          {` ${t("jobs.assigned.tabLabel")} (${assignedJobsCount})`}
        </div>
      </section>
      {assignedJobs?.length === 0 && isCalled && (
        <div className="AssignedJobs__no-content">
          {t("jobs.assigned.noContent")}
        </div>
      )}

      {timeDates &&
        sortedKeys?.map((key, index) => (
          <div className="AssignedJobs__timeline" key={index}>
            <div className="AssignedJobs__timeline-container">
              <div className="AssignedJobs__timeline-content">
                <div className="AssignedJobs__timeline-dot">
                  <div className="AssignedJobs__timeline-dot-weekday">
                    {daysOfTheWeek[new Date(key).getDay()]}
                  </div>
                  <div className="AssignedJobs__timeline-dot-day">
                    {new Date(key).getDate()}
                  </div>
                </div>
                {timeDates &&
                  timeDates[key]?.map((job: any, index: number) => (
                    <div key={index} className="AssignedJobs__job-wrapper">
                      <JobCard
                        job={job}
                        jobStatus={"Upcoming"}
                        subStatus={jobStatus}
                        shiftTotal={true}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      {isLoading && assignedJobs.length === 0 &&  (
        <div style={{ margin: "150px auto" }}>
          <LoadingComponent />
        </div>
      )}
      <div>
        {nextPage && nextPage !== null && (
          <div ref={sentryRef}>
            <LoadingComponent />
          </div>
        )}
      </div>
    </section>
  );
};

export default AssignedJobs;
