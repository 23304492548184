import React from "react";

import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { useTranslation } from "react-i18next";
import verifyPhoneAvatar from "../../../assets/svg/verify-phone-avatar.svg";

import "./VerifyPhoneNumberButton.scss";

interface VerifyPhoneNumberProps {
  onClick: () => void;
}

const VerifyPhoneNumberButton = (props: VerifyPhoneNumberProps) => {
  const { onClick } = props;
  const isMobile = useResponsiveDimensions().isMobile;
  const { t } = useTranslation("common");

  return (
    <div
      className="VerifyPhoneNumberButton"
      onClick={() => {
        onClick();
      }}
    >
      <div className="VerifyPhoneNumberButton__container">
        <div className="VerifyPhoneNumberButton__circle">
          <img
            alt="pic"
            className="VerifyPhoneNumberButton__avatar"
            src={verifyPhoneAvatar}
          />
        </div>
        <div className="VerifyPhoneNumberButton__text">
          <p className="VerifyPhoneNumberButton__verify">
            {t("verifyPhoneNumber.headerButton.textOne")}
          </p>
          {!isMobile && (
            <h5 className="VerifyPhoneNumberButton__phoneNumber">
              {t("verifyPhoneNumber.headerButton.textTwo")}
            </h5>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyPhoneNumberButton;
