import React from "react";
import cs from "classnames";
import closeIcon from "../../../assets/svg/close-dark.svg";

import "./RightSidebar.scss";

interface RightSidebarProps {
  children: React.ReactNode;
  onClose: () => void;
  sidebarRef?: any;
}

const RightSidebar = (props: RightSidebarProps) => {
  const { children, sidebarRef } = props;
  return (
    <div className={cs("RightSidebar", "RightSidebar--opened")}>
      <div className="RightSidebar__container" ref={sidebarRef}>
        <div className="RightSidebar__header">
          <img
            src={closeIcon}
            alt="close icon"
            onClick={() => {
              props.onClose();
            }}
            className="RightSidebar__close"
          />
        </div>
        <div className="RightSidebar__body">{children}</div>
      </div>
    </div>
  );
};

export default RightSidebar;
