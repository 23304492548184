import React, { useEffect, useState, useRef } from "react";
import { getApplicationsForJob } from "../../../api/Jobs";
import { apiCall } from "../../../api/Api";
import ApplicationCard from "./ApplicationCard/ApplicationCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import RightSidebar from "../../../components/shared/RightSidebar/RightSidebar";
import ApplicantSidebar from "./ApplicantSidebar/ApplicantSidebar";
import chevronLeft from "../../../assets/svg/chevron-down-gray.svg";
import cs from "classnames";
import { useNavigate } from "react-router-dom";
import reloadIcon from "../../../assets/svg/Refresh_icon.svg";

import "./Applications.scss";
import { useTranslation } from "react-i18next";

const Applications = () => {
  let jobStorage = JSON.parse(localStorage.getItem("selectedJob")!!);
  const availableJobsCount2 = localStorage.getItem("availableJobsCount");
  const assignedJobsCount2 = localStorage.getItem("assignedJobsCount");
  let jobStatusStorage = localStorage.getItem("jobStatus");
  let selectedLanguage = localStorage.getItem("lang");
  const unreviewedJobCount = localStorage.getItem("unreviewedJobCount");
  const completedJobsCount = localStorage.getItem("completedJobsCount");
  const [job, setJob] = useState(jobStorage);
  const [allApplications, setAllApplications] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error] = useState("");
  const [nextPage, setNextPage] = useState<any>("");
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openRightSidebar, setOpenSidebar] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState<any>({});
  const [availableJobsCount, setAvailableJobsCount] = useState(availableJobsCount2);
  const [assignedJobsCount, setAssignedJobsCount] = useState(assignedJobsCount2);
  const [refreshCalled, setRefreshCalled] = useState(false);
  const rightSidebarRef = useRef(null);
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const hideRightSidebar = () => {
    setOpenSidebar(false);
    setSelectedApplicant({});
  };

  useEffect(() => {
    apiCall("GET", `b2b/jobs/?status=assigned&limit=0`)
      .then((res) => {
        setAssignedJobsCount(res.data.count);
      })
      .catch((err) => {});
      apiCall("GET", `b2b/jobs/?status=available&limit=0`)
      .then((res) => {
        setAvailableJobsCount(res.data.count);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (!isCalled) {
      setIsLoading(true);
      getApplicationsForJob(job.id)
        .then((res) => {
          setAllApplications(res.data.results);
          setNextPage(res.data?.next);
          setIsCalled(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, []);

  const refresh = () => {
    getApplicationsForJob(job.id)
      .then((res) => {
        setAllApplications(res.data.results);
        setNextPage(res.data?.next);
        setIsCalled(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const loadMore = () => {
    if (nextPage !== null) {
    setIsLoading(true);
    let resultUrl = nextPage.split("?")[1];
      apiCall("GET", `b2b/applications/${job.id}/?${resultUrl}`)
        .then((res) => {
          setAllApplications([...allApplications, ...res.data.results]);
          setIsCalled(true);
          setNextPage(res.data?.next);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: nextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <section className="Applications">
      <div className="Applications__header">
        <div className="Applications__title-container">
        <h1 className="Applications__default-title">{t("jobs.title")}</h1>
        {isLoading ? 
          (<div className="Jobs__spinner" />) 
          : 
        (<img src={reloadIcon} alt="reload icon" className={cs("Applications__reload-icon", refreshCalled && "Applications__reload-icon--disable")} onClick={() => {
          if(!refreshCalled) {
            refresh();
            setRefreshCalled(true);
            setTimeout(() => {
              setRefreshCalled(false);
            }, 10000);
          }  
        }} />)}
        </div>
        <section className="Applications__container">
          <div
            onClick={() => navigate("/jobs/upcoming-not-assigned")}
            className={cs(
              "Applications__container-item",
              jobStatusStorage === "Upcoming" &&
                "Applications__container-item--active",
            )}
          >
            {t("jobs.upcomingTabLabel")}
          </div>
          <div
            onClick={() => {
              navigate("/jobs/past-unreviewed");
            }}
            className={cs(
              "Applications__container-item",
              jobStatusStorage === "Past" &&
                "Applications__container-item--active",
            )}
          >
            {t("jobs.pastTabLabel")}
          </div>
        </section>
        {jobStatusStorage === "Upcoming" && (
          <section className="Applications__buttons-container">
            <div
              className={cs(
                "Applications__not-assigned-btn",
                job.status === "available" &&
                  "Applications__not-assigned-btn--active",
              )}
              onClick={() => {
                navigate("/jobs/upcoming-not-assigned");
              }}
            >
              {`${t("jobs.notAssigned.tabLabel")} (${availableJobsCount})`}
            </div>
            <div
              className={cs(
                "Applications__assigned-btn",
                (job.status === "assigned" ||
                  job.status === "in_progress" ||
                  job.status === "unattended") &&
                  "Applications__assigned-btn--active",
              )}
              onClick={() => {
                navigate("/jobs/upcoming-assigned");
              }}
            >
              {`${t("jobs.assigned.tabLabel")} (${assignedJobsCount})`}
            </div>
          </section>
        )}
        {jobStatusStorage === "Past" && (
          <section className="Applications__buttons-container">
            <div
              className={cs(
                "Applications__status-btn",
                job.status === "unreviewed" &&
                  "Applications__status-btn--active",
              )}
              onClick={() => {
                navigate("/jobs/past-unreviewed");
              }}
            >
              {`${t("jobs.review.tabLabel")}  (${unreviewedJobCount})`}
            </div>
            <div
              className={cs(
                "Applications__status-btn",
                job.status === "completed" &&
                  "Applications__status-btn--active",
              )}
              onClick={() => {
                navigate("/jobs/past-reviewed");
              }}
            >
              {` ${t("jobs.completed.tabLabel")} (${completedJobsCount})`}
            </div>
          </section>
        )}
        <div className="Applications__header-wrapper">
          <img
            alt="chevron left icon"
            src={chevronLeft}
            className="Applications__back-icon"
            onClick={() => navigate("/jobs/upcoming-not-assigned")}
          />
          <h2 className="Applications__title">{selectedLanguage === "SQ" ? `${job.job_title.title?.sq}` : `${job.job_title.title?.en}`}</h2>
        </div>
      </div>
      {!isLoading && allApplications?.length === 0 && (
        <div className="Applications__no-content">
          {t("jobs.applications.noContent")}
        </div>
      )}
      <div className="Applications__cards-wrapper">
        {allApplications &&
          allApplications.map((person: any, index: number) => (
            <div key={index} className="Applications__card">
              <ApplicationCard
                person={person}
                jobName={selectedLanguage === "SQ" ? job.job_title.title?.sq : job.job_title.title?.en}
                setSelectedApplicant={setSelectedApplicant}
                setOpenSidebar={setOpenSidebar}
              />
            </div>
          ))}
      </div>
      <div>
        {nextPage && nextPage !== null && (
          <div ref={sentryRef}>
            <LoadingComponent />
          </div>
        )}
      </div>
      {isLoading && !loading && allApplications?.length === 0 && (
        <div className="Applications__no-content">
          <LoadingComponent />
        </div>
      )}
      {openRightSidebar && (
        <div>
          <RightSidebar
            onClose={() => hideRightSidebar()}
            sidebarRef={rightSidebarRef}
          >
            <ApplicantSidebar
              person={selectedApplicant}
              jobName={selectedLanguage === "SQ" ? job.job_title.title?.sq : job.job_title.title?.en}
              jobId={job.id}
              onClose={() => hideRightSidebar()}
              loadMore={refresh}
            />
          </RightSidebar>
        </div>
      )}
    </section>
  );
};

export default Applications;
