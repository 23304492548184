import React, { useRef, useState } from "react";

import { apiCall } from "../../../api/Api";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { useTranslation } from "react-i18next";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import ModalContainer from "../ModalContainer/ModalContainer";
import Button from "../Button/Button";
import calendarIcon from "../../../assets/svg/calendar-icon.svg";
import paymentCardIcon from "../../../assets/images/payment-card-icon.png";
import visaLogo from "../../../assets/images/visa-logo.png";
import masterCardIcon from "../../../assets/images/mastercard-icon.png";
import maestroIcon from "../../../assets/images/maestro-icon.png";
import threeDotsIcon from "../../../assets/images/three-dots-icon.png";

import "./PaymentCard.scss";

interface PaymentCardProps {
  item: any;
  defaultPaymentMethod: any;
}

const PaymentCard = (props: PaymentCardProps) => {
  const { item, defaultPaymentMethod } = props;
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPrimaryModalOpen, setIsPrimaryModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const dropdownRef = useRef(null);
  const globalCtx = useGlobalContext();
  const { t } = useTranslation("common");

  useOutsideClickDetection(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  const handleSetPrimaryCard = () => {
    setIsButtonLoading(true);
    apiCall("PUT", `subscription/set-default-payment-method/${item.id}/`)
      .then((response) => {
        globalCtx.openMessageModal(
          "success",
          t("staticMessage.paymentCardPrimarySuccess"),
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        globalCtx.openMessageModal(
          "error",
          error?.response?.data?.error || t("staticMessage.somethingWentWrong"),
        );
      })
      .finally(() => {
        setIsButtonLoading(false);
        setIsPrimaryModalOpen(false);
      });
  };

  const handleDeleteCard = () => {
    setIsButtonLoading(true);
    apiCall("DELETE", `subscription/detach-payment-method/${item.id}/`)
      .then((response) => {
        globalCtx.openMessageModal(
          "success",
          t("staticMessage.paymentCardDeleteSuccess"),
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        globalCtx.openMessageModal(
          "error",
          error?.response?.data?.error || t("staticMessage.somethingWentWrong"),
        );
      })
      .finally(() => {
        setIsButtonLoading(false);
        setIsDeleteModalOpen(false);
      });
  };

  return (
    <>
      {isPrimaryModalOpen && (
        <ModalContainer>
          <div className="MakePrimaryCardModal">
            <p className="MakePrimaryCardModal__question">
              {t("paymentCard.primaryModal.question")}
            </p>
            <div className="MakePrimaryCardModal__buttons">
              <Button
                className="MakePrimaryCardModal__buttons-button Button__secondary"
                onClick={() => {
                  setIsPrimaryModalOpen(false);
                }}
                label={t("paymentCard.primaryModal.cancel")}
              />
              <Button
                isLoading={isButtonLoading}
                className="MakePrimaryCardModal__buttons-button"
                onClick={() => {
                  handleSetPrimaryCard();
                }}
                label={t("paymentCard.primaryModal.confirm")}
              />
            </div>
          </div>
        </ModalContainer>
      )}

      {isDeleteModalOpen && (
        <ModalContainer>
          <div className="DeleteCardModal">
            <p className="DeleteCardModal__question">
              {t("paymentCard.deleteModal.question")}
            </p>
            <div className="DeleteCardModal__buttons">
              <Button
                className="DeleteCardModal__buttons-button Button__secondary"
                onClick={() => {
                  setIsDeleteModalOpen(false);
                }}
                label={t("paymentCard.deleteModal.cancel")}
              />
              <Button
                isLoading={isButtonLoading}
                className="DeleteCardModal__buttons-button Button__error"
                onClick={() => {
                  handleDeleteCard();
                }}
                label={t("paymentCard.deleteModal.delete")}
              />
            </div>
          </div>
        </ModalContainer>
      )}

      <div className="PaymentCard">
        <div className="PaymentCard__container">
          <div className="PaymentCard__left">
            <img
              alt="pic"
              className="PaymentCard__icon"
              src={
                item.card.brand === "visa"
                  ? visaLogo
                  : item.card.brand === "mastercard"
                    ? masterCardIcon
                    : item.card.brand === "maestro"
                      ? maestroIcon
                      : paymentCardIcon
              }
            />
          </div>
          <div className="PaymentCard__middle">
            <p className="PaymentCard__type">{item.card.brand}</p>
            <p className="PaymentCard__number">************{item.card.last4}</p>
            <div className="PaymentCard__expire">
              <img
                alt="pic"
                className="PaymentCard__expire-icon"
                src={calendarIcon}
              />
              <p className="PaymentCard__expire-date">{`${item.card.exp_month}/${item.card.exp_year}`}</p>
            </div>
          </div>
        </div>

        <div className="PaymentCard__right">
          {defaultPaymentMethod !== item.id && (
            <div className="PaymentCardDropdown">
              <div
                className="PaymentCardDropdown__head"
                onClick={() => {
                  setIsDropdownOpen(true);
                }}
              >
                <img
                  alt="pic"
                  className="PaymentCardDropdown__icon"
                  src={threeDotsIcon}
                />
              </div>
              {isDropdownOpen && (
                <div ref={dropdownRef} className="PaymentCardDropdown__body">
                  <p
                    className="PaymentCardDropdown__body-item"
                    onClick={() => {
                      setIsPrimaryModalOpen(true);
                      setIsDropdownOpen(false);
                    }}
                  >
                    {t("paymentCard.dropdown.primary")}
                  </p>
                  <p
                    className="PaymentCardDropdown__body-item"
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                      setIsDropdownOpen(false);
                    }}
                  >
                    {t("paymentCard.dropdown.delete")}
                  </p>
                </div>
              )}
            </div>
          )}

          {defaultPaymentMethod === item.id && (
            <p className="PaymentCard__default">{t("paymentCard.primary")}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentCard;
