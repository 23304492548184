import React, { useState, useEffect } from "react";
import { getTimeBetweenDates } from "../../../lib/helpers/getTimeBetweenDates";
import Button from "../../../components/shared/Button/Button";
import { useEditJobDetailsFormik } from "./useEditJobDetailsFormik";
import InputField from "../../../components/shared/InputField/InputField";
import TextAreaInput from "../../../components/shared/TextAreaInput/TextAreaInput";
import closeGray from "../../../assets/svg/close-gray.svg";
import deleteIcon from "../../../assets/svg/delete-icon.svg";
import MultiFileUploader from "../../../components/shared/MultiFileUploader/MultiFileUploader";
import DeleteModal from "../../../components/shared/DeleteModal/DeleteModal";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import {
  updateJobDetails,
  deleteJobImage,
  addJobImage,
  deleteJob,
} from "../../../api/Jobs";
import { convertMinsToHrsMins } from "../../../lib/helpers/convertMinsToHrsMins";
import chevronLeft from "../../../assets/svg/chevron-down-gray.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";
import cs from "classnames";
import { PhotoProvider, PhotoView } from "react-image-previewer";
import {
  SlideArrowLeft,
  SlideArrowRight,
  CloseButton,
} from "react-image-previewer/ui";

import "./EditJobDetails.scss";

const EditJobDetails = () => {
  let jobStorage = JSON.parse(localStorage.getItem("selectedJob")!!);
  let jobStatusStorage = localStorage.getItem("jobStatus");
  let selectedLanguage = localStorage.getItem("lang");
  const availableJobsCount = localStorage.getItem("availableJobsCount");
  const assignedJobsCount = localStorage.getItem("assignedJobsCount");

  const [isImageUploaderOpen, setIsImageUploaderOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteModal2, setOpenDeleteModal2] = useState(false);
  const [updateJob, setUpdateJob] = useState<any>(jobStorage);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [allImages, setAllImages] = useState(jobStorage?.image_urls);
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const globalCtx = useGlobalContext();
  const { t } = useTranslation("common");

  const navigate = useNavigate();

  const formik = useEditJobDetailsFormik(updateJob, {
    onSubmit: async (values: any) => {
      let data = {
        pay_rate: values.hourly_rate,
        description: values.description,
      };

      updateJobDetails(updateJob.id, data)
        .then((res) => {
          setUpdateJob(res.data);
          globalCtx.openMessageModal(
            "success",
            t("staticMessage.jobUpdatedSuccess"),
          );
          navigate("/jobs/upcoming-not-assigned");
          setIsButtonLoading(false);
        })
        .catch((err) => {
          const errorMessage = convertRestApiErrorCodes(
            err?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
          setIsButtonLoading(false);
        });
    },
  });

  useEffect(() => {
    if (
      formik.values.hourly_rate !== updateJob.pay_rate ||
      formik.values.description !== updateJob.description ||
      allImages !== updateJob?.image_urls
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [
    formik.values.hourly_rate,
    formik.values.description,
    updateJob.image_urls,
  ]);

  const handleDelete = () => {
    deleteJob(updateJob.id)
      .then((res) => {
        globalCtx.openMessageModal(
          "success",
          t("staticMessage.jobDeletedSuccess"),
        );
        navigate("/jobs/upcoming-not-assigned");
      })
      .catch((err) => {
        const errorMessage = convertRestApiErrorCodes(
          err?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
      });
  };

  return (
    <section className="EditJobDetails">
      <div className="EditJobDetails__header">
        <h1 className="EditJobDetails__default-title">{t("jobs.title")}</h1>
        <section className="EditJobDetails__container">
          <div
            onClick={() => navigate("/jobs/upcoming-not-assigned")}
            className={cs(
              "EditJobDetails__container-item",
              jobStatusStorage === "Upcoming" &&
                "EditJobDetails__container-item--active",
            )}
          >
            {t("jobs.upcomingTabLabel")}
          </div>
          <div
            onClick={() => {
              navigate("/jobs/past-unreviewed");
            }}
            className={cs(
              "EditJobDetails__container-item",
              jobStatusStorage === "Past" &&
                "EditJobDetails__container-item--active",
            )}
          >
            {t("jobs.pastTabLabel")}
          </div>
        </section>
        <section className="Jobs__buttons-container">
          <div
            className={cs(
              "Jobs__not-assigned-btn",
              updateJob.status === "available" &&
                "Jobs__not-assigned-btn--active",
            )}
            onClick={() => {
              navigate("/jobs/upcoming-not-assigned");
            }}
          >
            {`${t("jobs.notAssigned.tabLabel")} (${availableJobsCount})`}
          </div>
          <div
            className={cs(
              "Jobs__assigned-btn",
              updateJob.status === "assigned" && "Jobs__assigned-btn--active",
            )}
            onClick={() => {
              navigate("/jobs/upcoming-assigned");
            }}
          >
            {`${t("jobs.assigned.tabLabel")} (${assignedJobsCount})`}
          </div>
        </section>
        <div className="EditJobDetails__header-wrapper">
          <img
            alt="chevron left icon"
            src={chevronLeft}
            className="EditJobDetails__back-icon"
            onClick={() => navigate("/jobs/upcoming-not-assigned")}
          />
          <h2 className="EditJobDetails__title">
            {selectedLanguage === "SQ"
              ? `${updateJob?.job_title?.title.sq}`
              : `${updateJob?.job_title?.title.en}`}
          </h2>
        </div>
      </div>
      <div className="EditJobDetails__form">
        <div className="EditJobDetails__item-flex">
          <div className="EditJobDetails__item">
            <p className="EditJobDetails__label">
              {t("jobs.jobDetails.jobTitle.label")}
            </p>
            <p className="EditJobDetails__value">
              {selectedLanguage === "SQ"
                ? `${updateJob?.job_title?.title.sq}`
                : `${updateJob?.job_title?.title.en}`}
            </p>
          </div>
        </div>
        <div className="EditJobDetails__item">
          <p className="EditJobDetails__label">
            {t("jobs.jobDetails.hourlyRate.label")}
          </p>
          <div className="EditJobDetails__input-container">
            <InputField
              type="text"
              name="hourly_rate"
              value={formik.values.hourly_rate}
              onBlur={(e: any) => formik.handleBlur(e)}
              onChange={(e: any) => formik.handleChange(e)}
              className="EditJobDetails__input"
              textIcon="&#8364;/h"
              disabled={updateJob.applications_count > 0}
            />
            {formik.errors.hourly_rate && formik.touched.hourly_rate && (
              <p className="EditJobDetails__input-error">
                {formik.errors.hourly_rate as string}
              </p>
            )}
          </div>
        </div>
        <div className="EditJobDetails__item">
          <p className="EditJobDetails__label">
            {t("jobs.jobDetails.businessUnit.label")}
          </p>
          <p className="EditJobDetails__value">
            {updateJob?.organization_unit?.city}
          </p>
        </div>
        <div className="EditJobDetails__item">
          <p className="EditJobDetails__label">
            {t("jobs.jobDetails.shift.label")}
          </p>
          {updateJob.shifts &&
            updateJob.shifts.map((item: any, index: number) => (
              <div className="EditJobDetails__shift-container" key={index}>
                <div>
                  <div className="EditJobDetails__shift-item">
                    {`${daysOfTheWeek[new Date(item?.start_time).getDay()]} ${new Date(item?.start_time).toLocaleString("en-GB", { month: "short", day: "numeric" })}`}

                    {new Date(item?.end_time).toLocaleString("en-GB", {
                      month: "short",
                      day: "numeric",
                    }) !==
                    new Date(item?.start_time).toLocaleString("en-GB", {
                      month: "short",
                      day: "numeric",
                    })
                      ? ` - ${daysOfTheWeek[new Date(item?.end_time).getDay()]} ${new Date(item?.end_time).toLocaleString("en-GB", { month: "short", day: "numeric" })} `
                      : ""}
                  </div>
                  <div className="EditJobDetails__shift-item-small">
                    {`${new Date(item?.start_time).toLocaleString("en-GB", { hour: "numeric", minute: "numeric" })} ${t("jobs.jobDetails.shift.to")} ${new Date(item?.end_time).toLocaleString("en-GB", { hour: "numeric", minute: "numeric" })} `}
                  </div>
                </div>
                <div className="EditJobDetails__shift-item">
                  {convertMinsToHrsMins(
                    getTimeBetweenDates(
                      new Date(item?.start_time),
                      new Date(item?.end_time),
                    ).minutes,
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="EditJobDetails__item">
          <p className="EditJobDetails__label">
            {t("jobs.jobDetails.description.label")}
          </p>
          {updateJob.applications_count > 0 ? (
            <div className="EditJobDetails__input-container">
              <div className="EditJobDetails__textarea-desc">
                {formik.values.description}
              </div>
            </div>
          ) : (
            <div className="EditJobDetails__input-container">
              <TextAreaInput
                name="description"
                value={formik.values.description}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="EditJobDetails__textarea"
                disabled={updateJob.applications_count > 0}
              />
              {formik.errors.description && formik.touched.description && (
                <p className="EditJobDetails__input-error">
                  {formik.errors.description as string}
                </p>
              )}
            </div>
          )}
        </div>
        <div className="EditJobDetails__item">
          <p className="EditJobDetails__label">
            {t("jobs.jobDetails.images.label")}
          </p>
          <PhotoProvider
            maskOpacity={0.7}
            overlayRender={(props) => {
              return (
                <>
                  <SlideArrowLeft {...props} />
                  <SlideArrowRight {...props} />
                  <CloseButton onClick={props.onClose} />
                </>
              );
            }}
          >
            <div className="EditJobDetails__images">
              {updateJob?.image_urls?.length !== 0 &&
                updateJob?.image_urls?.map((item: any, index: number) => (
                  <div
                    className="EditJobDetails__image-preview-container"
                    key={index}
                  >
                    <PhotoView src={item} key={index}>
                      <img
                        alt="image preview"
                        src={item}
                        className="EditJobDetails__image-preview"
                        style={{
                          width: "90px",
                          height: "90px",
                          objectFit: "cover",
                          borderRadius: "6px",
                        }}
                      />
                    </PhotoView>
                    {updateJob?.applications_count === 0 && (
                      <img
                        alt="delete icon"
                        src={deleteIcon}
                        className="EditJobDetails__delete-icon"
                        onClick={() => {
                          setOpenDeleteModal(true);
                        }}
                      />
                    )}

                    {openDeleteModal && (
                      <DeleteModal
                        onCancel={() => setOpenDeleteModal(false)}
                        onSubmit={() => {
                          setIsLoading(true);
                          deleteJobImage(updateJob.id, item)
                            .then((res) => {
                              setUpdateJob(res.data);
                              setIsLoading(false);
                            })
                            .catch((err) => {
                              setIsLoading(false);
                            });
                          setOpenDeleteModal(false);
                        }}
                      />
                    )}
                  </div>
                ))}
              {updateJob?.image_urls.length < 10 &&
                updateJob?.applications_count === 0 && (
                  <div
                    onClick={() => setIsImageUploaderOpen(true)}
                    className="EditJobDetails__images-placeholder"
                  >
                    {isLoading ? (
                      <LoadingComponent />
                    ) : (
                      <img
                        src={closeGray}
                        alt="plus icon"
                        className="EditJobDetails__add-icon"
                      />
                    )}
                  </div>
                )}
            </div>
          </PhotoProvider>
        </div>
        {isImageUploaderOpen && (
          <MultiFileUploader
            accept="image/*"
            onCancel={() => {
              setIsImageUploaderOpen(false);
            }}
            onSubmit={(images) => {
              setIsLoading(true);
              let data = {
                image_data: images,
              };

              addJobImage(updateJob.id, data)
                .then((res) => {
                  setUpdateJob(res.data);
                  setIsLoading(false);
                })
                .catch((err) => {
                  setIsLoading(false);
                });
              setIsImageUploaderOpen(false);
            }}
            allImages={updateJob?.image_urls}
            maxImageSize={10485760}
          />
        )}
      </div>

      <div className="EditJobDetails__buttons-wrapper">
        <Button
          onClick={() => setOpenDeleteModal2(true)}
          className="Button__error EditJobDetails__delete-button"
          label={t("jobs.jobDetails.delete")}
        />
        {openDeleteModal2 && (
          <DeleteModal
            name={`${updateJob?.job_title?.title.en} job`}
            onCancel={() => setOpenDeleteModal2(false)}
            onSubmit={() => {
              handleDelete();
              setOpenDeleteModal2(false);
            }}
          />
        )}
        <div className="EditJobDetails__buttons">
          <Button
            type="reset"
            className="Button__secondary  EditJobDetails__cancel-btn"
            label={t("jobs.jobDetails.cancel")}
            onClick={() => {
              navigate("/jobs/upcoming-not-assigned");
            }}
          />
          <Button
            type="submit"
            label={t("jobs.jobDetails.submit")}
            onClick={() => {
              formik.handleSubmit();
            }}
            className="EditJobDetails__submit-btn"
            disabled={
              isButtonLoading ||
              !isChanged ||
              updateJob.applications_count > 0 ||
              formik.errors.description !== undefined ||
              formik.errors.hourly_rate !== undefined
            }
          />
        </div>
      </div>
    </section>
  );
};

export default EditJobDetails;
