import React, { useState, useId, useEffect } from "react";
import Button from "../../../components/shared/Button/Button";
import DatePicker from "react-datepicker";
import calendarIcon from "../../../assets/svg/calendar-icon.svg";
import { getTimeBetweenDates } from "../../../lib/helpers/getTimeBetweenDates";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import "react-datepicker/dist/react-datepicker.css";

import "./AddNewShift.scss";

const AddNewShift = (props: any) => {
  let selectedLanguage = localStorage.getItem("lang");
  let today = new Date();
  let tomorrow = today;
  let currentHours = today.getHours();
  let currentMinutes = today.getMinutes();

  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(currentHours, currentMinutes);

  const [startDate, setStartDate] = useState<any>();
  const [startTime, setStartTime] = useState<any>();
  const [endDate, setEndDate] = useState<any>(startTime);
  const [endTime, setEndTime] = useState<any>(endDate);
  const [selectedDates, setSelectedDates] = useState<any>({
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  });
  const [error, setError] = useState("");
  const shiftId = useId();
  const { t } = useTranslation("common");

  useEffect(() => { 
        if(selectedDates.startTime){
          let setHours = new Date(startTime)?.getHours() + 3;
          let minTime2 = new Date(startTime).setHours(setHours);
          setEndDate(new Date(minTime2));
          setEndTime(new Date(minTime2));
          setSelectedDates({ ...selectedDates, endDate: new Date(minTime2) });
          setSelectedDates({ ...selectedDates, endTime: new Date(minTime2) });
        } 
    
  }, [startTime])

  const handleClick = () => {
    let comparetwoDates = getTimeBetweenDates(startTime, endTime);
    if (comparetwoDates.hours === 12 && comparetwoDates.minutes / 60 > 12) {
      setError(t("jobs.addNewJobs.shifts.addShift.errorOne"));
      return;
    }
    if (comparetwoDates.hours > 12) {
      setError(t("jobs.addNewJobs.shifts.addShift.errorOne"));
      return;
    }
    if (comparetwoDates.hours < 3) {
      setError(t("jobs.addNewJobs.shifts.addShift.errorTwo"));
      return;
    }
    if (comparetwoDates.hours < 0) {
      setError(t("jobs.addNewJobs.shifts.addShift.errorThree"));
      return;
    }

    if (comparetwoDates.hours <= 12) {
      setError("");
      props.setShifts((prev: any) => [
        ...prev,
        {
          id: shiftId,
          start_time: startTime,
          end_time: endTime,
        },
      ]);
      props.setNewShift(false);
    } else {
      setError(t("jobs.addNewJobs.shifts.addShift.errorOne"));
    }
  };

  const filteredTime = (time: any) => {
    const selectedDate = new Date(time);

    let setHours = new Date(startTime)?.getHours() + 3;
    let minTime = new Date(startTime).setHours(setHours);

    let setMaxHours = new Date(startTime).getHours() + 12;
    let maxTime = new Date(startTime).setHours(setMaxHours);

    return (
      new Date(minTime).getTime() <= selectedDate.getTime() &&
      new Date(maxTime).getTime() >= selectedDate.getTime()
    );
  };

  const tomorrowTimeFiltered = (date: any) => tomorrow < date;

  return (
    <div className="AddNewShift">
      <div className="AddNewShift__container">
        <div>
          <div className="AddNewShift__label">
            {t("jobs.addNewJobs.shifts.addShift.startDate")}
          </div>
          <DatePicker
            selectsStart
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              setStartTime(date);
              setSelectedDates({ ...selectedDates, startDate: date });
            }}
            filterTime={tomorrowTimeFiltered}
            dateFormat="dd. MM. yyyy"
            className="AddNewShift__date-input"
            minDate={tomorrow}
            showIcon
            icon={
              <img
                src={calendarIcon}
                width={21}
                height={21}
                alt="calendar icon"
                className="AddNewShift__date-icon"
              />
            }
          />
        </div>
        <div>
          <div className="AddNewShift__label">
            {t("jobs.addNewJobs.shifts.addShift.atWhatTime")}
          </div>
          <DatePicker
            selected={startTime}
            onChange={(date) => {
              setStartTime(date);
              setSelectedDates({ ...selectedDates, startTime: date });
            }}
            filterTime={tomorrowTimeFiltered}
            showTimeSelect
            showTimeSelectOnly
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
            className={cs(
              "AddNewShift__time-input",
              !startDate && "AddNewShift__time-input--disabled",
            )}
            disabled={!selectedDates.startDate}
          />
        </div>
      </div>
      <div className="AddNewShift__container">
        <div>
          <div className="AddNewShift__label">
            {t("jobs.addNewJobs.shifts.addShift.endDate")}
          </div>
          <DatePicker
            selectsEnd
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
              setEndTime(date);
              setSelectedDates({ ...selectedDates, endDate: date });
            }}
            endDate={endDate}
            startDate={startTime}
            minDate={startTime}
            maxDate={
              new Date(
                new Date(startTime).setHours(
                  new Date(startTime).getHours() + 12,
                ),
              )
            }
            dateFormat="dd. MM. yyyy"
            className={cs(
              "AddNewShift__date-input",
              (!startDate || !startTime) && "AddNewShift__date-input--disabled",
            )}
            showIcon
            icon={
              <img
                src={calendarIcon}
                width={21}
                height={21}
                alt="calendar icon"
                className="AddNewShift__date-icon"
              />
            }
            disabled={!selectedDates.startDate || !selectedDates.startTime}
          />
        </div>
        <div>
          <div className="AddNewShift__label">
            {t("jobs.addNewJobs.shifts.addShift.atWhatTime")}
          </div>
          <DatePicker
            selected={endTime}
            onChange={(date) => {
              setEndTime(date);
              setSelectedDates({ ...selectedDates, endTime: date });
            }}
            filterTime={filteredTime}
            minDate={startTime}
            showTimeSelect
            showTimeSelectOnly
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
            className={cs(
              "AddNewShift__time-input",
              (!startDate || !startTime || !endDate) &&
                "AddNewShift__time-input--disabled",
            )}
            disabled={
              !selectedDates.startTime 
            }
          />
        </div>
      </div>
      {error && <div className="AddNewShift__error">{error}</div>}
      {selectedLanguage === "SQ" ? (
          <div className="AddNewShift__shift-info">
          <div> <span>Koha e Postimit: </span> Punët mund të postohen deri në 24 orë para kohës së fillimit.</div> 
           <div> <span>Publikime:</span> Punët mbeten të publikuara për kandidatët deri në 12 orë para kohës së fillimit të punës.</div>
          <div><span>Afati i Pranimit:</span> Ju mund të pranoni aplikantët deri në 10 orë para fillimit të punës.</div> 
          </div>
      ) : (
        <div className="AddNewShift__shift-info">
          <div> <span>Post Time: </span> Jobs can be posted up to 24 hours before the start time.</div> 
          <div> <span>Publications:</span> Jobs remain published for candidates up to 12 hours before the job start time. </div>
          <div><span>Acceptance Deadline:</span> You can accept applicants up to 10 hours before the start of work.</div> 
        </div>
      )}
    
      <div className="flex">
        <Button
          label={t("jobs.addNewJobs.shifts.addShift.save")}
          onClick={() => {
            handleClick();
          }}
          className={cs("AddNewShift__button" )}
          disabled={
            !selectedDates.startTime
          }
        />
        <Button
            label={t("jobs.addNewJobs.shifts.editShift.cancel")}
            onClick={() => {
              props.setNewShift(false);
            }}
            className="AddNewShift__cancel-button"
          />
        </div>
    </div>
  );
};

export default AddNewShift;
