import axios from "axios";

export const reverseGeocoding = async (longitude: number, latitude: number) => {
  const accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_KEY;
  return await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?language=sq`,
    {
      params: {
        access_token: accessToken,
        country: "XK",
      },
    },
  );
};
