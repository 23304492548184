import React, { useState } from "react";

import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import { useAuthContext } from "../../context/AuthContext/AuthContext";
import CompanyDropdown from "./CompanyDropdown/CompanyDropdown";
import UserProfileDropdown from "./UserProfileDropdown/UserProfileDropdown";
import VerifyPhoneNumberButton from "./VerifyPhoneNumberButton/VerifyPhoneNumberButton";
import VerifyPhoneNumberModal from "../shared/VerifyPhoneNumberModal/VerifyPhoneNumberModal";
import peakJobsLogo from "../../assets/svg/peak-jobs-logo.svg";
import hamburgerIcon from "../../assets/svg/hamburger-icon.svg";

import "./Header.scss";
import HelpDropdown from "../shared/HelpDropdown/HelpDropdown";

interface HeaderProps {
  openSidebar: () => void;
}

const Header = (props: HeaderProps) => {
  const { openSidebar } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useResponsiveDimensions().isMobile;
  const authCtx = useAuthContext();

  return (
    <>
      {isModalOpen && (
        <VerifyPhoneNumberModal
          onCloseClick={() => {
            setIsModalOpen(false);
          }}
        />
      )}
      <div className="Header">
        <div className="Header__left">
          {isMobile ? (
            <img
              alt="pic"
              src={hamburgerIcon}
              className="Header__hamburger"
              onClick={() => {
                openSidebar();
              }}
            />
          ) : (
            <img alt="pic" className="Header__logo" src={peakJobsLogo} />
          )}
        </div>
        <div className="Header__right">
          {!authCtx.localUserProfile?.phone_number && (
            <VerifyPhoneNumberButton
              onClick={() => {
                setIsModalOpen(true);
              }}
            />
          )}

          <HelpDropdown />
          <CompanyDropdown />
          <UserProfileDropdown />
        </div>
      </div>
    </>
  );
};

export default Header;
