import React from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";

import companyAvatar from "../../../assets/images/company-avatar.png";
import settingsWheelWhite from "../../../assets/svg/settings-wheel-white.svg";
import settingsWheelDark from "../../../assets/svg/settings-wheel-dark.svg";
import verifiedIcon from "../../../assets/svg/verified-icon.svg";

import cs from "classnames";

import "./CompanyDropdown.scss";

const CompanyDropdown = () => {
  const navigate = useNavigate();
  const authCtx = useAuthContext();
  const location = useLocation();

  return (
    <div
      className="CompanyDropdown"
      onClick={() => {
        navigate("/company/company-settings");
      }}
    >
      <div
        className={cs(
          "CompanyDropdown__head",
          location.pathname.startsWith("/company") &&
            "CompanyDropdown__head-active",
        )}
      >
        <div className="CompanyDropdown__image-container">
          <img
            alt="pic"
            className="CompanyDropdown__image"
            src={authCtx.localOrganization.logo_url || companyAvatar}
          />
          {authCtx.localOrganization.is_verified && (
            <img
              alt="pic"
              src={verifiedIcon}
              className="CompanyDropdown__verified"
            />
          )}
        </div>

        <h5
          className={cs(
            "CompanyDropdown__name",
            location.pathname.startsWith("/company") &&
              "CompanyDropdown__name-active",
          )}
        >
          {authCtx.localOrganization.name || "Company Name"}
        </h5>
        <img
          alt="pic"
          className="CompanyDropdown__icon"
          src={
            location.pathname.startsWith("/company")
              ? settingsWheelWhite
              : settingsWheelDark
          }
        />
      </div>
    </div>
  );
};

export default CompanyDropdown;
