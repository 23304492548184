import React, { useEffect, useRef, useState } from "react";

import "./FourDigits.scss";

interface FourDigitsPropsType {
  getValues: (fourDigitsValue: string) => void;
}

const FourDigits = (props: FourDigitsPropsType) => {
  const { getValues } = props;

  const [firstInputValue, setFirstInputValue] = useState("");
  const [secondInputValue, setSecondInputValue] = useState("");
  const [thirdInputValue, setThirdInputValue] = useState("");
  const [fourthInputValue, setFourthInputValue] = useState("");

  const firstInputRef = useRef(null);
  const secondInputRef = useRef(null);
  const thirdInputRef = useRef(null);
  const fourthInputRef = useRef(null);

  const focusFunction = (reference: any) => {
    if (reference != null) {
      reference.current.focus();
    }
  };

  const handleFirstInputChange = (e: any) => {
    setFirstInputValue(e.target.value);
  };
  const handleSecondInputChange = (e: any) => {
    setSecondInputValue(e.target.value);
  };
  const handleThirdInputChange = (e: any) => {
    setThirdInputValue(e.target.value);
  };
  const handleFourthInputChange = (e: any) => {
    setFourthInputValue(e.target.value);
  };

  const fourDigitsValue =
    firstInputValue + secondInputValue + thirdInputValue + fourthInputValue;

  useEffect(() => {
    getValues(fourDigitsValue);
  }, [firstInputValue, secondInputValue, thirdInputValue, fourthInputValue]);

  return (
    <div className="FourDigits">
      <input
        type="text"
        className="FourDigits__input"
        maxLength={1}
        id="first_input"
        ref={firstInputRef}
        onKeyUp={() => {
          focusFunction(secondInputRef);
        }}
        value={firstInputValue}
        onChange={(e) => {
          handleFirstInputChange(e);
        }}
      />
      <input
        type="text"
        className="FourDigits__input"
        maxLength={1}
        id="second_input"
        ref={secondInputRef}
        onKeyUp={() => {
          focusFunction(thirdInputRef);
        }}
        value={secondInputValue}
        onChange={(e) => {
          handleSecondInputChange(e);
        }}
      />
      <input
        type="text"
        className="FourDigits__input"
        maxLength={1}
        id="third_input"
        ref={thirdInputRef}
        onKeyUp={() => {
          focusFunction(fourthInputRef);
        }}
        value={thirdInputValue}
        onChange={(e) => {
          handleThirdInputChange(e);
        }}
      />
      <input
        type="text"
        className="FourDigits__input"
        maxLength={1}
        id="fourth_input"
        ref={fourthInputRef}
        onKeyUp={() => {
          focusFunction(firstInputRef);
        }}
        value={fourthInputValue}
        onChange={(e) => {
          handleFourthInputChange(e);
        }}
      />
    </div>
  );
};

export default FourDigits;
