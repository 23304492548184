import React, { useRef, useState } from "react";

import arrowDown from "../../../assets/svg/arrow-down.svg";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";

import "./LanguageDropdown.scss";

const LanguageDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const globalCtx = useGlobalContext();
  const dropdownRef = useRef(null);

  useOutsideClickDetection(dropdownRef, () => {
    setIsOpen(false);
  });

  const languagesItems = [
    {
      keyword: "SQ",
      label: "SHQIP",
    },
    {
      keyword: "EN",
      label: "ENGLISH",
    },
  ];

  return (
    <div className="LanguageDropdown" ref={dropdownRef}>
      <div
        className="LanguageDropdown__head"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <p className="LanguageDropdown__head-label">
          {" "}
          {globalCtx.selectedLanguage.label}{" "}
        </p>

        <img
          alt="pic"
          className="LanguageDropdown__head-arrow"
          src={arrowDown}
        />
      </div>
      {isOpen && (
        <div className="LanguageDropdown__body">
          {languagesItems.map((item, index) => (
            <div
              key={index}
              className="LanguageDropdown__body-item"
              onClick={() => {
                globalCtx.onLanguageSelect(item.keyword, item.label);
                setIsOpen(false);
              }}
            >
              <p className="LanguageDropdown__body-item--label">{item.label}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
