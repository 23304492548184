import React, { useEffect, useState } from "react";

import { apiCall } from "../../api/Api";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/GlobalContext/GlobalContext";
import { convertRestApiErrorCodes } from "../../lib/helpers/handleErrors";
import LoadingComponent from "../../components/shared/LoadingComponent/LoadingComponent";
import useGetParameterByName from "../../lib/hooks/useGetParameterByName";
import YourPlan from "./YourPlan/YourPlan";

import "./Subscription.scss";

const Subscription = () => {
  const [activePlan, setActivePlan] = useState<any | null>();
  const [paymentMethods, setPaymentMethods] = useState<any | null>();
  const [invoices, setInvoices] = useState<any | null>();
  const { t } = useTranslation("common");
  const globalCtx = useGlobalContext();
  const sessionId = useGetParameterByName("session_id");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // HANDLE PAYMENT METHOD RESPONSE
    if (sessionId.length > 0) {
      apiCall("GET", `subscription/session-status/${sessionId}/`)
        .then((response) => {
          if (response.data.status === "open") {
            globalCtx.openMessageModal(
              "error",
              t("staticMessage.paymentCardAddedError"),
            );
            setTimeout(() => {
              let searchParams = new URLSearchParams(location.search);
              searchParams.delete("session_id");
              navigate(`?${searchParams}`, { replace: true });
            }, 3000);
          }
          if (response.data.status === "complete") {
            globalCtx.openMessageModal(
              "success",
              t("staticMessage.paymentCardAddedSuccess"),
            );
            const planId = localStorage.getItem("selectedPlan");
            if (planId) {
              navigate(`/subscription/subscription-plans`);
            }
            setTimeout(() => {
              //ADD HERE LOGIC FOR SUBCRIPTION PLAN
              let searchParams = new URLSearchParams(location.search);
              searchParams.delete("session_id");
              if (!planId) {
                navigate(`?${searchParams}`, { replace: true });
              }
              // setActiveTab("subscribePlans");
            }, 3000);
          }
        })
        .catch((error) => {
          const errorMessage = convertRestApiErrorCodes(
            error?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
          setTimeout(() => {
            let searchParams = new URLSearchParams(location.search);
            searchParams.delete("session_id");
            navigate(`?${searchParams}`, { replace: true });
          }, 3000);
        });
    }
  }, []);

  useEffect(() => {
    // GET ACTIVE SUBSCRIPTION PLANS
    apiCall("GET", "subscription/")
      .then((response) => {
        setActivePlan(response.data[0]);

        // GET PAYMENT METHODS
        apiCall("GET", "subscription/payment-methods/")
          .then((response) => {
            setPaymentMethods(response.data);
          })
          .catch((error) => {
            setPaymentMethods(null);
          });

        // GET INVOICES
        apiCall("GET", "subscription/invoices/")
          .then((response) => {
            setInvoices(response.data.data);
          })
          .catch((error) => {
            setInvoices(null);
          });
      })
      .catch((error) => {
        setActivePlan(null);
      });
  }, []);

  return (
    <div className="Subscription">
      <div className="Subscription__head">
        <p className="Subscription__head-title">{t("subscription.title")}</p>
      </div>
      {activePlan === undefined ? (
        <div className="Subscription__loader">
          <LoadingComponent />
        </div>
      ) : activePlan === null ? (
        <div className="Subscription__noData">{t("noData")}</div>
      ) : (
        <div className="Subscription__body">
          <YourPlan
            activePlan={activePlan}
            paymentMethods={paymentMethods}
            invoices={invoices}
          />
        </div>
      )}
    </div>
  );
};

export default Subscription;
