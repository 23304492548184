import React, { useState, useEffect } from "react";
import chevronLeft from "../../../assets/svg/chevron-down-gray.svg";
import InputField from "../../../components/shared/InputField/InputField";
import { useNewJobFormik } from "./useNewJobFormik";
import TextAreaInput from "../../../components/shared/TextAreaInput/TextAreaInput";
import closeGray from "../../../assets/svg/close-gray.svg";
import Button from "../../../components/shared/Button/Button";
import info from "../../../assets/svg/info.svg";
import SelectBox from "../../../components/shared/SelectBox/SelectBox";
import cs from "classnames";
import AddNewShift from "../NewShift/AddNewShift";
import DropdownSearch from "../../../components/shared/DropdownSearch/DropdownSearch";
import EditShift from "../EditShift/EditShift";
import MultiFileUploader from "../../../components/shared/MultiFileUploader/MultiFileUploader";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import deleteIcon from "../../../assets/svg/delete-icon.svg";
import warningIcon from "../../../assets/svg/warning.svg";
import DeleteModal from "../../../components/shared/DeleteModal/DeleteModal";
import { getJobTitles, getSubscription, createNewJob } from "../../../api/Jobs";
import { useTranslation } from "react-i18next";
import { getOrganizationUnits } from "../../../api/Organization";
import { apiCall } from "../../../api/Api";
import { getTimeBetweenDates } from "../../../lib/helpers/getTimeBetweenDates";
import { convertMinsToHrsMins } from "../../../lib/helpers/convertMinsToHrsMins";
import { useNavigate, useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { PhotoProvider, PhotoView } from "react-image-previewer";
import {
  SlideArrowLeft,
  SlideArrowRight,
  CloseButton,
} from "react-image-previewer/ui";
import SubscribeForJobsModal from "../../../components/shared/SubscribeForJobsModal/SubscribeForJobsModal";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";

import "./NewJobForm.scss";

const NewJobForm = () => {
  const { t } = useTranslation("common");
  let selectedLanguage = localStorage.getItem("lang");
  const [selectedUnit, setSelectedUnit] = useState<any>({
    name: "",
    value: "",
  });
  const [selectedJob, setSelectedJob] = useState("");
  const [selectJobItem, setSelectedJobItem] = useState<any>();
  const [newShift, setNewShift] = useState<boolean>(false);
  const [shifts, setShifts] = useState<any>([]);
  const [jobTitles, setJobTitles] = useState<any>();
  const [businessUnits, setBusinessUnits] = useState<[]>([]);
  const [selectError, setSelectError] = useState({
    name: "",
    active: false,
  });
  const [selectShift, setSelectShift] = useState<any>([]);
  const [editShift, setEditShift] = useState<any>(false);
  //add image state
  const [isImageUploaderOpen, setIsImageUploaderOpen] = useState(false);
  const [base64Images, setBase64Images] = useState<File[]>([]);
  const [base64ImageError, setBase64ImageError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffSet] = useState(0);
  const [nextPage, setNextPage] = useState<any>("");
  const [subscription, setSubscription] = useState<any>();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [shiftsAllowed, setShiftsAllowed] = useState();
  const [seatsAllowed, setSeatsAllowed] = useState();
  //DEBAUNCER
  const [filter, setFilter] = useState<string>("");
  const [debouncedFilter] = useDebounce(filter, 500);
  const [isLoaded, setIsLoaded] = useState(false);

  const globalCtx = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    try {
      getJobTitles("", limit, offset).then((res) => {
        if (selectedLanguage === "SQ") {
          const allJobTitles: string[] = res?.data?.results.map(
            (item: any) => item?.title?.sq,
          );
          setJobTitles([...allJobTitles]);
          setSelectedJobItem(res?.data?.results);
        } else {
          const allJobTitles: string[] = res?.data?.results.map(
            (item: any) => item?.title?.en,
          );
          setJobTitles([...allJobTitles]);
          setSelectedJobItem(res?.data?.results);
        }
        if (res.data?.next !== null) {
          setNextPage(res.data?.next);
        }
      });
      getOrganizationUnits().then((res) => {
        setBusinessUnits(res.data);
      });
      getSubscription().then((res) => {
        if (res.data) {
          setSubscription(res.data);
          setShiftsAllowed(res.data[0]?.plan?.shifts_per_job_limit);
          setSeatsAllowed(res.data[0]?.plan?.seats_per_job_limit);
        }
      });
    } catch (err) {}
  }, []);

  const loadMore = () => {
    let resultUrl = nextPage.split("?")[1];
    apiCall("GET", `job-classification/job-titles/?${resultUrl}`)
      .then((res) => {
        if (selectedLanguage === "SQ") {
          const allJobTitles: string[] = res?.data?.results.map((item: any) => {
            if (!jobTitles?.includes(item?.title?.sq)) {
              return item?.title?.sq;
            }
          });
          setJobTitles([...jobTitles, ...allJobTitles]);
          setSelectedJobItem([...selectJobItem, ...res?.data?.results]);
        } else {
          const allJobTitles: string[] = res?.data?.results.map((item: any) => {
            if (!jobTitles?.includes(item?.title?.en)) {
              return item?.title?.en;
            }
          });
          setJobTitles([...jobTitles, ...allJobTitles]);
          setSelectedJobItem([...selectJobItem, ...res?.data?.results]);
        }
        if (res.data?.next !== null) {
          setNextPage(res.data?.next);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const getJobTitleCall = (value: string) => {
      getJobTitles(value, 50)
        .then((res) => {
          if (selectedLanguage === "SQ") {
            const allJobTitles: string[] = res?.data?.results.map(
              (item: any) => item?.title?.sq,
            );
            const arrayOfNames = [...jobTitles, ...allJobTitles];
            setJobTitles([...new Set(arrayOfNames)]);
            setSelectedJobItem(res?.data?.results);
          } else {
            const allJobTitles: string[] = res?.data?.results.map(
              (item: any) => item?.title?.en,
            );
            const arrayOfNames = [...jobTitles, ...allJobTitles];
            setJobTitles([...new Set(arrayOfNames)]);
            setSelectedJobItem(res?.data?.results);
          }
          if (res.data?.next !== null) {
            setNextPage(res.data?.next);
          }
        })
        .catch((err) => {});
    };
    if (isLoaded) {
      getJobTitleCall(filter);
    }
    if (isLoaded && filter === "") {
      getJobTitles("", limit, offset).then((res) => {
        if (selectedLanguage === "SQ") {
          const allJobTitles: string[] = res?.data?.results.map(
            (item: any) => item?.title?.sq,
          );
          setJobTitles([...allJobTitles]);
          setSelectedJobItem(res?.data?.results);
        } else {
          const allJobTitles: string[] = res?.data?.results.map(
            (item: any) => item?.title?.en,
          );
          setJobTitles([...allJobTitles]);
          setSelectedJobItem(res?.data?.results);
        }
        if (res.data?.next !== null) {
          setNextPage(res.data?.next);
        }
      });
    }

    setIsLoaded(true);
  }, [debouncedFilter]);

  const formik = useNewJobFormik(seatsAllowed, {
    onSubmit: async (values: any) => {
      setIsButtonLoading(true);
      const filterItem = selectJobItem.filter((item: any) => {
        if (selectedLanguage === "SQ") {
          return item?.title?.sq === selectedJob;
        } else {
          return item?.title?.en === selectedJob;
        }
      });

      let data = {
        job_title: filterItem ? filterItem[0]?.id : "",
        pay_rate: values.hourly_rate,
        description: values.description,
        organization_unit: selectedUnit?.id,
        image_data: base64Images,
        shifts: shifts,
        is_active: true,
        latitude: selectedUnit?.latitude,
        longitude: selectedUnit?.longitude,
        seats: values.seats,
      };

      createNewJob(data)
        .then((res) => {
          globalCtx.openMessageModal(
            "success",
            t("staticMessage.newJobPostedSuccess"),
          );
          navigate("/jobs/upcoming-not-assigned", {
            state: { previousLocationPathname: location.pathname },
          });
          setIsButtonLoading(false);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "jobs_limit_reached") {
            setIsModalOpen(true);
          } else {
            const errorMessage = convertRestApiErrorCodes(
              err?.response?.data?.code,
              t,
            );
            globalCtx.openMessageModal("error", errorMessage);
          }
          setIsButtonLoading(false);
        });
    },
  });

  return (
    <>
      {isModalOpen && (
        <SubscribeForJobsModal
          onUpgradeClick={() => {
            navigate("/subscription");
          }}
          onCloseClick={() => {
            setIsModalOpen(false);
          }}
        />
      )}
      <div className="NewJobForm">
        <div className="NewJobForm__header">
          <h1 className="NewJobForm__default-title">{t("jobs.title")}</h1>
          <div className="NewJobForm__header-wrapper">
            <img
              alt="chevron left icon"
              src={chevronLeft}
              className="NewJobForm__back-icon"
              onClick={() => navigate(-1)}
            />
            <h2 className="NewJobForm__title">{t("jobs.addNewJobs.title")}</h2>
          </div>
        </div>
        <div className="NewJobForm__form">
          <div className="NewJobForm__column">
            <div className="NewJobForm__subtitle">
              {t("jobs.addNewJobs.jobDetails.title")}
            </div>
            <div className="NewJobForm__input-container">
              <DropdownSearch
                items={jobTitles}
                selectedItem={selectedJob}
                onItemSelect={(item) => {
                  setSelectedJob(item);
                  setSelectError({ name: "Job", active: false });
                }}
                onBlur={() => {
                  if (selectedJob?.length === 0) {
                    setSelectError({ name: "Job", active: true });
                  }
                }}
                inputPlaceholder={t(
                  "jobs.addNewJobs.jobDetails.jobTitle.placeholder",
                )}
                className="NewJobForm__dropdown"
                loadMore={loadMore}
                setFilter={setFilter}
              />

              {selectError.active && selectError.name === "Job" && (
                <h5 className="NewJobForm__input-error">
                  {t("jobs.addNewJobs.jobDetails.jobTitle.error")}
                </h5>
              )}
            </div>
            <div className="NewJobForm__input-container">
              <InputField
                type="number"
                placeholder={t(
                  "jobs.addNewJobs.jobDetails.hourlyRate.placeholder",
                )}
                name="hourly_rate"
                value={formik.values.hourly_rate}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="NewJobForm__input"
                textIcon="&#8364;/h"
              />
              {formik.errors.hourly_rate && formik.touched.hourly_rate ? (
                <p className="NewJobForm__input-error">
                  {formik.errors.hourly_rate as string}
                </p>
              ) : (
                <div className="NewJobForm__seat-info">
                  <img
                    src={info}
                    alt="info icon"
                    className="NewJobForm__shift-info-icon"
                  />
                  <div className="NewJobForm__shift-info-text">
                    {t("jobs.addNewJobs.jobDetails.hourlyRate.min")}
                  </div>
                </div>
              )}
            </div>
            <div className="NewJobForm__input-container">
              <InputField
                type="number"
                placeholder={t("jobs.addNewJobs.jobDetails.seats.placeholder")}
                name="seats"
                value={formik.values.seats}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="NewJobForm__input"
                min="1"
                max={
                  subscription ? subscription[0]?.plan?.seats_per_job_limit : ""
                }
              />
              {formik.errors.seats && formik.touched.seats ? (
                <p className="NewJobForm__input-error">
                  {formik.errors.seats as string}
                </p>
              ) : (
                <div className="NewJobForm__seat-info">
                  <img
                    src={info}
                    alt="info icon"
                    className="NewJobForm__shift-info-icon"
                  />
                  <div className="NewJobForm__shift-info-text">
                    {t("jobs.addNewJobs.jobDetails.seats.seatInfo.textOne")}{" "}
                    {seatsAllowed}{" "}
                    {seatsAllowed && seatsAllowed === 1
                      ? t("jobs.addNewJobs.jobDetails.seats.seatInfo.textTwo")
                      : t(
                          "jobs.addNewJobs.jobDetails.seats.seatInfo.textThree",
                        )}
                    <div
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                      className="NewJobForm__upgrade-link"
                    >
                      {t("jobs.addNewJobs.jobDetails.seats.seatInfo.upgrade")}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="NewJobForm__input-container" onMouseOut={() => {}}>
              <SelectBox
                items={businessUnits}
                selectedItem={selectedUnit}
                onItemSelect={(item: any) => {
                  setSelectedUnit(item);
                  setSelectError({
                    name: "Unit",
                    active: false,
                  });
                }}
                placeholder={t(
                  "jobs.addNewJobs.jobDetails.businessUnit.placeholder",
                )}
                onClick={() => {}}
                onBlur={(e: any) => {
                  if (selectedUnit?.name?.length === 0) {
                    setSelectError({ name: "Unit", active: true });
                  }
                }}
                onChange={(e: any) => {}}
              />

              {selectError.active && selectError.name === "Unit" && (
                <h5 className="NewJobForm__input-error">
                  {t("jobs.addNewJobs.jobDetails.businessUnit.error")}
                </h5>
              )}
            </div>
            <div className="NewJobForm__input-container">
              <TextAreaInput
                placeholder={t(
                  "jobs.addNewJobs.jobDetails.description.placeholder",
                )}
                name="description"
                value={formik.values.description}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="NewJobForm__textarea"
              />
              {formik.errors.description && formik.touched.description && (
                <p className="NewJobForm__input-error">
                  {formik.errors.description as string}
                </p>
              )}
            </div>
            <div className="NewJobForm__images-label-wrapper">
              <div className="NewJobForm__images-label">
                {t("jobs.addNewJobs.jobDetails.images.title")}
              </div>
              <img
                src={info}
                alt="info icon"
                className="NewJobForm__info-icon"
              />
              <span className="NewJobForm__images-note">
                {" "}
                {t("jobs.addNewJobs.jobDetails.images.optional")}
              </span>
            </div>
            <PhotoProvider
              maskOpacity={0.7}
              overlayRender={(props) => {
                return (
                  <>
                    <SlideArrowLeft {...props} />
                    <SlideArrowRight {...props} />
                    <CloseButton onClick={props.onClose} />
                  </>
                );
              }}
            >
              <div className="NewJobForm__images">
                {base64Images?.length !== 0 &&
                  base64Images?.map((item: any, index: number) => (
                    <div
                      className="NewJobForm__image-preview-container"
                      key={index}
                    >
                      <PhotoView src={item} key={index}>
                        <img
                          alt="image preview"
                          src={item}
                          className="NewJobForm__image-preview"
                          style={{
                            width: "92px",
                            height: "92px",
                            objectFit: "cover",
                            borderRadius: "6px",
                          }}
                        />
                      </PhotoView>
                      <img
                        alt="delete icon"
                        src={deleteIcon}
                        className="NewJobForm__delete-icon"
                        onClick={() => {
                          const filterItems: any = base64Images?.filter(
                            (image) => image !== item,
                          );
                          setBase64Images(filterItems);
                        }}
                      />
                    </div>
                  ))}
                {base64Images.length < 10 && (
                  <div
                    onClick={() => setIsImageUploaderOpen(true)}
                    className="NewJobForm__images-placeholder"
                  >
                    <img
                      src={closeGray}
                      alt="plus icon"
                      className="NewJobForm__add-icon"
                    />
                  </div>
                )}
              </div>
            </PhotoProvider>
            {isImageUploaderOpen && (
              <MultiFileUploader
                accept="image/*"
                onCancel={() => {
                  setIsImageUploaderOpen(false);
                }}
                onSubmit={(images) => {
                  setBase64Images((prev: any) => [...prev, ...images]);
                  setIsImageUploaderOpen(false);
                  setBase64ImageError(false);
                }}
                allImages={base64Images}
                maxImageSize={10485760}
              />
            )}
          </div>
          <div className="NewJobForm__column">
            <div className="NewJobForm__shift-label">
              {" "}
              {t("jobs.addNewJobs.shifts.title")}
            </div>

            {/*<div className="NewJobForm__banner">*/}
            {/*  <img*/}
            {/*    src={warningIcon}*/}
            {/*    alt="warning icon"*/}
            {/*    className="NewJobForm__warning"*/}
            {/*  />*/}
            {/*  <span> {t("jobs.addNewJobs.shifts.warning")}</span>*/}
            {/*</div>*/}
            {shifts &&
              shifts.map((item: any, index: number) => (
                <div key={index} className="NewJobForm__shift-card">
                  <div className="NewJobForm__shift-card-header">
                    <h2 className="NewJobForm__shift-card-name">
                      {t("jobs.addNewJobs.shifts.shiftCart.title")} {index + 1}
                    </h2>
                    <div className="NewJobForm__shift-card-buttons">
                      <Button
                        onClick={() => {
                          setSelectShift([
                            {
                              id: item?.id,
                              end_time: item?.end_time,
                              start_time: item?.start_time,
                            },
                          ]);
                          setEditShift(true);
                          setNewShift(false);
                        }}
                        className="NewJobForm__edit-shift-button"
                        label={t("jobs.addNewJobs.shifts.shiftCart.edit")}
                        disabled={editShift}
                      />
                      <Button
                        onClick={() => {
                          setOpenDeleteModal(true);
                        }}
                        className="NewJobForm__edit-shift-button"
                        label={t("jobs.addNewJobs.shifts.shiftCart.delete")}
                        disabled={editShift}
                      />
                    </div>
                    {openDeleteModal && (
                      <DeleteModal
                        name={`${t("jobs.addNewJobs.shifts.shiftCart.title")} ${index + 1}`}
                        onCancel={() => setOpenDeleteModal(false)}
                        onSubmit={() => {
                          const filterShift: any = shifts.filter(
                            (shift: any) => shift?.id !== item.id,
                          );
                          setShifts(filterShift);
                          setOpenDeleteModal(false);
                        }}
                      />
                    )}
                  </div>
                  <div className="NewJobForm__shift-card-body">
                    <div>
                      <div className="NewJobForm__shift-card-start">
                        {" "}
                        {`${t("jobs.addNewJobs.shifts.shiftCart.start")}:   ${item?.start_time.toLocaleString("en-GB", { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" })}`}
                      </div>
                      <div className="NewJobForm__shift-card-end">
                        {" "}
                        {`${t("jobs.addNewJobs.shifts.shiftCart.end")}:   ${item?.end_time.toLocaleString("en-GB", { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" })}`}
                      </div>
                    </div>
                    <div className="NewJobForm__shift-item">
                      {convertMinsToHrsMins(
                        getTimeBetweenDates(
                          new Date(item.start_time),
                          new Date(item.end_time),
                        ).minutes,
                      )}
                    </div>
                  </div>
                </div>
              ))}

            {newShift && (
              <AddNewShift
                setShifts={setShifts}
                shifts={shifts}
                setNewShift={() => setNewShift(false)}
              />
            )}

            {editShift && (
              <EditShift
                selectShift={selectShift}
                setEditShift={() => {
                  setEditShift(false);
                  setSelectShift([]);
                }}
                edit={editShift}
                setShifts={setShifts}
                shifts={shifts}
              />
            )}
            <div className="NewJobForm__shift-button-container">
              <div
                className={cs(
                  "NewJobForm__shift-placeholder",
                  (newShift || subscription
                    ? subscription[0]?.plan?.shifts_per_job_limit ===
                      shifts.length
                    : "") && "NewJobForm__shift-placeholder--disabled",
                )}
                onClick={() => {
                  if (
                    !newShift &&
                    (subscription
                      ? subscription[0]?.plan?.shifts_per_job_limit >
                        shifts?.length
                      : "")
                  ) {
                    setNewShift(true);
                    setEditShift(false);
                  }
                }}
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="NewJobForm__add-icon"
                >
                  <defs>
                    <linearGradient
                      id="myGradient"
                      gradientTransform="rotate(188.55)"
                    >
                      <stop
                        offset="104.56%"
                        style={{ stopColor: "#030ac0", stopOpacity: 0.8 }}
                      />
                      <stop
                        offset="6.65%"
                        style={{ stopColor: "#aee7ff", stopOpacity: 0.8 }}
                      />
                    </linearGradient>
                  </defs>
                  <path
                    d="M19.2205 1.64844L1.55806 19.3167"
                    stroke="url(#myGradient)"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                  <path
                    d="M1.55884 1.64844L19.2212 19.3167"
                    stroke="url(#myGradient)"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                </svg>
                <span className="NewJobForm__shift-text">
                  {t("jobs.addNewJobs.shifts.addShiftButton.title")}{" "}
                </span>
              </div>
              <div className="NewJobForm__shift-info">
                <img
                  src={info}
                  alt="info icon"
                  className="NewJobForm__shift-info-icon"
                />
                <div className="NewJobForm__shift-info-text">
                  {t("jobs.addNewJobs.shifts.addShiftButton.descriptionOne")}{" "}
                  {shiftsAllowed}{" "}
                  {shiftsAllowed && shiftsAllowed === 1
                    ? t("jobs.addNewJobs.shifts.addShiftButton.descriptionTwo")
                    : t(
                        "jobs.addNewJobs.shifts.addShiftButton.descriptionThree",
                      )}
                  <div
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                    className="NewJobForm__upgrade-link"
                  >
                    {t("jobs.addNewJobs.shifts.addShiftButton.upgrade")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Button
          label={t("jobs.addNewJobs.completeButton")}
          onClick={() => {
            formik.handleSubmit();
          }}
          className="NewJobForm__button"
          type="submit"
          disabled={
            shifts?.length === 0 ||
            !!formik.errors.description ||
            !!formik.errors.hourly_rate ||
            !!formik.errors.seats ||
            !!selectError.active ||
            isButtonLoading ||
            editShift ||
            !formik.values.description ||
            !formik.values.hourly_rate ||
            !formik.values.seats ||
            selectedJob.length === 0 ||
            selectedUnit?.name.length === 0
          }
          isLoading={isButtonLoading}
        />
      </div>
    </>
  );
};

export default NewJobForm;
