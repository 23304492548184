import React, { useEffect, useState } from "react";

import downloadIcon from "../../../assets/svg/download-icon.svg";
import verifiedIcon from "../../../assets/svg/verified-icon.svg";
import companyAvatar from "../../../assets/images/company-avatar.png";
import { useTranslation } from "react-i18next";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import { apiCall } from "../../../api/Api";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { useNavigate } from "react-router-dom";

import "./CompanySettings.scss";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";

const CompanySettings = () => {
  const [companyData, setCompanyData] = useState<any | null>();
  const { t } = useTranslation("common");
  const isMobile = useResponsiveDimensions().isMobile;
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    apiCall("GET", "b2b/organization/")
      .then((response) => {
        setCompanyData(response.data);
      })
      .catch((error) => {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
        setCompanyData(null);
      });
  }, []);

  return (
    <div className="CompanySettingsContainer">
      <div className="CompanySettingsContainer__head">
        <p className="CompanySettingsContainer__head-title">
          {t("company.title")}
        </p>
      </div>
      <div className="CompanySettingsContainer__body">
        {companyData === undefined ? (
          <div className="CompanySettings__loader">
            <LoadingComponent />
          </div>
        ) : companyData === null ? (
          <div className="CompanySettings__noData">{t("noData")}</div>
        ) : (
          <div className="CompanySettings">
            <div className="CompanySettings__head">
              <p className="CompanySettings__title">
                {t("company.companySettings.title")}
              </p>
              <div className="CompanySettings__buttons">
                {companyData?.business_certificate_url !== null && (
                  <div
                    className="CompanySettings__certificate"
                    onClick={() => {
                      window.open(
                        companyData?.business_certificate_url,
                        "_blank",
                      );
                    }}
                  >
                    <h6 className="CompanySettings__certificate-text">
                      {t("company.companySettings.certificate")}
                    </h6>
                    <img
                      alt="pic"
                      src={downloadIcon}
                      className="CompanySettings__certificate-icon"
                    />
                  </div>
                )}

                <h6
                  className="CompanySettings__edit"
                  onClick={() => {
                    navigate("/company/edit-company-settings");
                  }}
                >
                  {t("company.companySettings.edit")}
                </h6>
              </div>
            </div>
            <div className="CompanySettings__body">
              <div className="CompanySettings__form">
                <div className="CompanySettings__logo">
                  <h6 className="CompanySettings__logo-title">
                    {t("company.companySettings.companyLogo")}
                  </h6>
                  <img
                    alt="pic"
                    className="CompanySettings__logo-image"
                    src={companyData?.logo_url || companyAvatar}
                  />
                </div>
                <div className="CompanySettings__details">
                  <div className="CompanySettings__detail">
                    <h6 className="CompanySettings__detail-title">
                      {t("company.companySettings.companyName")}
                    </h6>
                    <h5 className="CompanySettings__detail-value">
                      {companyData?.name}
                      {companyData?.is_verified && (
                        <img
                          alt="pic"
                          className="CompanySettings__detail-verification"
                          src={verifiedIcon}
                        />
                      )}
                    </h5>
                  </div>{" "}
                  <div className="CompanySettings__detail">
                    <h6 className="CompanySettings__detail-title">
                      {t("company.companySettings.companyIdentificationNumber")}
                    </h6>
                    <h5 className="CompanySettings__detail-value">
                      {companyData?.unique_business_identifier}
                    </h5>
                  </div>{" "}
                  <div className="CompanySettings__detail">
                    <h6 className="CompanySettings__detail-title">
                      {t("company.companySettings.country")}
                    </h6>
                    <h5 className="CompanySettings__detail-value">
                      {companyData?.country}
                    </h5>
                  </div>{" "}
                  <div className="CompanySettings__detail">
                    <h6 className="CompanySettings__detail-title">
                      {t("company.companySettings.city")}
                    </h6>
                    <h5 className="CompanySettings__detail-value">
                      {companyData?.city}
                    </h5>
                  </div>
                  <div
                    className="CompanySettings__detail"
                    style={isMobile ? undefined : { gridColumn: "1/3" }}
                  >
                    <h6 className="CompanySettings__detail-title">
                      {t("company.companySettings.about")}
                    </h6>
                    <h5 className="CompanySettings__detail-value">
                      {companyData?.description}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanySettings;
