import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import Button from "../Button/Button";

import "./FileUploader.scss";

interface FileUploaderProps {
  onCancel: () => void;
  onSubmit: (fileName: string, base64: string) => void;
  accept?: string;
  maxFileSizeInBytes?: number;
}
const FileUploader = (props: FileUploaderProps) => {
  const { onCancel, onSubmit, accept, maxFileSizeInBytes } = props;
  const [error, setError] = useState<string | null>(null);
  const [base64String, setBase64String] = useState<
    string | ArrayBuffer | null
  >();
  const [fileName, setFileName] = useState("");
  const { t } = useTranslation("common");

  const handleChange = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file) {

      if(maxFileSizeInBytes){
        if (file.size > maxFileSizeInBytes) {
          const megaBytesFullNumber = maxFileSizeInBytes / (1024 * 1024)
          const megaBytes = megaBytesFullNumber.toFixed(0)
  
          setError(accept?.includes("image") ? t("fileUploader.errorImage") + ` ${megaBytes} MB` : t("fileUploader.errorFile")  + ` ${megaBytes} MB`);
          setFileName("");
          setBase64String(null);
          return;
        } else {
          setError(null);
        }
      }

      setFileName(file.name);
    }
    reader.onloadend = () => {
      setBase64String(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="FileUploader">
      <div className="FileUploader__input-container">
        <label className="FileUploader__input-label">
          {accept?.includes("image") ? t("fileUploader.imageLabel") : t("fileUploader.fileLabel")}
        </label>
        <input
          className="FileUploader__input"
          type="file"
          accept={accept}
          onChange={(e) => {
            handleChange(e);
          }}
        />

        {error && <div className="FileUploader__input-error">{error}</div>}
      </div>

      <div className="FileUploader__buttons">
        <Button
          type="reset"
          style={{ height: "45px", marginRight: "5px" }}
          className="Button__secondary"
          label={t("fileUploader.leftButton")}
          onClick={() => {
            onCancel();
          }}
        />
        <Button
          type="submit"
          style={{ height: "45px", marginLeft: "5px" }}
          label={t("fileUploader.rightButton")}
          onClick={() => {
            if (error === null) {
              onSubmit(fileName, base64String as string);
            }
          }}
        />
      </div>
    </div>
  );
};

export default FileUploader;
