import React, { useState } from "react";

import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";

import "./PrivateRoutesLayout.scss";
import MessageModal from "../../components/shared/MessageModal/MessageModal";

interface LayoutPropsType {
  children: React.ReactNode;
}
const PrivateRoutesLayout = (props: LayoutPropsType) => {
  const { children } = props;
  const isMobile = useResponsiveDimensions().isMobile;
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);

  return (
    <div className="PrivateRoutesLayout">
      <MessageModal />
      <Header
        openSidebar={() => {
          if (isMobile) {
            setIsSidebarOpen(true);
          }
        }}
      />

      {isSidebarOpen && (
        <Sidebar
          closeSidebar={() => {
            if (isMobile) {
              setIsSidebarOpen(false);
            }
          }}
        />
      )}

      <div className="PrivateRoutesLayout__container">
        <div className="PrivateRoutesLayout__routes">{children}</div>
      </div>
    </div>
  );
};

export default PrivateRoutesLayout;
