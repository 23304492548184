import React, { useEffect, useState } from "react";

import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { apiCall } from "../../../api/Api";
import { useEditUnitFormik } from "./useEditUnitFormik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Mapbox, { LocationType } from "../../../components/shared/Mapbox/Mapbox";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";
import useGetParameterByName from "../../../lib/hooks/useGetParameterByName";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import markerIcon from "../../../assets/svg/marker.svg";
import chevronDown from "../../../assets/svg/chevron-down-gray.svg";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";

import "./EditUnit.scss";

const EditUnit = () => {
  const [unit, setUnit] = useState<any | null>();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [location, setLocation] = useState<LocationType | null>();
  const [locationError, setLocationError] = useState(false);
  const [locationRequiredError, setLocationRequiredError] = useState(false);
  const [locationStreetRequiredError, setLocationStreetRequiredError] =
    useState(false);
  const { t } = useTranslation("common");
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();
  const unitId = useGetParameterByName("unit_id");

  useEffect(() => {
    apiCall("GET", `b2b/organization-unit/${unitId}/`)
      .then((res) => {
        setUnit(res.data);
      })
      .catch((error) => {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
        setUnit(null);
      });
  }, []);

  const formik = useEditUnitFormik({
    onSubmit: async (values: any) => {
      if (location === undefined) {
        setLocationRequiredError(true);
        return;
      }
      if (location === null) {
        setLocationError(true);
        return;
      }
      if (location.street === null) {
        setLocationStreetRequiredError(true);
        return;
      }

      try {
        setIsButtonLoading(true);
        const data = {
          name: values.unitName,
          phone: values.phoneNumber,
          longitude: location.longitude,
          latitude: location.latitude,
          city: location.city,
          address: location.full_address,
        };

        await apiCall("PUT", `b2b/organization-unit/${unit.id}/`, data);

        globalCtx.openMessageModal(
          "success",
          t("staticMessage.unitUpdatedSuccess"),
        );

        setTimeout(() => {
          navigate("/units");
        }, 3000);
      } catch (error: any) {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
      } finally {
        setIsButtonLoading(false);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("unitName", unit?.name || "");
    formik.setFieldValue("phoneNumber", unit?.phone || "");
  }, [unit]);

  useEffect(() => {
    if (location !== undefined) {
      setLocationRequiredError(false);

      if (location !== null) {
        setLocationError(false);

        if (location.street !== null) {
          setLocationStreetRequiredError(false);
        }
      }
    }
  }, [location]);

  return (
    <div className="EditUnitContainer">
      <div className="EditUnitContainer__head">
        <p className="EditUnitContainer__head-title">{t("units.title")}</p>
      </div>
      <div className="EditUnitContainer__body">
        {unit === undefined ? (
          <div className="EditUnit__loader">
            <LoadingComponent />
          </div>
        ) : unit === null ? (
          <div className="EditUnitContainer__noData">{t("noData")}</div>
        ) : (
          <div className="EditUnit">
            <div className="EditUnit__header">
              <p className="EditUnit__title">
                <img
                  alt="pic"
                  className="EditUnit__title-back"
                  src={chevronDown}
                  onClick={() => {
                    navigate("/units");
                  }}
                />
                {t("units.editUnit.title")}
                <span>({unit.name})</span>
              </p>
            </div>
            <div className="EditUnit__body">
              <div className="EditUnit__form">
                <div className="EditUnit__inputs">
                  <div className="EditUnit__input">
                    <InputField
                      type="text"
                      placeholder={t("units.editUnit.unitName.placeholder")}
                      name="unitName"
                      value={formik.values.unitName}
                      onBlur={(e: any) => formik.handleBlur(e)}
                      onChange={(e: any) => formik.handleChange(e)}
                    />
                    {formik.errors.unitName && formik.touched.unitName && (
                      <h5 className="EditUnit__input-error">
                        {formik.errors.unitName as string}
                      </h5>
                    )}
                  </div>{" "}
                  <div className="EditUnit__input">
                    <InputField
                      type="text"
                      placeholder={`${t("units.editUnit.phoneNumber.placeholder")} (+38349888497)`}
                      name="phoneNumber"
                      value={formik.values.phoneNumber}
                      onBlur={(e: any) => formik.handleBlur(e)}
                      onChange={(e: any) => formik.handleChange(e)}
                    />
                    {formik.errors.phoneNumber &&
                      formik.touched.phoneNumber && (
                        <h5 className="EditUnit__input-error">
                          {formik.errors.phoneNumber as string}
                        </h5>
                      )}
                  </div>
                </div>
                <div className="EditUnit__map">
                  <Mapbox
                    locationData={(location) => {
                      setLocation(location);
                    }}
                    longitudeData={unit.longitude}
                    latitudeData={unit.latitude}
                  />
                </div>
                <div className="EditUnit__map-description">
                  {t("mapComponent.descriptionOne")}{" "}
                  <img alt="pic" width="10px" src={markerIcon} />{" "}
                  {t("mapComponent.descriptionTwo")}
                </div>
                {locationError && (
                  <h5 className="EditUnit__map-error">
                    {t("mapComponent.error")}
                  </h5>
                )}{" "}
                {locationRequiredError && (
                  <h5 className="EditUnit__map-error">
                    {t("mapComponent.errorRequired")}
                  </h5>
                )}{" "}
                {locationStreetRequiredError && (
                  <h5 className="EditUnit__map-error">
                    {t("mapComponent.errorStreetRequired")}
                  </h5>
                )}
                <div className="EditUnit__footer">
                  <Button
                    isLoading={isButtonLoading}
                    type="submit"
                    className="EditUnit__footer-button"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    label={t("units.editUnit.button")}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditUnit;
