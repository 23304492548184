import React from "react";

import "./Checkbox.scss";

interface CheckboxPropsType {
  isChecked: boolean;
  onCheckboxClick: () => void;
}

const Checkbox = (props: CheckboxPropsType) => {
  const { isChecked, onCheckboxClick } = props;

  return (
    <div
      className="Checkbox"
      onClick={() => {
        onCheckboxClick();
      }}
    >
      {isChecked && <div className="Checkbox__checkmark" />}
    </div>
  );
};

export default Checkbox;
