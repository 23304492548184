import { createContext, useContext } from "react";
import { User } from "firebase/auth";

export interface AuthContextType {
  currentUser: User | null | undefined;
  localUserProfile: any;
  localOrganization: any;
  setDataToLocalStorage: (userProfile: any, organization: any) => void;
  signOutFirebaseUser: () => void;
}

const AuthContextValues: AuthContextType = {
  currentUser: null,
  localUserProfile: null,
  localOrganization: null,
  setDataToLocalStorage: () => {},
  signOutFirebaseUser: () => {},
};

export const AuthContext = createContext<AuthContextType>(AuthContextValues);
export const useAuthContext = () => useContext(AuthContext);
