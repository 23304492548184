import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import closeIcon from "../../../../assets/svg/close-white.svg";
import cs from "classnames";

import "./PlanInfo.scss";

interface PlanInfoProps {
  activePlan: any;
  onCloseClick: () => void;
}

const PlanInfo = (props: PlanInfoProps) => {
  const { activePlan, onCloseClick } = props;
  const [formattedStartDate, setFormattedStartDate] = React.useState<
    string | null
  >(null);
  const [formattedEndDate, setFormattedEndDate] = React.useState<string | null>(
    null,
  );
  const [formattedNextPlanStartDate, setFormattedNextPlanStartDate] =
    React.useState<string | null>(null);
  const [nextInvoiceAmount, setNextInvoiceAmount] = useState("");

  const { t } = useTranslation("common");

  useEffect(() => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    //START DATE
    const startDateTimestamp =
      activePlan.stripe_subscription.current_period_start;
    const startDate = new Date(startDateTimestamp * 1000); // Convert to milliseconds
    const formattedStartDate = startDate.toLocaleString(
      "en-US",
      options as any,
    );
    setFormattedStartDate(formattedStartDate);

    //END DATE
    const endDateTimestamp = activePlan.stripe_subscription.current_period_end;
    const endDate = new Date(endDateTimestamp * 1000); // Convert to milliseconds
    const formattedEndDate = endDate.toLocaleString("en-US", options as any);
    setFormattedEndDate(formattedEndDate);

    //NEXT PLAN START DATE
    const nextPlanStartDateTimestamp = activePlan.next_invoice_date;
    const nextPlanStartDate = new Date(nextPlanStartDateTimestamp * 1000); // Convert to milliseconds
    const formattedNextPlanStartDate = nextPlanStartDate.toLocaleString(
      "en-US",
      options as any,
    );
    setFormattedNextPlanStartDate(formattedNextPlanStartDate);

    //NEXT INVOICE AMOUNT PARSE
    const amount = activePlan.next_invoice_amount;
    const formattedAmount = (amount / 100).toFixed(2);
    setNextInvoiceAmount(formattedAmount);
  }, []);

  return (
    <div className="PlanInfo">
      <div
        className={cs(
          "PlanInfo__header",
          activePlan.plan.name === "Free" && "PlanInfo__header-free",
          activePlan.plan.name === "Pro" && "PlanInfo__header-pro",
          activePlan.plan.name === "Business +" &&
            "PlanInfo__header-businessPlus",
          activePlan.plan.name === "Enterprise" &&
            "PlanInfo__header-enterprise",
        )}
      >
        <div>
          <p className="PlanInfo__title">{activePlan.plan.name}</p>
          <p className="PlanInfo__subtitle">
            {t("subscription.subscriptionPlans.planInfoModal.subtitle")}
          </p>
        </div>
        <img
          alt="pic"
          className="PlanInfo__close"
          src={closeIcon}
          onClick={() => {
            onCloseClick();
          }}
        />
      </div>
      <div className="PlanInfo__body">
        <div className="PlanInfo__list">
          <p className="PlanInfo__list-title">
            {t("subscription.subscriptionPlans.planInfoModal.listTitle")}{" "}
          </p>
          <p className="PlanInfo__list-subtitle">
            {t(
              "subscription.subscriptionPlans.planInfoModal.listSubTitleActive",
            )}{" "}
            <span>( {activePlan.plan.name} )</span>
          </p>
          <p className="PlanInfo__list-item">
            {t("subscription.subscriptionPlans.planInfoModal.started")}:{" "}
            <span>{formattedStartDate}</span>
          </p>
          <p className="PlanInfo__list-item">
            {t("subscription.subscriptionPlans.planInfoModal.price")}:{" "}
            <span>{activePlan.plan.price} €</span>
          </p>
          {activePlan.next_plan_name !== null && (
            <>
              <p className="PlanInfo__list-subtitle">
                {t(
                  "subscription.subscriptionPlans.planInfoModal.listSubTitleUpcoming",
                )}{" "}
                <span>( {activePlan.next_plan_name} )</span>
              </p>
              <p className="PlanInfo__list-item">
                {t("subscription.subscriptionPlans.planInfoModal.from")}:{" "}
                <span>{formattedNextPlanStartDate}</span>
              </p>
              <p className="PlanInfo__list-item">
                {t("subscription.subscriptionPlans.planInfoModal.price")}:{" "}
                <span>{nextInvoiceAmount} €</span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanInfo;
