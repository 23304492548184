import React from "react";
import person from "../../../assets/svg/person.svg";
import clock from "../../../assets/svg/clock.svg";
import { getTimeBetweenDates } from "../../../lib/helpers/getTimeBetweenDates";
import { useLocation, useNavigate } from "react-router-dom";
import { convertMinsToHrsMins } from "../../../lib/helpers/convertMinsToHrsMins";
import { useTranslation } from "react-i18next";
import Button from "../../../components/shared/Button/Button";
import cs from "classnames";
import Tooltip from "@mui/material/Tooltip";
import dayjs from 'dayjs';
import { Countdown } from "../../../components/shared/Countdown/Countdown";

import "./JobCard.scss";

const JobCard = (props: any) => {
  const { job, shiftTotal, jobStatus, subStatus } = props;
  let selectedLanguage = localStorage.getItem("lang");
  let today = new Date();
  let tomorrow = today;
  tomorrow.setDate(tomorrow.getDate() + 1);
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const locationPathname = useLocation().pathname;

  const showIcon =
    job.status === "assigned" &&
    job.clocked_in_count === 0 &&
    new Date(job.job_start_time).getTime() < new Date().getTime() &&
    job.clocked_in_count !== job.my_jobs_count;

  
  const maxAvailableTime = dayjs(job?.job_start_time).subtract(12, "hour");
  const currentTime = dayjs();
  const diff = maxAvailableTime?.diff(currentTime,'day',true);
  const days = Math.floor(diff);
  //const hours = Math.floor((diff - days) * 24);

  return (
    <section className="JobCard">
      <Tooltip title={`${selectedLanguage === "SQ" ? `Numri i punëtorëve të nevojshëm: ${job.seats}` : `Number of workers needed: ${job.seats}`}`}
       placement="top"
      >
      <div className="JobCard__header">
        <h2 className="JobCard__job-title">{selectedLanguage === "SQ" ? `(${job.seats}) ${job.job_title.title?.sq}` : `(${job.seats}) ${job.job_title.title?.en}`}</h2>
        <div className="JobCard__job-price">{job.pay_rate}&euro;/h</div>
      </div>
      </Tooltip>
      <div className="JobCard__body">
        <div className="JobCard__city">{job.organization_unit?.city}</div>
        {job.status === "assigned" ||
        job.status === "in_progress" ||
        job.status === "did_not_attend" ? (
          <div className="JobCard__card-total flex">
            {job.status === "assigned" && (
              <span className="JobCard__job-status--assigned">
                {t("jobs.jobCard.jobStatus.assigned")}
              </span>
            )}
            {job.status === "in_progress" &&
              job?.clocked_in_count === job?.my_jobs_count && (
                <span className="JobCard__job-status--in-progress">
                  {t("jobs.jobCard.jobStatus.inProgress")}
                </span>
              )}
            {job.status === "in_progress" &&
              job?.clocked_in_count !== job?.my_jobs_count && (
                <span className="JobCard__job-status--in-progress-yellow">
                  {t("jobs.jobCard.jobStatus.inProgress")}
                </span>
              )}
            {job.status === "did_not_attend" && (
              <span className="JobCard__job-status--did_not_attend">
                {t("jobs.jobCard.jobStatus.didNotAttend")}
              </span>
            )}
          </div>
        ) : null}
        {jobStatus === "Upcoming" && job?.status === "available" && (
          <div className={cs("JobCard__upcoming", getTimeBetweenDates(new Date(), new Date(job.job_start_time)).days < 0 && "JobCard__upcoming--started")}>
            {
            getTimeBetweenDates(new Date(), new Date(job.job_start_time)).days >= 0 ? (
            getTimeBetweenDates(new Date(), new Date(job.job_start_time))
              .days === 0
              ? t("jobs.jobCard.upcomingTextOne")
              : getTimeBetweenDates(new Date(), new Date(job.job_start_time))
                    .days === 1
                ? t("jobs.jobCard.upcomingTextTwo")
                : `${t("jobs.jobCard.upcomingTextThree")} ${getTimeBetweenDates(new Date(), new Date(job.job_start_time)).days} ${t("jobs.jobCard.upcomingTextFour")}`
            ) : (
              `${selectedLanguage === "SQ" ? "Puna tashmë ka filluar" : "Job has already started"}`
            )
              }
          </div>
        )}
        {job.status === "completed" && (
          <div className="JobCard__card-total flex">
            <span className="JobCard__job-status--completed">
              {t("jobs.jobCard.completed")}
            </span>
          </div>
        )}
      </div>
      {job.shifts.length > 1 && (
        <div className="JobCard__shift-card-body-another-shift" />
      )}
      <div className="JobCard__shift-card-body">
        <div>
          <div className="JobCard__shift-card-start">
            {" "}
            {`${daysOfTheWeek[new Date(job.shifts[0]?.start_time).getDay()]} ${new Date(job.shifts[0]?.start_time).toLocaleString("en-GB", { month: "short", day: "numeric" })}`}
            {new Date(job.shifts[0]?.end_time).toLocaleString("en-GB", {
              month: "short",
              day: "numeric",
            }) !==
            new Date(job.shifts[0]?.start_time).toLocaleString("en-GB", {
              month: "short",
              day: "numeric",
            })
              ? ` - ${daysOfTheWeek[new Date(job.shifts[0]?.end_time).getDay()]} ${new Date(job.shifts[0]?.end_time).toLocaleString("en-GB", { month: "short", day: "numeric" })} `
              : ""}
          </div>

          <div className="NewJobForm__shift-card-end">
            {`${new Date(job.shifts[0]?.start_time).toLocaleString("en-GB", { hour: "numeric", minute: "numeric" })} ${t("jobs.jobCard.to")} ${new Date(job.shifts[0]?.end_time).toLocaleString("en-GB", { hour: "numeric", minute: "numeric" })} `}
          </div>
        </div>
        {shiftTotal && (
          <div className="JobCard__shift-card-total">
            {" "}
            {convertMinsToHrsMins(
              getTimeBetweenDates(
                new Date(job.shifts[0]?.start_time),
                new Date(job.shifts[0]?.end_time),
              ).minutes,
            )}{" "}
            total
          </div>
        )}
      </div>
      <div className="JobCard__applicants-wrapper">
        <div className="JobCard__applicants">
          <img src={person} alt="person icon" className="JobCard__person-img" />
          {job.status !== "available" ? (
            <p>
              {job.my_jobs_count === 0
                ? t(
                    locationPathname === "/jobs/upcoming-not-assigned"
                      ? "jobs.jobCard.applicants.textOne"
                      : "jobs.jobCard.workers.textOne",
                  )
                : `${job.my_jobs_count} ${t(locationPathname === "/jobs/upcoming-not-assigned" ? "jobs.jobCard.applicants.textTwo" : "jobs.jobCard.workers.textTwo")}`}
            </p>
          ) : (
            <p>
              {job.applications_count === 0
                && t(
                    locationPathname === "/jobs/upcoming-not-assigned"
                      ? "jobs.jobCard.applicants.textOne"
                      : "jobs.jobCard.workers.textOne",
                  )
               } 
            </p>
          )}
       
         {job.status === "available" && job.applications_count !== 0 && (
              <p className="JobCard__applicants-text">
               {job.applications_count} {t("jobs.jobCard.applicants.textTwo")}
             </p>
          )}
        </div>
        {job.status === "available" && job.applications_count !== 0 &&  (
          <Tooltip
           title={((days < 0)) ? `${selectedLanguage === "SQ" ? "Nuk mund të pranoni asnjë kandidat për këtë vend pune" : "You cannot accept any candidates for this job"}` : `${selectedLanguage === "SQ" ? "Ju keni edhe kaq kohë për të pranuar kandidatët për këtë punë" : "You still have this much time to accept candidates for this job"}`}
           placement="top"
          >
           <div className="JobCard__available-job-hours-count">
              <img src={clock} alt="clock icon" className="JobCard__clock-icon" />
              <span>   
              <Countdown endTime={maxAvailableTime} />
            </span>
          </div>
         </Tooltip>
          )}
        {job.status !== "available" && (
        <div className="flex">
          {showIcon && (
            <Tooltip title={t("jobs.jobCard.toolTip")} placement="top">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 16 16"
                fill="none"
                className="JobCard__status-icon"
              >
                <circle cx="7.53" cy="7.53" r="7.53" fill="#DD291E" />
                <path
                  d="M4.12695 7.50977H10.9314"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </Tooltip>
          )}
          {job.status !== "available" && (
            <div className="JobCard__applicants-status">
              {t("jobs.jobCard.clockIn")}:
              <span
                className={cs(
                  "JobCard__clock-in-status",
                  job.status === "assigned" &&
                    "JobCard__clock-in-status--assigned",
                  job.status === "completed" &&
                    "JobCard__clock-in-status--completed",
                  job.status === "in_progress" &&
                    job?.clocked_in_count === job?.my_jobs_count &&
                    "JobCard__clock-in-status--in-progress",
                  job.status === "in_progress" &&
                    job?.clocked_in_count !== job?.my_jobs_count &&
                    "JobCard__clock-in-status--in-progress-yellow",
                  job.status === "did_not_attend" &&
                    "JobCard__clock-in-status--did_not_attend",
                )}
              >
                {job.clocked_in_count}/{job.my_jobs_count}{" "}
              </span>
            </div>
          )}
        </div>
       )}

      </div>
      {job.status === "available" && job?.applications_count === 0 &&  (
        <Button
          onClick={() => {
            navigate("/jobs/edit-job");
            localStorage.setItem("selectedJob", JSON.stringify(job));
            localStorage.setItem("jobStatus", jobStatus);
          }}
          className="JobCard__edit-button"
          label={t("jobs.jobCard.edit")}
        />
      )}
      {job.status === "available" && job?.applications_count > 0 && (
        <Button
          onClick={() => {
            navigate("/jobs/edit-job");
            localStorage.setItem("selectedJob", JSON.stringify(job));
            localStorage.setItem("jobStatus", jobStatus);
          }}
          className="JobCard__edit-button"
          label={t("jobs.jobCard.view")}
        />
      )}
      <Button
        onClick={() => {
          if (jobStatus === "Upcoming" && job.status === "available") {
            navigate("/jobs/applicants");
            localStorage.setItem("selectedJob", JSON.stringify(job));
            localStorage.setItem("jobStatus", jobStatus);
          } else {
            navigate("/jobs/attendance", {
              state: { previousLocation: subStatus },
            });
            localStorage.setItem("selectedJob", JSON.stringify(job));
            localStorage.setItem("jobStatus", jobStatus);
          }
        }}
        className={cs(
          "JobCard__show-btn",
          job?.status !== "available" && "JobCard__show-btn--center",
        )}
        label={t(
          locationPathname === "/jobs/upcoming-not-assigned"
            ? "jobs.jobCard.show"
            : "jobs.jobCard.showWorkers",
        )}
      />
    </section>
  );
};

export default JobCard;
