export const convertCityName = (cityName: string | null) => {
  if (cityName === null) {
    return null;
  }
  switch (cityName) {
    case "Komuna e Deçanit":
      return "Deçan";
    case "Gjakovë":
      return "Gjakovë";
    case "Drenasi":
      return "Gllogoc";
    case "Gjilani":
      return "Gjilan";
    case "Sharri":
      return "Dragash";
    case "Burimi":
      return "Istog";
    case "Kaçaniku":
      return "Kaçanik";
    case "Klina":
      return "Klinë";
    case "Fushë Kosova":
      return "Fushë Kosovë";
    case "Dardana":
      return "Kamenicë";
    case "Mitrovica":
      return "Mitrovicë";
    case "Albaniku":
      return "Leposaviq";
    case "Lipjani":
      return "Lipjan";
    case "Komuna e Artanës":
      return "Novobërdë";
    case "Kastrioti":
      return "Obiliq";
    case "Rahoveci":
      return "Rahovec";
    case "Peja":
      return "Pejë";
    case "Besiana":
      return "Podujevë";
    case "Prishtina":
      return "Prishtina";
    case "Prizreni":
      return "Prizren";
    case "Skënderaj":
      return "Skenderaj";
    case "Shtimja":
      return "Shtime";
    case "Shtërpca":
      return "Shtërpcë";
    case "Theranda":
      return "Suharekë";
    case "Ferizaj":
      return "Ferizaj";
    case "Vitia":
      return "Viti";
    case "Vushtrria":
      return "Vushtrri";
    case "Zubin Potoku":
      return "Zubin Potok";
    case "Zveçani":
      return "Zveçan";
    case "Malisheva":
      return "Malishevë";
    case "Komuna e Hanit të Elezit":
      return "Hani i Elezit";
    case "Mamushë":
      return "Mamushë";
    case "Juniku":
      return "Junik";
    case "Graçanicë":
      return "Graçanicë";
    case "Ranillug":
      return "Ranillug";
    case "Partesh":
      return "Partesh";
    case "Komuna e Kllokotit":
      return "Kllokot";
    default:
      return cityName;
  }
};
