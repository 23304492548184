import React from "react";

import { Outlet, Navigate, useLocation } from "react-router-dom";
import PrivateRoutesLayout from "../layout/PrivateRoutesLayout/PrivateRoutesLayout";

interface PrivateRoutesContainerProps {
  isAuth: boolean;
}
const PrivateRoutesContainer = (props: PrivateRoutesContainerProps) => {
  const { isAuth } = props;
  const location = useLocation();

  return (
    <>
      {isAuth ? (
        <PrivateRoutesLayout>
          <Outlet />
        </PrivateRoutesLayout>
      ) : (
        <Navigate
          to="/sign-up"
          replace
          state={{ pathBeforeLogin: location.pathname }}
        />
      )}
    </>
  );
};
export default PrivateRoutesContainer;
