import React from "react";
import SimpleModal from "../SimpleModal/SimpleModal";
import { useTranslation } from "react-i18next";
import "./ContactModal.scss";

interface ContactModalProps {
  phoneNumber: string;
  onClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({ phoneNumber, onClose }) => {
  const { t } = useTranslation("common");

  return (
    <SimpleModal onClose={onClose}>
      <h2>{t("contactModal.title")}</h2>
      <div className="ContactModal__options">
        <div
          className="ContactModal__option"
          onClick={() => window.open(`tel:${phoneNumber}`, "_self")}
        >
          <i className="fa fa-phone ContactModal__icon" aria-hidden="true"></i>
          {t("contactModal.phone")}
        </div>
        <div
          className="ContactModal__option"
          onClick={() => window.open(`https://wa.me/${phoneNumber}`, "_blank")}
        >
          <i className="fa fa-whatsapp ContactModal__icon" aria-hidden="true" style={{ color: "#25D366" }}></i>
          {t("contactModal.whatsapp")}
        </div>
      </div>
    </SimpleModal>
  );
};

export default ContactModal;
