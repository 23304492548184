import React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import LanguageDropdown from "../../components/shared/LanguageDropdown/LanguageDropdown";
import SignInForm from "./SignInForm/SignInForm";
import briefCase from "../../assets/svg/briefcase-colored.svg";
import peakJobsLogo from "../../assets/svg/peak-jobs-logo.svg";

import cs from "classnames";

import "./SignIn.scss";

const SignIn = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const isMobile = useResponsiveDimensions().isMobile;

  return (
    <div className="SignIn">
      <div className={cs("SignIn__left", isMobile && "SignIn__left-mobile")}>
        <div className="SignIn__left-top">
          <div className="SignIn__logo">
            <img alt="pic" src={peakJobsLogo} className="SignIn__logo-icon" />
            <LanguageDropdown />
          </div>
          <div className="SignIn__form">
            <SignInForm />
          </div>
        </div>
      </div>

      {!isMobile && (
        <div className="SignIn__right">
          <div className="SignIn__right-container">
            <div className="SignIn__language">{/*<LanguageDropdown />*/}</div>
            <div className="SignIn__circle-container">
              <div className="SignIn__circle">
                <p className="SignIn__circle-text">
                  PEAK <br /> YOUR SHIFT
                </p>
                <div className="SignIn__circle-tip">
                  <img
                    className="SignIn__circle-tip--icon"
                    alt="pic"
                    src={briefCase}
                  />
                  <p className="SignIn__circle-tip--text">{t("signIn.tip")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignIn;
