import React, { useEffect, useState } from "react";
import { apiCall } from "../../../api/Api";
import { getCompletedJobs } from "../../../api/Jobs";
import JobCard from "../JobCard/JobCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import plusIcon from "../../../assets/svg/plus-icon-white.svg";
import reloadIcon from "../../../assets/svg/Refresh_icon.svg";
import cs from "classnames";

import "./CompletedJobs.scss";

const CompletedJobs = () => {
  const [completedJobs, setCompletedJobs] = useState<any>([]);
  const [nextPage, setNextPage] = useState<any>("");
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading] = useState(false);
  const [error] = useState("");
  const [jobStatus, setJobStatus] = useState("Completed");
  const [mainJobStatus, setMainJobStatus] = useState<string>("Past");
  const [unreviewedJobCount, setUnreviewedJobCount] = useState(0);
  const [completedJobsCount, setCompletedJobsCount] = useState(0);
  const [jobsCountLoading, setJobsCountLoading] = useState(false);
  const [refreshCalled, setRefreshCalled] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  useEffect(() => {
    setJobsCountLoading(true);
    try {
      apiCall("GET", `b2b/jobs/?status=unreviewed&limit=0`).then((res) => {
        setUnreviewedJobCount(res.data.count);
        setJobsCountLoading(false);
      });
    }catch(error){
      setJobsCountLoading(false);
    }
   
  }, []);

  const callJobs = () => {
    setIsLoading(true);
    getCompletedJobs()
      .then((res) => {
        setCompletedJobs(res.data.results);
        setCompletedJobsCount(res.data.count);
        setIsCalled(true);
        setNextPage(res.data?.next);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (!isCalled) {
     callJobs();
    }
  }, []);

  const loadMoreJobs = () => {
    if (nextPage !== null) {
      setIsLoading(true);
      let resultUrl = nextPage.split("?")[1];
      apiCall("GET", `b2b/jobs/?${resultUrl}`)
        .then((res) => {
          setCompletedJobs([...completedJobs, ...res.data.results]);
          setIsCalled(true);
          setNextPage(res.data?.next);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: nextPage,
    onLoadMore: loadMoreJobs,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <section className="CompletedJobs">
      <div className="CompletedJobs__header-container">
      <div className="CompletedJobs__title-container">
        <div className="CompletedJobs__main-title">{t("jobs.title")}</div>
        {isLoading ? 
          (<div className="Jobs__spinner" />) 
          : 
        (<img src={reloadIcon} alt="reload icon" className={cs("PastJobs__reload-icon", refreshCalled && "PastJobs__reload-icon--disable")} onClick={() => {
          if(!refreshCalled) {
            callJobs();
            setRefreshCalled(true);
            setTimeout(() => {
              setRefreshCalled(false);
            }, 10000);
          }
        }} />)}
      </div>
        <div className="CompletedJobs__button-wrapper">
          <div
            className="CompletedJobs__button"
            onClick={() => {
              navigate("/jobs/new-job");
            }}
          >
            <div className="CompletedJobs__button-round">
              <img
                alt="pic"
                src={plusIcon}
                className="CompletedJobs__button-round--plus"
              />
            </div>
            <p className="CompletedJobs__button-text">
              {t("jobs.addJobButton")}
            </p>
          </div>
        </div>
      </div>
      <section className="CompletedJobs__filter-container">
        <div
          onClick={() => {
            setMainJobStatus("Upcoming");
            navigate("/jobs/upcoming-not-assigned");
          }}
          className={cs(
            "CompletedJobs__container-item",
            mainJobStatus === "Upcoming" &&
              "CompletedJobs__container-item--active",
          )}
        >
          {t("jobs.upcomingTabLabel")}
        </div>
        <div
          onClick={() => {
            navigate("/jobs/past-unreviewed");
            setMainJobStatus("Past");
          }}
          className={cs(
            "CompletedJobs__container-item",
            mainJobStatus === "Past" && "CompletedJobs__container-item--active",
          )}
        >
          {t("jobs.pastTabLabel")}
        </div>
      </section>
      <section className="CompletedJobs__buttons-container">
        <div
          className={cs(
            "CompletedJobs__status-btn flex",
            jobStatus === "Review" && "CompletedJobs__status-btn--active",
          )}
          onClick={() => {
            setJobStatus("Review");
            navigate("/jobs/past-unreviewed");
            localStorage.setItem("completedJobsCount", `${completedJobsCount}`);
            localStorage.setItem("unreviewedJobCount", `${unreviewedJobCount}`);
          }}
        >
          {` ${t("jobs.review.tabLabel")}`}
          {jobsCountLoading ? (<div className="Jobs__spinner" />) : `(${unreviewedJobCount})`} 
        </div>
        <div
          className={cs(
            "CompletedJobs__status-btn flex",
            jobStatus === "Completed" && "CompletedJobs__status-btn--active",
          )}
          onClick={() => {
            navigate("/jobs/past-reviewed");
            setJobStatus("Completed");
            localStorage.setItem("completedJobsCount", `${completedJobsCount}`);
            localStorage.setItem("unreviewedJobCount", `${unreviewedJobCount}`);
          }}
        >
          {`${t("jobs.completed.tabLabel")}`}
          {jobsCountLoading ? (<div className="Jobs__spinner" />) : `(${completedJobsCount})`} 
        </div>
      </section>
      {completedJobs?.length === 0 && isCalled && !loading && !isLoading && (
        <div className="CompletedJobs__no-content">
          {t("jobs.completed.noContent")}
        </div>
      )}
      <div className="CompletedJobs__container">
        {completedJobs &&
          completedJobs.map((job: any, index: number) => {
            return (
              <div key={index} className="CompletedJobs__job-wrapper">
                <JobCard
                  job={job}
                  shiftTotal={true}
                  jobStatus={"Past"}
                  subStatus={jobStatus}
                />
              </div>
            );
          })}
      </div>
      <div>
        {nextPage && nextPage !== null && (
          <div ref={sentryRef}>
            <LoadingComponent />
          </div>
        )}
      </div>
      {isLoading && completedJobs.length === 0 && (
        <div style={{ margin: "150px auto" }}>
          <LoadingComponent />
        </div>
      )}
    </section>
  );
};

export default CompletedJobs;
