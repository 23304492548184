import React, { useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "./AuthContext";
import { signOut, onAuthStateChanged, User } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import LoadingPage from "../../components/shared/LoadingPage/LoadingPage";

interface AuthContextProps {
  children: React.ReactNode | null;
}

export const AuthContextProvider = (props: AuthContextProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [localUserProfile, setLocalUserProfile] = useState(
    JSON.parse(localStorage.getItem("profile")!!),
  );
  const [localOrganization, setOrganization] = useState(
    JSON.parse(localStorage.getItem("organization")!!),
  );

  //GET CURRENT USER
  useEffect(() => {
    onAuthStateChanged(auth, () => {
      setCurrentUser(auth.currentUser);
    });
  }, [localUserProfile]);

  //SET USER PROFILE
  const setDataToLocalStorage = (userProfile: any, organization: any) => {
    setLocalUserProfile(userProfile);
    setOrganization(organization);
    localStorage.setItem("profile", JSON.stringify(userProfile));
    localStorage.setItem("organization", JSON.stringify(organization));
  };

  //SIGN OUT
  const signOutFirebaseUser = () => {
    signOut(auth).then(() => {
      localStorage.removeItem("profile");
      localStorage.removeItem("organization");
      localStorage.removeItem("selectedPlan");
    });
  };

  //PUT LOADING PAGE TO STOP RENDERING TWO TIMES THE PAGES BELOW
  if (currentUser === undefined) {
    return <LoadingPage />;
  }

  const context: AuthContextType = {
    currentUser,
    localUserProfile,
    localOrganization,
    setDataToLocalStorage,
    signOutFirebaseUser,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
