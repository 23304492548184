import React from "react";

import organizationAvatar from "../../../assets/images/company-avatar.png";
import marker from "../../../assets/svg/marker.svg";
import { useTranslation } from "react-i18next";

import "./UnitCart.scss";

interface UnitCartProps {
  image: string;
  name: string;
  city: string;
  address: string;
  number?: string;
  onEditClick: () => void;
}

const UnitCart = (props: UnitCartProps) => {
  const { image, name, city, address, number, onEditClick } = props;
  const { t } = useTranslation("common");
  return (
    <div className="UnitCart">
      <div className="UnitCart__header">
        <div className="UnitCart__organization">
          <img
            alt="pic"
            className="UnitCart__organization-logo"
            src={image || organizationAvatar}
          />
          <h5 className="UnitCart__organization-text">{name}</h5>
        </div>
        <h6 className="UnitCart__city">{city}</h6>
      </div>
      <div className="UnitCart__body">
        <div className="UnitCart__location">
          <img alt="pic" src={marker} className="UnitCart__location-icon" />
          <h6 className="UnitCart__location-text">{address}</h6>
        </div>
        {number && <h6 className="UnitCart__number">{number}</h6>}
      </div>
      <div className="UnitCart__footer">
        <h6
          className="UnitCart__edit"
          onClick={() => {
            onEditClick();
          }}
        >
          {t("units.unitCart.edit")}
        </h6>
      </div>
    </div>
  );
};

export default UnitCart;
