import React, { useState, useEffect } from "react";
import Button from "../../../components/shared/Button/Button";
import DatePicker from "react-datepicker";
import calendarIcon from "../../../assets/svg/calendar-icon.svg";
import cs from "classnames";
import { getTimeBetweenDates } from "../../../lib/helpers/getTimeBetweenDates";
import "react-datepicker/dist/react-datepicker.css";

import "./EditShift.scss";
import { useTranslation } from "react-i18next";

const EditShift = (props: any) => {
  let selectedLanguage = localStorage.getItem("lang");
  let today = new Date();
  let tomorrow = today;
  let currentHours = today.getHours();
  let currentMinutes = today.getMinutes();

  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(currentHours, currentMinutes);

  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>(startDate);
  const [startTime, setStartTime] = useState<any>(startDate);
  const [endTime, setEndTime] = useState<any>(endDate);
  const [selectedDates, setSelectedDates] = useState<any>({
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  });
  const [error, setError] = useState("");
  const { t } = useTranslation("common");

  useEffect(() => {
    if (props.selectShift) {
      setStartDate(props.selectShift[0]?.start_time);
      setStartTime(props.selectShift[0]?.start_time);
      setEndDate(props.selectShift[0]?.end_time);
      setEndTime(props.selectShift[0]?.end_time);
    }
  }, []);

  useEffect(() => {
    let comparetwoDates = getTimeBetweenDates(startTime, endTime);
    if (comparetwoDates.hours === 12 && comparetwoDates.minutes / 60 > 12) {
      setError(t("jobs.addNewJobs.shifts.editShift.errorOne"));
    } else if (comparetwoDates.hours > 12) {
      setError(t("jobs.addNewJobs.shifts.editShift.errorOne"));
    } else if (comparetwoDates.hours < 3) {
      setError(t("jobs.addNewJobs.shifts.editShift.errorTwo"));
    } else if (comparetwoDates.hours < 0) {
      setError(t("jobs.addNewJobs.shifts.editShift.errorThree"));
    } else {
      setError("");
    }

  }, [startTime, endTime, startDate, endDate]);

  const handleClick = () => {
    let comparetwoDates = getTimeBetweenDates(startTime, endTime);
    if (comparetwoDates.hours === 12 && comparetwoDates.minutes / 60 > 12) {
      setError(t("jobs.addNewJobs.shifts.editShift.errorOne"));
      return;
    }
    if (comparetwoDates.hours > 12) {
      setError(t("jobs.addNewJobs.shifts.editShift.errorOne"));
      return;
    }
    if (comparetwoDates.hours < 3) {
      setError(t("jobs.addNewJobs.shifts.editShift.errorTwo"));
      return;
    }
    if (comparetwoDates.hours < 0) {
      setError(t("jobs.addNewJobs.shifts.editShift.errorThree"));
      return;
    }
    if (comparetwoDates.hours <= 12) {
      setError("");
      const updateShifts = props.shifts.map((item: any) => {
        if (item?.id === props?.selectShift[0]?.id) {
          return {
            id: props?.selectShift[0]?.id,
            start_time: startTime,
            end_time: endTime,
          };
        }
        return item;
      });
      props.setShifts(updateShifts);
      props.setEditShift();
    } else {
      setError(t("jobs.addNewJobs.shifts.editShift.errorOne"));
    }
  };

  const filteredTime = (time: any) => {
    const selectedDate = new Date(time);

    let setHours = new Date(startTime)?.getHours() + 3;
    let minTime = new Date(startTime).setHours(setHours);

    let setMaxHours = new Date(startTime).getHours() + 12;
    let maxTime = new Date(startTime).setHours(setMaxHours);

    return (
      new Date(minTime).getTime() <= selectedDate.getTime() &&
      new Date(maxTime).getTime() >= selectedDate.getTime()
    );
  };

  const tomorrowTimeFiltered = (date: any) => tomorrow < date;

  return (
    <div className="EditShift">
      <div className="EditShift__container">
        <div>
          <div className="EditShift__label">
            {t("jobs.addNewJobs.shifts.editShift.startDate")}
          </div>
          <DatePicker
            selectsStart
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              setStartTime(date);
              setSelectedDates({ ...selectedDates, startDate: date });
            }}
            filterTime={tomorrowTimeFiltered}
            minDate={tomorrow}
            dateFormat="dd. MM. yyyy"
            className={cs(
              "EditShift__date-input",
              !selectedDates?.startDate && "EditShift__time-input--disabled",
            )}
            showIcon
            icon={
              <img
                src={calendarIcon}
                width={21}
                height={21}
                alt="calendar icon"
                className="EditShift__date-icon"
              />
            }
          />
        </div>
        <div>
          <div className="EditShift__label">
            {" "}
            {t("jobs.addNewJobs.shifts.editShift.atWhatTime")}
          </div>
          <DatePicker
            selected={startTime}
            onChange={(date) => {
              setStartTime(date);
              setSelectedDates({ ...selectedDates, startTime: date });
            }}
            filterTime={tomorrowTimeFiltered}
            showTimeSelect
            showTimeSelectOnly
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
            className={cs(
              "EditShift__time-input",
              !selectedDates?.startTime && "EditShift__time-input--disabled",
            )}
          />
        </div>
      </div>
      <div className="EditShift__container">
        <div>
          <div className="EditShift__label">
            {t("jobs.addNewJobs.shifts.editShift.endDate")}
          </div>
          <DatePicker
            selectsEnd
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
              setEndTime(date);
              setSelectedDates({ ...selectedDates, endDate: date });
            }}
            endDate={endDate}
            startDate={startTime}
            minDate={startTime}
            maxDate={
              new Date(
                new Date(startTime).setHours(
                  new Date(startTime).getHours() + 12,
                ),
              )
            }
            dateFormat="dd. MM. yyyy"
            className={cs(
              "EditShift__date-input",
              !selectedDates.endDate && "EditShift__date-input--disabled",
            )}
            showIcon
            icon={
              <img
                src={calendarIcon}
                width={21}
                height={21}
                alt="calendar icon"
                className="EditShift__date-icon"
              />
            }
          />
        </div>
        <div>
          <div className="EditShift__label">
            {t("jobs.addNewJobs.shifts.editShift.atWhatTime")}
          </div>
          <DatePicker
            selected={endTime}
            onChange={(date) => {
              setEndTime(date);
              setSelectedDates({ ...selectedDates, endTime: date });
            }}
            filterTime={filteredTime}
            minDate={startTime}
            showTimeSelect
            showTimeSelectOnly
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
            className={cs(
              "EditShift__time-input",
              !selectedDates.endTime && "EditShift__time-input--disabled",
            )}
          />
        </div>
      </div>
      {error && <div className="AddNewShift__error">{error}</div>}
      {selectedLanguage === "SQ" ? (
          <div className="AddNewShift__shift-info">
          <div> <span>Koha e Postimit: </span> Punët mund të postohen deri në 24 orë para kohës së fillimit.</div> 
           <div> <span>Publikime:</span> Punët mbeten të publikuara për kandidatët deri në 12 orë para kohës së fillimit të punës.</div>
          <div><span>Afati i Pranimit:</span> Ju mund të pranoni aplikantët deri në 10 orë para fillimit të punës.</div> 
          </div>
      ) : (
        <div className="AddNewShift__shift-info">
          <div> <span>Post Time: </span> Jobs can be posted up to 24 hours before the start time.</div> 
          <div> <span>Publications:</span> Jobs remain published for candidates up to 12 hours before the job start time. </div>
          <div><span>Acceptance Deadline:</span> You can accept applicants up to 10 hours before the start of work.</div> 
        </div>
      )}
      <div className="EditShift__button-wrapper">
        <Button
          label={t("jobs.addNewJobs.shifts.editShift.update")}
          onClick={() => handleClick()}
          className="EditShift__button"
          disabled={error.length !== 0}
        />
        <Button
          label={t("jobs.addNewJobs.shifts.editShift.cancel")}
          onClick={() => {
            props.setEditShift();
          }}
          className="EditShift__cancel-button"
        />
      </div>
    </div>
  );
};

export default EditShift;
