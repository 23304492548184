import { apiCall } from "./Api";

export const getJobTitles = async (
  title?: string,
  limit?: number,
  offset?: number
) => {
  return apiCall(
    "get",
    `job-classification/job-titles/?jobtitle=${title ? title : ""}${limit ? `&limit=${limit}` : ``}${offset ? `&offset=${offset}` : ""}`
  );
};

export const createNewJob = async (data: any) => {
  return apiCall("POST", `b2b/jobs/`, data);
};

export const getAvailableJobs = async () => {
  return apiCall("GET", `b2b/jobs/?status=available&limit=10`);
};

export const getOngoingJobs = async () => {
  return apiCall("GET", `b2b/jobs/?status=in_progress&limit=10`);
};
//past jobs
export const getUnreviewedJobs = async () => {
  return apiCall("GET", `b2b/jobs/?status=unreviewed&limit=10`);
};
export const getCompletedJobs = async () => {
  return apiCall("GET", `b2b/jobs/?status=completed&limit=10`);
};
export const getUnattendedJobs = async () => {
  return apiCall("GET", `b2b/jobs/?status=unattended&limit=10`);
};

export const getAssignedJobs = async () => {
  return apiCall("GET", `b2b/jobs/?status=assigned&limit=10`);
};

export const deleteJob = async (jobId: string) => {
  return apiCall("DELETE", `b2b/jobs/${jobId}`);
};

export const getSubscription = async () => {
  return apiCall("GET", "subscription/");
};

export const getApplicationsForJob = async (jobId: string) => {
  return apiCall("GET", `b2b/applications/${jobId}/`);
};

export const deleteJobImage = async (jobId: string, item: string) => {
  return apiCall("DELETE", `b2b/jobs/${jobId}/image/`, { image_urls: [item] });
};

export const addJobImage = async (jobId: string, data: any) => {
  return apiCall("PUT", `b2b/jobs/${jobId}/image/`, data);
};

export const updateJobDetails = async (jobId: string, data: any) => {
  return apiCall("PUT", `b2b/jobs/${jobId}/`, data);
};

export const getApplicantPastExperience = async (id: string) => {
  return apiCall("GET", `b2b/applications/applicant/${id}/past-experiences/`);
};

export const getApplicantJobsWithReview = async (id: string) => {
  return apiCall("GET", `b2b/applications/applicant/${id}/jobs-with-review/`);
};

export const acceptApplicant = async (jobId: string, personId: string) => {
  return apiCall("POST", `b2b/applications/${jobId}/accept/${personId}/`);
};

export const cancelApplicant = async (jobId: string, personId: string) => {
  return apiCall("POST", `b2b/applications/${jobId}/cancel/${personId}/`);
};

export const listAttendanceForJob = async (jobId: string, personId: string) => {
  return apiCall("GET", `b2b/attendance/${jobId}/user/${personId}/`);
};

export const listAllShiftAttendanceForJob = async (jobId: string) => {
  return apiCall("GET", `b2b/attendance/${jobId}/users-shifts/`);
};

export const postJobReview = async (data: any) => {
  return apiCall("POST", `b2b/job-review/`, data);
};

export const getJobReview = async (id: string) => {
  return apiCall("GET", `b2b/job-review/${id}/`);
};

export const downloadContract = async (personId: string, jobId: any) => {
  return apiCall("GET", `b2b/applications/${personId}/contract/${jobId}/`);
};

export const completeApplicantJob = async (jobId: any, personId: string) => {
  return apiCall("POST", `b2b/jobs/${jobId}/complete/${personId}/`);
};

export const markUnattendedApplicantJob = async (
  jobId: any,
  personId: string
) => {
  return apiCall("POST", `b2b/jobs/${jobId}/mark-as-unattended/${personId}/`);
};
