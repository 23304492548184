import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useEditCompanyFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  const { t } = useTranslation("common");

  const memoizedT = useCallback(t, [t]);

  return useFormik({
    initialValues: {
      companyName: "",
      companyNumber: "",
      companyDescription: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      companyName: Yup.string().required(
        memoizedT("company.editCompanySettings.companyName.required"),
      ),
      companyNumber: Yup.number()
        .required(
          memoizedT("company.editCompanySettings.companyNumber.required"),
        )
        .typeError(
          memoizedT("company.editCompanySettings.companyNumber.numberError"),
        ),
      companyDescription: Yup.string().required(
        memoizedT("company.editCompanySettings.companyDescription.required"),
      ),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
