import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useSignUpFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  const { t } = useTranslation("common");

  const memoizedT = useCallback(t, [t]);

  return useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required(memoizedT("signUp.form.email.required")) // Translated error message
        .email(memoizedT("signUp.form.email.email")), // Translated error message
      password: Yup.string()
        .required(memoizedT("signUp.form.password.required")) // Translated error message
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
          memoizedT("signUp.form.password.matches"), // Translated error message
        ),
      confirmPassword: Yup.string()
        .required(memoizedT("createNewPassword.form.confirmPassword.required"))
        .when("password", {
          is: (val: string) => val && val.length > 0,
          then: () =>
            Yup.string().oneOf(
              [Yup.ref("password")],
              memoizedT("createNewPassword.form.confirmPassword.matches"),
            ),
        }),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
