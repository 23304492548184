import React from "react";

import "./SwitchButton.scss";
import cs from "classnames";

interface SwitchButtonPropsType {
  isChecked: boolean;
  onSwitchClick: () => void;
}

const SwitchButton = (props: SwitchButtonPropsType) => {
  const { isChecked, onSwitchClick } = props;

  return (
    <div
      className={cs("SwitchButton", isChecked && "SwitchButton--isChecked")}
      onClick={() => {
        onSwitchClick();
      }}
    >
      <div
        className={cs(
          "SwitchButton__circle",
          isChecked && "SwitchButton__circle--isChecked",
        )}
      />
    </div>
  );
};

export default SwitchButton;
