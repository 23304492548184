import React, { useEffect, useRef, useState } from "react";

import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

import { reverseGeocoding } from "../../../lib/helpers/reverseGeocoding";
import { convertCityName } from "../../../lib/helpers/convertCityNames";

import "./Mapbox.scss";

export interface LocationType {
  full_address: string | null;
  street: string | null;
  address_number: string | null;
  city: string | null;
  country_code: string | null;
  longitude: number | null;
  latitude: number | null;
}

export interface MapboxProps {
  locationData: (location: LocationType | null) => void;
  longitudeData?: number;
  latitudeData?: number;
}

const Mapbox = (props: MapboxProps) => {
  const { locationData, longitudeData, latitudeData } = props;
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const marker = useRef<mapboxgl.Marker | null>(null);
  const geoCoder = useRef<MapboxGeocoder | null>(null);
  const lng = 21;
  const lat = 42.5;
  const zoom = 7.6;

  //WHEN USER CLICKS ON THE MAP, FROM LNG AND LAT GET LOCATION DATA AND SEND BACK
  const handleClick = (event: any) => {
    if (map.current && marker.current && geoCoder.current) {
      try {
        const { lng, lat } = event.lngLat;
        marker.current.setLngLat([lng, lat]); // Update marker position
        marker.current.addTo(map.current);
        geoCoder.current.clear();

        const longSliced = +event.lngLat.lng.toString().slice(0, 9);
        const latSliced = +event.lngLat.lat.toString().slice(0, 9);

        reverseGeocoding(longSliced, latSliced)
          .then((response) => {
            const features = response.data.features;
            const address = features.find((feature: any) =>
              feature.id.startsWith("address"),
            );
            const place = features.find((feature: any) =>
              feature.id.startsWith("place"),
            );
            const country = features.find((feature: any) =>
              feature.id.startsWith("country"),
            );
            const cityOriginal = place?.text || null;

            const street = address?.text || null;
            const addressNumber = address?.address || null;
            const city = convertCityName(cityOriginal);
            const countryCode =
              country?.properties?.short_code.toUpperCase() || null;

            const fullAddress = `${street || ""}${street && !addressNumber ? ", " : " "}${addressNumber || ""}${street && addressNumber ? ", " : " "}${city || ""}`;

            // @ts-ignore
            geoCoder.current.setInput(fullAddress);

            locationData({
              full_address: fullAddress,
              street: street,
              address_number: addressNumber,
              city: city,
              country_code: countryCode,
              longitude: longSliced || null,
              latitude: latSliced || null,
            });
          })
          .catch((e) => {
            locationData(null);
          });
      } catch (e) {
        locationData(null);
      }
    }
  };

  //WHEN USER SELECT ON ONE OF THE SUGGESTIONS, GET LOCATION DATA AND SEND BACK
  const handleRetrieve = (event: any) => {
    if (map.current && marker.current && geoCoder.current) {
      try {
        marker.current.remove();
        const result = event?.result;
        const context = result?.context;
        const address = context?.find((contextItem: any) =>
          contextItem.id.startsWith("address"),
        );
        const place = context?.find((contextItem: any) =>
          contextItem.id.startsWith("place"),
        );
        const country = context?.find((contextItem: any) =>
          contextItem.id.startsWith("country"),
        );

        let street = address?.text || null;
        let addressNumber = address?.address || null;
        let city = place?.text || null;
        let countryCode = country?.short_code?.toUpperCase() || null;

        if (result.id.startsWith("address")) {
          addressNumber = result?.address || null;
          street = result?.text || null;
        }
        if (result.id.startsWith("place")) {
          city = result?.text || null;
        }
        if (result.id.startsWith("country")) {
          countryCode = result?.properties?.short_code.toUpperCase() || null;
        }
        city = convertCityName(city);
        const longSliced = +result?.center[0].toString().slice(0, 9);
        const latSliced = +result?.center[1].toString().slice(0, 9);

        const fullAddress = `${street || ""}${street && !addressNumber ? ", " : " "}${addressNumber || ""}${street && addressNumber ? ", " : " "}${city || ""}`;

        geoCoder.current.setInput(fullAddress);

        locationData({
          full_address: fullAddress,
          street: street,
          address_number: addressNumber,
          city: city,
          country_code: countryCode,
          longitude: longSliced || null,
          latitude: latSliced || null,
        });
      } catch (e) {
        locationData(null);
      }
    }
  };

  // INITIALIZE MAP
  useEffect(() => {
    try {
      map.current = new mapboxgl.Map({
        accessToken: process.env.REACT_APP_MAPBOX_PUBLIC_KEY,
        container: mapContainer.current || "",
        style: "mapbox://styles/arlind-sylaj/cly60s6md00c101pib0ug4o83",
        center: [lng, lat],
        zoom: zoom,
        locale: {
          language: "sq",
          country: "xk",
        },
      });

      // Add our navigation control (the +/- zoom buttons)
      map.current.addControl(new mapboxgl.NavigationControl(), "top-right");
      map.current.addControl(new mapboxgl.FullscreenControl({}));
      map.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
        }),
      );

      marker.current = new mapboxgl.Marker({});

      geoCoder.current = new MapboxGeocoder({
        accessToken: process.env.REACT_APP_MAPBOX_PUBLIC_KEY as string,
        mapboxgl: mapboxgl,
        language: "sq",
        countries: "xk",
        reverseGeocode: true,
      });

      map.current.addControl(geoCoder.current, "top-left");

      map.current.on("click", (event) => {
        handleClick(event);
      });
      geoCoder.current.on("result", (event) => {
        handleRetrieve(event);
      });
    } catch (e) {
      locationData(null);
    }

    return () => {
      if (map.current) {
        map.current.remove();
      }
      if (marker.current) {
        marker.current.remove();
      }
      if (geoCoder.current) {
        geoCoder.current.clear();
      }
    };
  }, []);

  //WHEN USER ALREADY HAS A LOCATION(LNG,LAT), SET THE VIEWSTATE AND TRIGGER MAPCLICK FUNCTION TO SEND DATA BACK
  useEffect(() => {
    if (longitudeData !== undefined && latitudeData !== undefined) {
      const event = {
        lngLat: {
          lng: longitudeData,
          lat: latitudeData,
        },
      };
      handleClick(event);
    }
  }, [longitudeData, latitudeData]);

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      ref={mapContainer}
      className="map-container"
    />
  );
};

export default Mapbox;
