import React from "react";

import "./ModalContainer.scss";
import cs from "classnames";

interface ModalContainerProps {
  children: React.ReactNode;
  position?: "top" | "bottom" | "left" | "right";
}
const ModalContainer = (props: ModalContainerProps) => {
  const { children, position } = props;
  return (
    <div className={cs("ModalContainer", `ModalContainer--${position}`)}>
      <div className="ModalContainer__container">{children}</div>
    </div>
  );
};

export default ModalContainer;
