import React from "react";

import cs from "classnames";

import { useTranslation } from "react-i18next";

import "./PlanCart.scss";

interface PlanCartTypes {
  item: any;
  activePlan: any;
  onPlanInfoClick: () => void;
  onSelectPlanClick: () => void;
}

const PlanCart = (props: PlanCartTypes) => {
  const { item, activePlan, onPlanInfoClick, onSelectPlanClick } = props;
  const { t } = useTranslation("common");
  return (
    <div
      className={cs(
        "PlanCart",
        item.name === "Free" &&
          item.id === activePlan.plan.id &&
          "PlanCart__free",
        item.name === "Pro" &&
          item.id === activePlan.plan.id &&
          "PlanCart__pro",
        item.name === "Business +" &&
          item.id === activePlan.plan.id &&
          "PlanCart__businessPlus",
        item.name === "Enterprise" &&
          item.id === activePlan.plan.id &&
          "PlanCart__enterprise",
      )}
    >
      <div
        className={cs(
          "PlanCart__top",
          item.name === "Free" && "PlanCart__top-free",
          item.name === "Pro" && "PlanCart__top-pro",
          item.name === "Business +" && "PlanCart__top-businessPlus",
          item.name === "Enterprise" && "PlanCart__top-enterprise",
        )}
      />
      <div className="PlanCart__bottom">
        <div className="PlanCart__header">
          <p className="PlanCart__title">{item.name}</p>
          <p className="PlanCart__price">
            {item.price} €
            {item.name !== "Free" && (
              <span>
                {" "}
                / {t("subscription.subscriptionPlans.planCart.month")}
              </span>
            )}
          </p>
        </div>
        <div className="PlanCart__list">
          <div className="PlanCart__list-item">
            &#8226; {item.jobs_limit}{" "}
            {t("subscription.subscriptionPlans.planCart.jobLimit")}
          </div>

          <div className="PlanCart__list-item">
            &#8226; {item.shifts_per_job_limit}{" "}
            {item.shifts_per_job_limit === 1
              ? t(
                  "subscription.subscriptionPlans.planCart.shiftPerJobLimit.partOne",
                )
              : t(
                  "subscription.subscriptionPlans.planCart.shiftPerJobLimit.partTwo",
                )}{" "}
            {t(
              "subscription.subscriptionPlans.planCart.shiftPerJobLimit.partThree",
            )}
          </div>

          <div className="PlanCart__list-item">
            &#8226; {item.seats_per_job_limit}{" "}
            {item.seats_per_job_limit === 1
              ? t(
                  "subscription.subscriptionPlans.planCart.seatsPerJobLimit.partOne",
                )
              : t(
                  "subscription.subscriptionPlans.planCart.seatsPerJobLimit.partTwo",
                )}{" "}
            {t(
              "subscription.subscriptionPlans.planCart.seatsPerJobLimit.partThree",
            )}
          </div>

          <div className="PlanCart__list-item">
            &#8226; {item.organization_units}{" "}
            {item.organization_units === 1
              ? t(
                  "subscription.subscriptionPlans.planCart.organizationUnits.partOne",
                )
              : t(
                  "subscription.subscriptionPlans.planCart.organizationUnits.partTwo",
                )}
          </div>
        </div>

        <div className="PlanCart__footer">
          {item.id === activePlan.plan.id ? (
            <div
              className={cs(
                "PlanCart__button",
                item.name === "Free" &&
                  item.id === activePlan.plan.id &&
                  "PlanCart__button-free",
                item.name === "Pro" &&
                  item.id === activePlan.plan.id &&
                  "PlanCart__button-pro",
                item.name === "Business +" &&
                  item.id === activePlan.plan.id &&
                  "PlanCart__button-businessPlus",
                item.name === "Enterprise" &&
                  item.id === activePlan.plan.id &&
                  "PlanCart__button-enterprise",
              )}
              onClick={() => {
                onPlanInfoClick();
              }}
            >
              {t("subscription.subscriptionPlans.planCart.buttonSelect")}
            </div>
          ) : (
            <div
              className="PlanCart__button"
              onClick={() => {
                onSelectPlanClick();
              }}
            >
              {t("subscription.subscriptionPlans.planCart.buttonPlan")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanCart;
