import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useCreateNewPasswordFormik } from "./useCreateNewPasswordFormik";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import lock from "../../../assets/svg/lock.svg";
import eyeSlash from "../../../assets/svg/eye-slash.svg";
import eye from "../../../assets/svg/eye.svg";

import "./CreateNewPasswordForm.scss";

interface CreateNewPasswordFormProps {
  isButtonLoading: boolean;
  onFormSubmit: (confirmPassword: string) => void;
}
const CreateNewPasswordForm = (props: CreateNewPasswordFormProps) => {
  const { isButtonLoading, onFormSubmit } = props;
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation("common");

  const formik = useCreateNewPasswordFormik({
    onSubmit: async (values: any) => {
      onFormSubmit(values.confirmPassword);
    },
  });

  return (
    <form className="CreateNewPasswordForm" onSubmit={formik.handleSubmit}>
      <div className="CreateNewPasswordForm__container">
        <div className="CreateNewPasswordForm__title">
          <img
            alt="pic"
            src={lock}
            className="CreateNewPasswordForm__title-icon"
          />
          <h2 className="CreateNewPasswordForm__title-text">
            {t("createNewPassword.form.title")}
          </h2>
        </div>
        <p className="CreateNewPasswordForm__description">
          {t("createNewPassword.form.description")}
        </p>{" "}
        <div className="CreateNewPasswordForm__input">
          <InputField
            type={showNewPassword ? "text" : "password"}
            placeholder={t("createNewPassword.form.newPassword.placeholder")}
            name="newPassword"
            value={formik.values.newPassword}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {
              setShowNewPassword(!showNewPassword);
            }}
            icon={showNewPassword ? eyeSlash : eye}
          />
          {formik.errors.newPassword && formik.touched.newPassword && (
            <h5 className="CreateNewPasswordForm__input-error">
              {formik.errors.newPassword as string}
            </h5>
          )}
        </div>
        <div className="CreateNewPasswordForm__input">
          <InputField
            type={showConfirmPassword ? "text" : "password"}
            placeholder={t(
              "createNewPassword.form.confirmPassword.placeholder",
            )}
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            onIconClick={() => {
              setShowConfirmPassword(!showConfirmPassword);
            }}
            icon={showConfirmPassword ? eyeSlash : eye}
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <h5 className="CreateNewPasswordForm__input-error">
              {formik.errors.confirmPassword as string}
            </h5>
          )}
        </div>
        <div className="CreateNewPasswordForm__button">
          <Button
            isLoading={isButtonLoading}
            onClick={() => {}}
            label={t("createNewPassword.form.button")}
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default CreateNewPasswordForm;
