import React from "react";

import closeIcon from "../../../assets/images/close-icon.png";

import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";

import "./MessageModal.scss";
import cs from "classnames";

const MessageModal = () => {
  const messageModal = useGlobalContext().messageModal;
  const closeMessageModal = useGlobalContext().closeMessageModal;

  return (
    <div
      className={cs(
        "MessageModal",
        messageModal.text !== "" && "MessageModal--isVisible",
      )}
    >
      <div
        className={cs(
          "MessageModal__container",
          `MessageModal__container--${messageModal.type}`,
        )}
      >
        <div className="MessageModal__text">{messageModal.text}</div>
        <img
          className="MessageModal__close"
          alt="pic"
          src={closeIcon}
          onClick={() => {
            closeMessageModal();
          }}
        />
      </div>
    </div>
  );
};

export default MessageModal;
