import React from "react";
import Button from "../../../components/shared/Button/Button";

import "./AuthPageResponse.scss";

interface AuthPageResponseType {
  icon: string;
  title: string;
  buttonLabel: string;
  onButtonClick: () => void;
}

const AuthPageResponse = (props: AuthPageResponseType) => {
  const { icon, title, buttonLabel, onButtonClick } = props;
  return (
    <div className="AuthPageResponse">
      <div className="AuthPageResponse__container">
        <div className="AuthPageResponse__title">
          <img alt="pic" src={icon} className="AuthPageResponse__title-icon" />
          <h2 className="AuthPageResponse__title-text">{title}</h2>
        </div>
        <Button
          label={buttonLabel}
          onClick={() => {
            onButtonClick();
          }}
        />
      </div>
    </div>
  );
};

export default AuthPageResponse;
