import React from "react";
import { ReactNode } from "react";
import Dashboard from "../pages/Dashboard/Dashboard";
import SignUp from "../pages/SingUp/SignUp";
import SignIn from "../pages/SignIn/SignIn";
import NotFound from "../pages/NotFound/NotFound";
import Environment from "../pages/Environment/Environment";
import SendEmail from "../pages/SendEmail/SendEmail";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import CreateCompany from "../pages/CreateCompany/CreateCompany";
import AuthPage from "../pages/AuthPage/AuthPage";
import Jobs from "../pages/Jobs/Jobs";
import Units from "../pages/Units/Units";
import Subscription from "../pages/Subscription/Subscription";
import NewJobForm from "../pages/Jobs/NewJobForm/NewJobForm";
import AssignedJobs from "../pages/Jobs/AssignedJobs/AssignedJobs";
import PastJobs from "../pages/Jobs/PastJobs/PastJobs";
import CompletedJobs from "../pages/Jobs/CompletedJobs/CompletedJobs";
import CompanySettings from "../pages/Company/CompanySettings/CompanySettings";
import EditCompanySettings from "../pages/Company/EditCompanySettings/EditCompanySettings";
import ProfileSettings from "../pages/Profile/ProfileSettings/ProfileSettings";
import EditProfileSettings from "../pages/Profile/EditProfileSettings/EditProfileSettings";
import CreateUnit from "../pages/Units/CreateUnit/CreateUnit";
import EditUnit from "../pages/Units/EditUnit/EditUnit";
import SubscriptionPlans from "../pages/Subscription/SubscriptionPlans/SubscriptionPlans";
import PaymentCards from "../pages/Subscription/PaymentCards/PaymentCards";
import Invoices from "../pages/Subscription/Invoices/Invoices";
import EditJobDetails from "../pages/Jobs/EditJobDetails/EditJobDetails";
import Applications from "../pages/Jobs/Applications/Applications";
import Attendance from "../pages/Jobs/Attendance/Attendance";
import VerifyPhoneNumber from "../pages/VerifyPhoneNumber/VerifyPhoneNumber";

export interface AppRoute {
  path: string;
  name?: string;
  element?: ReactNode;
  childRoute?: { path: string; name?: string; element?: ReactNode }[];
}

export const PrivateRoutesComponents: AppRoute[] = [
  {
    path: "/",
    name: "Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/units",
    name: "Units",
    element: <Units />,
  },
  {
    path: "/units/new-unit",
    name: "Create New Unit",
    element: <CreateUnit />,
  },
  {
    path: "/units/edit-unit",
    name: "Edit Unit",
    element: <EditUnit />,
  },
  {
    path: "jobs/new-job",
    name: "New Job",
    element: <NewJobForm />,
  },
  {
    path: "jobs/upcoming-not-assigned",
    name: "Not Assigned Jobs",
    element: <Jobs />,
  },
  {
    path: "jobs/upcoming-assigned",
    name: "Assigned Jobs",
    element: <AssignedJobs />,
  },
  {
    path: "jobs/past-unreviewed",
    name: "Past Jobs",
    element: <PastJobs />,
  },
  {
    path: "jobs/past-reviewed",
    name: "Completed Jobs",
    element: <CompletedJobs />,
  },
  {
    path: "jobs/applicants",
    name: "Applicants",
    element: <Applications />,
  },
  {
    path: "jobs/attendance",
    name: "Attendance",
    element: <Attendance />,
  },
  {
    path: "jobs/edit-job",
    name: "Edit Job",
    element: <EditJobDetails />,
  },
  {
    path: "/subscription",
    name: "Subscription",
    element: <Subscription />,
  },
  {
    path: "/subscription/subscription-plans",
    name: "Subscription Plans",
    element: <SubscriptionPlans />,
  },
  {
    path: "/subscription/payment-cards",
    name: "Payment Cards",
    element: <PaymentCards />,
  },
  {
    path: "/subscription/invoices",
    name: "Invoices",
    element: <Invoices />,
  },
  {
    path: "company/company-settings",
    name: "Company Settings",
    element: <CompanySettings />,
  },
  {
    path: "company/edit-company-settings",
    name: "Edit Company Settings",
    element: <EditCompanySettings />,
  },
  {
    path: "/profile/profile-settings",
    name: "Profile Settings",
    element: <ProfileSettings />,
  },
  {
    path: "/profile/edit-profile-settings",
    name: "Edit Profile Settings",
    element: <EditProfileSettings />,
  },
];

export const PublicRoutesComponents: AppRoute[] = [
  {
    path: "/sign-up",
    name: "Sign Up",
    element: <SignUp />,
  },
  {
    path: "/send-email",
    name: "Send Email",
    element: <SendEmail />,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    element: <SignIn />,
  },
  {
    path: "/auth",
    name: "Auth Page",
    element: <AuthPage />,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    element: <ResetPassword />,
  },
  {
    path: "/create-company",
    name: "Create Organization",
    element: <CreateCompany />,
  },
  {
    path: "/verify-phone-number",
    name: "Verify phone number",
    element: <VerifyPhoneNumber />,
  },

  {
    path: "/environment",
    name: "Environment",
    element: <Environment />,
  },
  {
    path: "/*",
    name: "Not Found",
    element: <NotFound />,
  },
];
