import React, { useRef, useState } from "react";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import cs from "classnames";
import chevronDown from "../../../assets/svg/chevron-down.svg";
import InfiniteScroll from "../InfiniteScroll/InfiniteScroll";

import "./DropdownSearch.scss";

interface DropdownSearchProps {
  items: string[];
  className?: string;
  className2?: string;
  style?: any;
  selectedItem: string;
  onItemSelect: (item: any) => void;
  onBlur?: () => void;
  inputDisabled?: boolean;
  inputPlaceholder?: string;
  iconStyle?: React.CSSProperties;
  loadMore?: () => void | undefined;
  setFilter: (item: string) => void;
}

const DropdownSearch = (props: DropdownSearchProps) => {
  const {
    items,
    selectedItem,
    className,
    className2,
    style,
    onBlur,
    inputDisabled,
    inputPlaceholder,
    iconStyle,
    loadMore,
    setFilter
  } = props;
  const [showItems, setShowItems] = useState(false);

  const dropdownRef = useRef(null);

  const onItemSelect = (item: string) => {
    props.onItemSelect(item);
    setShowItems(!showItems);
  };

  const handleOutSideClick = () => {
    setShowItems(false);
  };

  useOutsideClickDetection(dropdownRef, handleOutSideClick);

  return (
    <div
      ref={dropdownRef}
      className={cs("DropdownSearch", className)}
      style={style}
    >
      <div
        className={cs(
          "DropdownSearch__container",
          inputDisabled && `DropdownSearch__container--disabled`
        )}
      >
        <input
          placeholder={inputPlaceholder}
          disabled={inputDisabled}
          onBlur={onBlur}
          onClick={() => setShowItems(!showItems)}
          value={selectedItem}
          className="DropdownSearch__input"
          onChange={(e) => {
            props.onItemSelect(e.target.value);
            setFilter(e.target.value);
            setShowItems(true);
          }}
        />
        <img
          className="DropdownSearch__icon"
          src={chevronDown}
          alt="chevron down icon"
          onClick={() => setShowItems(!showItems)}
        />
      </div>

      <div
        className={cs(
          "DropdownSearch__items",
          showItems && "active",
          className2
        )}
      >
        {items
          ?.filter((item) => {
            if (!selectedItem) {
              return item;
            } else if (
              item?.toLowerCase().includes(selectedItem?.toLowerCase())
            ) {
              return item;
            } else if (selectedItem === item){
              return item;
            }
          })
          .map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => onItemSelect(item)}
                className={`DropdownSearch__item-container ${
                  props.selectedItem === item ? "activeLabel" : ""
                }`
              }
              >
                <p className="DropdownSearch__item">{item}</p>
              </div>
            );
          })}
          <InfiniteScroll loadMore={loadMore} /> 
      </div>
    </div>
  );
};

export default DropdownSearch;
