import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import { apiCall } from "../../../api/Api";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { useNavigate } from "react-router-dom";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";
import profileAvatar from "../../../assets/images/user-avatar.png";

import "./ProfileSettings.scss";

const ProfileSettings = () => {
  const [profileData, setProfileData] = useState<any | null>();
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  useEffect(() => {
    apiCall("GET", "b2b/user/profile/")
      .then((response) => {
        setProfileData(response.data);
      })
      .catch((error) => {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
        setProfileData(null);
      });
  }, []);

  return (
    <div className="ProfileSettingsContainer">
      <div className="ProfileSettingsContainer__head">
        <p className="ProfileSettingsContainer__head-title">
          {t("profile.title")}
        </p>
      </div>
      <div className="ProfileSettingsContainer__body">
        {profileData === undefined ? (
          <div className="ProfileSettings__loader">
            <LoadingComponent />
          </div>
        ) : profileData === null ? (
          <div className="ProfileSettings__noData">{t("noData")}</div>
        ) : (
          <div className="ProfileSettings">
            <div className="ProfileSettings__head">
              <p className="ProfileSettings__title">
                {t("profile.profileSettings.title")}
              </p>
              <div className="ProfileSettings__buttons">
                <h6
                  className="ProfileSettings__edit"
                  onClick={() => {
                    navigate("/profile/edit-profile-settings");
                  }}
                >
                  {t("profile.profileSettings.edit")}
                </h6>
              </div>
            </div>
            <div className="ProfileSettings__body">
              <div className="ProfileSettings__form">
                <div className="ProfileSettings__logo">
                  <h6 className="ProfileSettings__logo-title">
                    {t("profile.profileSettings.profilePicture")}
                  </h6>
                  <img
                    alt="pic"
                    className="ProfileSettings__logo-image"
                    src={profileData?.profile.picture_url || profileAvatar}
                  />
                </div>
                <div className="ProfileSettings__details">
                  <div className="ProfileSettings__detail">
                    <h6 className="ProfileSettings__detail-title">
                      {t("profile.profileSettings.displayName")}
                    </h6>
                    <h5 className="ProfileSettings__detail-value">
                      {profileData?.profile.display_name}
                    </h5>
                  </div>
                  <div className="ProfileSettings__detail">
                    <h6 className="ProfileSettings__detail-title">
                      {t("profile.profileSettings.email")}
                    </h6>
                    <h5 className="ProfileSettings__detail-value">
                      {profileData?.user.email}
                    </h5>
                  </div>
                  <div className="ProfileSettings__detail">
                    <h6 className="ProfileSettings__detail-title">
                      {t(
                        "profile.profileSettings.applicationEmailNotifications",
                      )}
                    </h6>
                    <h5 className="ProfileSettings__detail-value">
                      {profileData?.profile
                        .allow_application_email_notifications
                        ? t("profile.profileSettings.yes")
                        : t("profile.profileSettings.no")}
                    </h5>
                  </div>{" "}
                  <div className="ProfileSettings__detail">
                    <h6 className="ProfileSettings__detail-title">
                      {t(
                        "profile.profileSettings.applicationPushNotifications",
                      )}
                    </h6>
                    <h5 className="ProfileSettings__detail-value">
                      {profileData?.profile.allow_application_push_notifications
                        ? t("profile.profileSettings.yes")
                        : t("profile.profileSettings.no")}
                    </h5>
                  </div>{" "}
                  <div className="ProfileSettings__detail">
                    <h6 className="ProfileSettings__detail-title">
                      {t("profile.profileSettings.jobEmailNotifications")}
                    </h6>
                    <h5 className="ProfileSettings__detail-value">
                      {profileData?.profile.allow_job_email_notifications
                        ? t("profile.profileSettings.yes")
                        : t("profile.profileSettings.no")}
                    </h5>
                  </div>{" "}
                  <div className="ProfileSettings__detail">
                    <h6 className="ProfileSettings__detail-title">
                      {t("profile.profileSettings.jobPushNotifications")}
                    </h6>
                    <h5 className="ProfileSettings__detail-value">
                      {profileData?.profile.allow_job_push_notifications
                        ? t("profile.profileSettings.yes")
                        : t("profile.profileSettings.no")}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileSettings;
