import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import PaymentCard from "../../../components/shared/PaymentCard/PaymentCard";
import Invoice from "../../../components/shared/Invoice/Invoice";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import AddPaymentCard from "../AddPaymentCard/AddPaymentCard";
import diamondIcon from "../../../assets/svg/diamond.svg";
import plusIcon from "../../../assets/svg/plus-icon-white.svg";
import cs from "classnames";

import "./YourPlan.scss";

interface YourPlanProps {
  activePlan: any;
  paymentMethods: any;
  invoices: any;
}

const YourPlan = (props: YourPlanProps) => {
  const { activePlan, paymentMethods, invoices } = props;
  const [isAddPaymentCardOpen, setIsAddPaymentCardOpen] = useState(false);
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  // Reorder the payment methods array to have the default payment method first
  const reorderedPaymentMethods = paymentMethods?.data
    ? [
        ...paymentMethods.data.filter(
          (item: any) => item.id === paymentMethods.default_payment_method,
        ),
        ...paymentMethods.data.filter(
          (item: any) => item.id !== paymentMethods.default_payment_method,
        ),
      ]
    : [];

  return (
    <>
      {isAddPaymentCardOpen && (
        <ModalContainer position="top">
          <AddPaymentCard
            onCloseClick={() => {
              setIsAddPaymentCardOpen(false);
            }}
          />
        </ModalContainer>
      )}
      <div className="YourPlan">
        <div className="YourPlan__header">
          <div
            className={cs(
              "YourPlanCart",
              activePlan.plan.name === "Free" && "YourPlanCart__free",
              activePlan.plan.name === "Pro" && "YourPlanCart__pro",
              activePlan.plan.name === "Business +" &&
                "YourPlanCart__businessPlus",
              activePlan.plan.name === "Enterprise" &&
                "YourPlanCart__enterprise",
            )}
          >
            <div className="YourPlanCart__left">
              <h2 className="YourPlanCart__title">{activePlan.plan.name}</h2>
              {activePlan.next_plan_name !== null && (
                <p className="YourPlanCart__subtitle">
                  {t("subscription.yourPlan.yourPlanCart.subtitle")}:
                  <span> {activePlan.next_plan_name} </span>
                </p>
              )}
            </div>
            <div
              className="YourPlanCart__button"
              onClick={() => {
                navigate("/subscription/subscription-plans");
              }}
            >
              {activePlan.plan.name === "Free" && (
                <div className="YourPlanCart__button-round">
                  <img
                    alt="pic"
                    src={diamondIcon}
                    className="YourPlanCart__button-round--plus"
                  />
                </div>
              )}

              <p
                className={cs(
                  "YourPlanCart__button-text",
                  activePlan.plan.name !== "Free" &&
                    "YourPlanCart__button-text--withoutIcon",
                )}
              >
                {activePlan.plan.name === "Free"
                  ? t("subscription.yourPlan.yourPlanCart.buttonTextOne")
                  : t("subscription.yourPlan.yourPlanCart.buttonTextTwo")}
              </p>
            </div>
          </div>
        </div>

        <div className="YourPlan__body">
          <div className="PaymentCardsCart">
            <div className="PaymentCardsCart__header">
              <p className="PaymentCardsCart__title">
                {t("subscription.yourPlan.paymentCardsCart.title")}
              </p>
              <p
                className="PaymentCardsCart__manage"
                onClick={() => {
                  navigate("/subscription/payment-cards");
                }}
              >
                {t("subscription.yourPlan.paymentCardsCart.manage")}
              </p>
            </div>
            <div className="PaymentCardsCart__body">
              {paymentMethods === undefined ? (
                <div className="PaymentCardsCart__loader">
                  <LoadingComponent />
                </div>
              ) : paymentMethods === null ? (
                <div
                  className="PaymentCardsCart__add"
                  onClick={() => {
                    setIsAddPaymentCardOpen(true);
                  }}
                >
                  <div className="PaymentCardsCart__add-round">
                    <img
                      alt="pic"
                      src={plusIcon}
                      className="PaymentCardsCart__add-plus"
                    />
                  </div>
                  <p className="PaymentCardsCart__add-text">
                    {t("subscription.yourPlan.paymentCardsCart.addCart")}
                  </p>
                </div>
              ) : (
                <>
                  {reorderedPaymentMethods
                    ?.slice(0, 3)
                    .map((item: any, index: number) => (
                      <PaymentCard
                        key={index}
                        item={item}
                        defaultPaymentMethod={
                          paymentMethods.default_payment_method
                        }
                      />
                    ))}
                </>
              )}
            </div>
          </div>
          <div className="InvoicesCart">
            <div className="InvoicesCart__header">
              <p className="InvoicesCart__title">
                {t("subscription.yourPlan.invoicesCart.title")}
              </p>
              <p
                className="InvoicesCart__viewAll"
                onClick={() => {
                  navigate("/subscription/invoices");
                }}
              >
                {t("subscription.yourPlan.invoicesCart.viewAll")}
              </p>
            </div>

            {invoices === undefined ? (
              <div className="InvoicesCart__loader">
                <LoadingComponent />
              </div>
            ) : invoices === null ? (
              <div className="InvoicesCart">{t("noData")}</div>
            ) : (
              <div className="InvoicesCart__body">
                {invoices
                  ?.slice(0, 3)
                  .map((item: any, index: number) => (
                    <Invoice key={index} invoice={item} />
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default YourPlan;
