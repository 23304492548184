import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import closeIcon from "../../../assets/svg/close-dark.svg";

import "./MultiFileUploader.scss";

interface FileUploaderProps {
  onCancel: () => void;
  onSubmit: (files: any) => void;
  accept?: string;
  allImages?: File[];
  maxImageSize: number;
}

const MultiFileUploader = (props: FileUploaderProps) => {
  const { onCancel, onSubmit, accept, allImages, maxImageSize } = props;

  const [images, setImages] = useState<any>([]);
  const [error, setError] = useState(``);

  const MAX_LENGTH =
    allImages?.length !== 0 && allImages ? 10 - allImages?.length : 10;
  const { t } = useTranslation("common");

  function readFileAsText(file: any) {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();

      fr.onload = function () {
        resolve(fr.result);
      };

      fr.onerror = function () {
        reject(fr);
      };

      fr.readAsDataURL(file);
    });
  }

  const handleChange = (e: any) => {
    let files = e.target.files;
    let readers = [];

    const fileSize = Array.from(e.target.files).filter(
      (item: any) => item.size > maxImageSize,
    );

    if (!files.length) return;
    if (Array.from(e.target.files).length > MAX_LENGTH) {
      e.preventDefault();
      setError(
        `${t("multiFileUploader.errorOne")} ${MAX_LENGTH} ${MAX_LENGTH === 1 ? t("multiFileUploader.errorOne.errorOneTwo") : t("multiFileUploader.errorOne.errorOneThree")}`,
      );
      return;
    }
    if (fileSize.length > 0) {
      e.preventDefault();
      const megaBytesFullNumber = maxImageSize / (1024 * 1024);
      const megaBytes = megaBytesFullNumber.toFixed(0);
      setError(`${t("multiFileUploader.errorTwo")} ${megaBytes}MB`);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      readers.push(readFileAsText(files[i]));
    }
    setError(``);

    Promise.all(readers).then((values: any) => {
      const found = allImages?.some((r) => values.includes(r));
      if (found) {
        setError(t("multiFileUploader.errorThree"));
        return;
      } else {
        setImages(values);
      }
    });
  };

  return (
    <div className="MultiFileUploader">
      <div className="MultiFileUploader__container">
        <img
          className="MultiFileUploader__close"
          alt="close icon"
          src={closeIcon}
          onClick={() => {
            onCancel();
          }}
        />
        <label className="MultiFileUploader__input-label">
          {t("multiFileUploader.label")}
        </label>
        <div className="MultiFileUploader__input-container">
          <input
            className="MultiFileUploader__input"
            type="file"
            accept={accept}
            onChange={(e: any) => handleChange(e)}
            multiple
          />
          {error && <div className="MultiFileUploader__error"> {error}</div>}
        </div>
        <div className="MultiFileUploader__buttons">
          <Button
            type="reset"
            style={{ height: "60px", marginRight: "5px" }}
            className="Button__secondary MultiFileUploader__cancel-btn"
            label={t("multiFileUploader.leftButton")}
            onClick={() => {
              onCancel();
              setImages([]);
            }}
          />
          <Button
            type="submit"
            style={{ height: "60px", marginLeft: "5px" }}
            label={t("multiFileUploader.rightButton")}
            onClick={() => {
              onSubmit(images);
              setImages([]);
            }}
            className="MultiFileUploader__submit-btn"
            disabled={!!error}
          />
        </div>
      </div>
    </div>
  );
};

export default MultiFileUploader;
