import React from "react";

import MessageModal from "../../components/shared/MessageModal/MessageModal";

import "./PublicRoutesLayout.scss";

interface LayoutPropsType {
  children: React.ReactNode;
}
const PublicRoutesLayout = (props: LayoutPropsType) => {
  const { children } = props;

  return (
    <div className="PublicRoutesLayout">
      <MessageModal />
      <div className="PublicRoutesLayout__children">{children}</div>
    </div>
  );
};

export default PublicRoutesLayout;
