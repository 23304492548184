import React, { useEffect, useState } from "react";

import { apiCall } from "../../../../api/Api";
import { useGlobalContext } from "../../../../context/GlobalContext/GlobalContext";
import { useTranslation } from "react-i18next";
import { convertRestApiErrorCodes } from "../../../../lib/helpers/handleErrors";
import Checkbox from "../../../../components/shared/Checkbox/Checkbox";
import Button from "../../../../components/shared/Button/Button";
import closeIcon from "../../../../assets/svg/close-white.svg";
import cs from "classnames";

import "./SelectPlan.scss";

interface SelectPlanTypes {
  selectedPlan: any;
  activePlan: any;
  onCloseClick: () => void;
  openSuccessModal: () => void;
}

const SelectPlan = (props: SelectPlanTypes) => {
  const { selectedPlan, activePlan, onCloseClick, openSuccessModal } = props;
  const activePlanPrice = +activePlan.plan.price;
  const selectedPlanPrice = +selectedPlan.price;
  const [nowState, setNowState] = useState(activePlanPrice < selectedPlanPrice);
  const [laterState, setLaterState] = useState(false);
  const [nextBillingCycle, setNextBillingCycle] = React.useState<string | null>(
    null,
  );
  const [checkboxError, setCheckboxError] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const globalCtx = useGlobalContext();
  const { t } = useTranslation("common");
  const planId = localStorage.getItem("selectedPlan");

  //FORMAT DATE
  useEffect(() => {
    const endDateTimestamp = activePlan.stripe_subscription.current_period_end;
    const endDate = new Date(endDateTimestamp * 1000); // Convert to millisec
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedStartDate = endDate.toLocaleString("en-US", options as any);
    setNextBillingCycle(formattedStartDate);
  }, []);

  //HANDLE SELECT PLAN
  const handleSelectPlan = () => {
    if (!nowState && !laterState) {
      setCheckboxError(true);
    } else {
      setIsButtonLoading(true);

      //CHANGE PLAN
      apiCall(
        "PUT",
        `subscription/change-plan/${selectedPlan.id}/?change_immediately=${nowState}`,
      )
        .then((response) => {
          setTimeout(() => {
            openSuccessModal();
          }, 1000);
          if (planId) {
            localStorage.removeItem("selectedPlan");
          }
        })
        .catch((error) => {
          const errorMessage = convertRestApiErrorCodes(
            error?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
          if (planId) {
            localStorage.removeItem("selectedPlan");
          }
        })
        .finally(() => {
          onCloseClick();
          setIsButtonLoading(false);
          if (planId) {
            localStorage.removeItem("selectedPlan");
          }
        });
    }
  };

  return (
    <div className="SelectPlan">
      <div
        className={cs(
          "SelectPlan__header",
          selectedPlan.name === "Free" && "SelectPlan__header-free",
          selectedPlan.name === "Pro" && "SelectPlan__header-pro",
          selectedPlan.name === "Business +" &&
            "SelectPlan__header-businessPlus",
          selectedPlan.name === "Enterprise" && "SelectPlan__header-enterprise",
        )}
      >
        <div>
          <p className="SelectPlan__title">{selectedPlan.name}</p>
          <p className="SelectPlan__subtitle">
            {t("subscription.subscriptionPlans.selectPlanModal.subtitle")}
          </p>
        </div>
        <img
          alt="pic"
          className="SelectPlan__close"
          src={closeIcon}
          onClick={() => {
            onCloseClick();
            if (planId) {
              localStorage.removeItem("selectedPlan");
            }
          }}
        />
      </div>
      {activePlanPrice > selectedPlanPrice && (
        <div className="SelectPlan__body">
          <p className="SelectPlan__question">
            {t("subscription.subscriptionPlans.selectPlanModal.question")}
          </p>
          <div className="SelectPlan__checkbox">
            <Checkbox
              isChecked={nowState}
              onCheckboxClick={() => {
                setNowState(!nowState);
                setLaterState(false);
                setCheckboxError(false);
              }}
            />{" "}
            <div className="SelectPlan__checkbox-text">
              {t(
                "subscription.subscriptionPlans.selectPlanModal.changeImmediately",
              )}
            </div>
          </div>

          <div className="SelectPlan__checkbox">
            <Checkbox
              isChecked={laterState}
              onCheckboxClick={() => {
                setLaterState(!laterState);
                setNowState(false);
                setCheckboxError(false);
              }}
            />{" "}
            <div className="SelectPlan__checkbox-text">
              {t(
                "subscription.subscriptionPlans.selectPlanModal.changeNextBilling",
              )}
            </div>
          </div>

          {checkboxError && (
            <p className="SelectPlan__checkbox-error">
              {t("subscription.subscriptionPlans.selectPlanModal.error")}
            </p>
          )}
        </div>
      )}

      <div className="SelectPlan__footer">
        <div className="SelectPlan__explain">
          {laterState || nowState ? (
            <>
              <div className="SelectPlan__explain-textOne">
                {t("subscription.subscriptionPlans.selectPlanModal.hint")}
              </div>
              <div className="SelectPlan__explain-textTwo">
                {nowState
                  ? t(
                      "subscription.subscriptionPlans.selectPlanModal.immediately",
                    )
                  : nextBillingCycle}
              </div>
            </>
          ) : null}
        </div>
        <Button
          className="SelectPlan__button"
          isLoading={isButtonLoading}
          onClick={() => {
            handleSelectPlan();
          }}
          label={t("subscription.subscriptionPlans.selectPlanModal.button")}
        />
      </div>
    </div>
  );
};

export default SelectPlan;
